import { IlcValidationError } from '../../ilc-types';

type CombinedErrorsByCode = { code: IlcValidationError['code']; count: number; errors: IlcValidationError[] }[];

type CombinedErrorsByType = { type: IlcValidationError['type']; combinedErrors: CombinedErrorsByCode }[];

export function combineErrorsByType(errors: IlcValidationError[]): CombinedErrorsByType {
  return errors.reduce((acc, error) => {
    const existingTypeGroup = acc.find((group) => group.type === error.type);

    if (!existingTypeGroup) {
      acc.push({
        type: error.type,
        combinedErrors: [{ code: error.code, count: 1, errors: [error] }],
      });
    } else {
      const existingCodeGroup = existingTypeGroup.combinedErrors.find((group) => group.code === error.code);

      if (!existingCodeGroup) {
        existingTypeGroup.combinedErrors.push({
          code: error.code,
          count: 1,
          errors: [error],
        });
      } else {
        existingCodeGroup.count += 1;
        existingCodeGroup.errors.push(error);
      }
    }

    return acc;
  }, [] as CombinedErrorsByType);
}

export function cleanAreaName(name: string): string {
  return name.replace(/Polygon/i, '');
}
