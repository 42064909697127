import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Secret } from 'types/company.types';
import { IntegrationHeader } from '../integrations.styled';
import { Button, LabelWrapper } from '@ratedpower/components';
import meteoDataSourceNames, { MeteoSources } from 'utils/constants/meteoDataSources';

interface IProps {
  onEdit: () => void;
  isEditing: boolean;
  integrationSecret: Secret;
}

const getMeteoInfo = (publicName: string) => {
  switch (publicName) {
    case meteoDataSourceNames.API_SOLARANYWHERE:
      return 'company:meteorological-integration-info';
    default:
      break;
  }
};

const IntegrationCardHeader: React.FC<IProps> = ({ integrationSecret, isEditing, onEdit }) => {
  const { t } = useTranslation();

  const meteoInfo = getMeteoInfo(integrationSecret.publicName);

  const meteoHeader = useMemo(() => {
    if (integrationSecret.secret === MeteoSources.API_SOLARGIS) {
      return t('company:API_SOLARGIS');
    }
    return integrationSecret.publicName;
  }, [integrationSecret]);

  return (
    <>
      <IntegrationHeader>
        <LabelWrapper
          size="l"
          weight="bold"
          info={meteoInfo && t(meteoInfo)}
          title={meteoHeader}
          dataTest={integrationSecret.secret}
        />
        {!isEditing && <Button variant="ghost" onClick={onEdit} text={t('action:edit')} />}
      </IntegrationHeader>
    </>
  );
};

export default IntegrationCardHeader;
