import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  UPDATE_DESIGNS,
  UPDATE_DESIGN_NAME,
  TOGGLE_FAVORITE_DESIGN,
  GET_COMBINED_PROJECT_DESIGNS,
} from 'services/designs-gql';
import { IBessDesign, IDesign } from 'types/design';
import { IUseModal, useModal } from 'utils/hooks/use-modal';
import { DocumentNode, FetchResult, MutationFunctionOptions, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import {
  getRudderstackCloneDesignEventLocation,
  getRudderstackFavoriteDesignEventLocation,
} from 'utils/constants/rudderstack';
import { track } from 'rudderstack/utils';
import { RudderstackEvent } from 'rudderstack/types';
import { buildDesignProcessUrl } from 'utils/navigation/navigation';

export interface IDesignActions {
  handleClone: (item: IDesign | IBessDesign) => Promise<void>;
  editNotesModal: IUseModal;
  viewNotesModal: IUseModal;
  handleModalEditNotes: (item: IDesign | IBessDesign) => void;
  handleModalViewNotes: (notes: string) => void;
  handleEditNotes: () => void;
  handleSwitchFavorite: (item: IDesign | IBessDesign) => Promise<void>;
  handleEditDesignName: (id: string, newName: string) => void;
  updateDesigns: (options?: MutationFunctionOptions) => Promise<FetchResult<any>>;
  handleIlc: (item: IDesign | IBessDesign) => Promise<void>;
  itemToEdit: IDesign | IBessDesign | null;
}

export const useDesignsActions = (
  refetch: () => void,
  refetchQueries: DocumentNode[],
  projectId: string
): IDesignActions => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [updateDesigns] = useMutation(UPDATE_DESIGNS);
  const [toggleFavoriteDesign] = useMutation(TOGGLE_FAVORITE_DESIGN, {
    refetchQueries,
  });
  const [itemToEdit, setItemToEdit] = useState<IDesign | IBessDesign | null>(null);

  const handleSwitchFavorite = async (item: IDesign | IBessDesign) => {
    const { id, isFavorite } = item;
    await toggleFavoriteDesign({ variables: { designId: id } });
    const location = getRudderstackFavoriteDesignEventLocation(pathname);
    if (location && !isFavorite) track(RudderstackEvent.DESIGN_MARKED_FAVORITE, { location });
  };

  const [updateDesignName] = useMutation(UPDATE_DESIGN_NAME, { refetchQueries: [GET_COMBINED_PROJECT_DESIGNS] });

  const { subscriptionPlan } = useSelector((state: RootState) => state.currentUser);
  const canClone = !!subscriptionPlan && !subscriptionPlan.subscriptionPlanExpired;

  const handleClone = async (item: IDesign | IBessDesign) => {
    if (!canClone) return;
    const { id } = item;
    const location = getRudderstackCloneDesignEventLocation(pathname);
    if (location) track(RudderstackEvent.DESIGN_CLONE, { location });
    navigate(buildDesignProcessUrl(projectId, id, item.simulationCategory, false));
  };

  const editNotesModal = useModal();
  const viewNotesModal = useModal();

  const handleModalEditNotes = (item: IDesign | IBessDesign) => {
    editNotesModal.setModalOpen(true);
    setItemToEdit(item);
  };
  const handleModalViewNotes = (notes: string) => {
    viewNotesModal.setModalOpen(true);
    viewNotesModal.setData(notes);
  };

  const handleEditNotes = () => {
    editNotesModal.setModalOpen(false);
    setItemToEdit(null);
    refetch();
  };

  const handleEditDesignName = (id: string, newName: string) => {
    updateDesignName({ variables: { id, name: newName } });
  };

  const handleIlc = async (item: IDesign | IBessDesign) => {
    if (!canClone) return;
    const { id } = item;
    navigate(`/projects/${projectId}/interactive-layout/?designId=${id}&?sessionId=random`);
  };

  return {
    itemToEdit,
    editNotesModal,
    viewNotesModal,
    handleSwitchFavorite,
    handleClone,
    handleModalEditNotes,
    handleModalViewNotes,
    handleEditNotes,
    handleEditDesignName,
    updateDesigns,
    handleIlc,
  };
};
