import React from 'react';
import * as S from './topbar-team-content.styled';
import { useTranslation } from 'react-i18next';
import { ContentTopBar } from 'components/content-top-bar';
import { Tabs } from '@ratedpower/components';
import { teamTabs } from 'utils/teams';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface IProps {
  onOpenFiltersPanel?: () => void;
  onSearch?: (value: string) => void;
}

const TopbarTeamContent: React.FC<IProps> = ({ onSearch, onOpenFiltersPanel }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { contentType, teamId } = useParams<{ contentType: string; teamId: string }>();
  const defaultTabId = contentType ?? teamTabs[0].id;

  const onChangeTab = (id: string) => () => {
    navigate(`../${teamId}/${id}`, { state: { teamName: routeState?.teamName } });
  };

  const routeState = location.state as { teamName: string };

  return (
    <S.TopbarWrapper>
      <Tabs
        tabActive={defaultTabId}
        onClickTab={(id) => onChangeTab(id)()}
        tabs={teamTabs.map((tab) => ({ ...tab, label: t(tab.label) }))}
      />
      <S.RightTopbarWrapper>
        {onSearch && onOpenFiltersPanel && (
          <ContentTopBar searchOnChange={onSearch} filterOnClick={onOpenFiltersPanel} />
        )}
      </S.RightTopbarWrapper>
    </S.TopbarWrapper>
  );
};

export default TopbarTeamContent;
