import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.v2.spacing.xs};

  & > p {
    flex-grow: 1;
  }
`;

export const ErrorListItem = styled.div<{ $active: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, $active }) => ($active ? theme.v2.bg.secondary.default : 'transparent')};
  padding: ${({ theme }) => theme.v2.spacing.xxs} ${({ theme }) => theme.v2.spacing.xxs};
`;

export const ErrorListItemHeader = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.v2.spacing.xxs};
`;

export const ErrorItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.v2.spacing.xs};
  padding: ${({ theme }) => theme.v2.spacing.xxs} 0;
  max-height: 220px;
  overflow: auto;
`;
