import React from 'react';
import { useTranslation } from 'react-i18next';
import { TopbarWrapper, Typography } from '@ratedpower/components';
import { useTheme } from 'styled-components';

const NotificationsTopBar: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <TopbarWrapper
      type="main"
      padding={`0 ${theme.v2.spacing.l}`}
      left={<Typography size="xl">{t('communication:notification-tab-title')}</Typography>}
    />
  );
};

export default NotificationsTopBar;
