import styled, { css } from 'styled-components';
import { BaseCell } from 'pages/users-management/read-only-roles-tab/read-only-roles-wrapper/read-only-roles-custom/read-only-roles-custom.styled';

export const Cell = styled(BaseCell)<{ $textAlign?: string; $bgColor?: string; $withTitle?: boolean }>`
  display: grid;
  grid-row-gap: ${({ theme }) => theme.v2.spacing.m};
  ${({ $withTitle }) =>
    $withTitle &&
    css`
      align-content: end;
    `}
`;

export const SVGWrapper = styled.div`
  display: block;
  margin: 0 auto;
`;
