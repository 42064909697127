import {
  GetCombinedProjectDesignsByUserResponse,
  GetCombinedProjectDesignsResponse,
  IBessDesign,
  IDesign,
  PageInfo,
  SIMULATION_CATEGORY,
} from 'types/design';

export function combineSimulations(response: GetCombinedProjectDesignsResponse): {
  result: { content: (IDesign | IBessDesign)[]; pageInfo: PageInfo };
  project: { name: string; teamId: string; publishedDate?: Date };
} {
  if (!response) {
    return {
      result: { content: [], pageInfo: { totalPages: 0, pageNumber: 0 } },
      project: { name: '', teamId: '' },
    };
  }

  const pvResults = response.combinedProjectSimulations?.pvResult ?? [];
  const bessResults = response.combinedProjectSimulations?.saBessResult ?? [];

  const bessResultsWithCategory = bessResults.map((result: IBessDesign) => ({
    ...result,
    simulationCategory: SIMULATION_CATEGORY.STANDALONE_BATTERY,
  }));

  return {
    result: {
      content: [...pvResults, ...bessResultsWithCategory].sort(
        (a: IDesign | IBessDesign, b: IDesign | IBessDesign) => {
          return new Date(a.publishedDate) > new Date(b.publishedDate) ? -1 : 1;
        }
      ),
      pageInfo: response.combinedProjectSimulations?.pageInfo,
    },
    project: response.project,
  };
}

export function combineSimulationsByUser(response: GetCombinedProjectDesignsByUserResponse): {
  result: { content: (IDesign | IBessDesign)[]; pageInfo: PageInfo };
  project: { name: string; teamId: string; publishedDate?: Date };
} {
  if (!response) {
    return {
      result: { content: [], pageInfo: { totalPages: 0, pageNumber: 0 } },
      project: { name: '', teamId: '' },
    };
  }

  const pvResults = response.combinedProjectSimulationsByUser?.pvResult ?? [];
  const bessResults = response.combinedProjectSimulationsByUser?.saBessResult ?? [];

  const pvResultsWithCategory = pvResults.map((result: IDesign) => ({ ...result, category: SIMULATION_CATEGORY.PV }));
  const bessResultsWithCategory = bessResults.map((result: IBessDesign) => ({
    ...result,
    simulationCategory: SIMULATION_CATEGORY.STANDALONE_BATTERY,
  }));

  return {
    result: {
      content: [...pvResultsWithCategory, ...bessResultsWithCategory].sort(
        (a: IDesign | IBessDesign, b: IDesign | IBessDesign) => {
          return new Date(a.publishedDate) > new Date(b.publishedDate) ? 1 : -1;
        }
      ),
      pageInfo: response.combinedProjectSimulationsByUser?.pageInfo,
    },
    project: response.project,
  };
}
