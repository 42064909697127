import React from 'react';
import { ConditionalWrapper, InputText, LabelWrapper, Tooltip, Typography } from '@ratedpower/components';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { SUPPORT_EMAIL } from 'utils/constants/contact';

interface IProps {
  title: string;
  value?: string;
  loading: boolean;
  disabled: boolean;
  editing: boolean;
  inputName: string;
  onChange: (value: string) => void;
  dataTest?: string;
}

const CompanyEditableInput: React.FC<IProps> = ({
  value,
  title,
  disabled,
  loading,
  editing,
  inputName,
  onChange,
  dataTest = '',
}) => {
  const { t } = useTranslation();

  if (loading)
    return (
      <LabelWrapper title={title} size="l">
        <Skeleton height={10} width={200} />
      </LabelWrapper>
    );

  return (
    <LabelWrapper title={title} size="l">
      <ConditionalWrapper
        condition={disabled}
        wrapper={(children) => (
          <Tooltip
            description={
              <Typography>
                {t('company:edit-company-info-banner')} {SUPPORT_EMAIL}
              </Typography>
            }
          >
            {children}
          </Tooltip>
        )}
      >
        <InputText
          disabled={disabled}
          readOnly={!editing}
          value={value}
          onChange={onChange}
          name={inputName}
          size="l"
          dataTest={dataTest}
        />
      </ConditionalWrapper>
    </LabelWrapper>
  );
};

export default CompanyEditableInput;
