import React from 'react';

import { PrimaryActionTypes, useAddStructure, useDesignInputs, useGetRectangleElement, useModes } from '../ilc-store';

import ReferencePoints from './reference-points';
import { calculateAdjacentStructureReferenceRectangle } from '../ilc-utils';
import { Point } from '../ilc-types';

export const ReferenceStructureForAdd = () => {
  const designInputs = useDesignInputs();
  const action = useModes().mode;
  const getRectangleElement = useGetRectangleElement();
  const addStructure = useAddStructure();

  if (
    action.type !== PrimaryActionTypes.ADD_STRUCTURES ||
    !action.payload.selectedStructureOption ||
    !action.payload.selectedPs ||
    !action.payload.referenceStructureId
  )
    return null;

  const selectedReferenceElement = getRectangleElement(action.payload.referenceStructureId);
  const { width, length, strings } = action.payload.selectedStructureOption;
  const psId = action.payload.selectedPs;

  const adjacentStructureReference = calculateAdjacentStructureReferenceRectangle(
    selectedReferenceElement,
    designInputs,
    length,
    width
  );

  const onClickReferencePoint = (coords: Point) => {
    if (!selectedReferenceElement) return;
    addStructure({
      selectedPs: psId,
      selectedStructureOption: {
        strings,
        length,
        width,
      },
      coordinates: { x: coords.x, y: coords.y },
      referenceStructureId: selectedReferenceElement.id,
    });
  };

  return selectedReferenceElement && adjacentStructureReference ? (
    <ReferencePoints
      color="purple"
      showReferencePoints={false}
      showMidPoints={true}
      selectedReferencePoint={null}
      onClick={onClickReferencePoint}
      center={selectedReferenceElement.centroid}
      userCoords={adjacentStructureReference}
    />
  ) : null;
};
