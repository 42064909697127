import React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { track } from 'rudderstack/utils';
import { GET_COMBINED_PROJECT_DESIGNS } from 'services/designs-gql';
import { DesignSortKeysAtServer, DESIGN_ACTIONS, DesignSortKeys } from 'utils/constants/designs';
import { views } from 'utils/constants/top-bar';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { DESIGNS_PER_PAGE as entriesPerPage } from 'utils/constants/pagination';
import { getSortDirection } from 'utils/sort';
import { DESIGNS_TABS as tabs, DESIGN_SERVER_FILTER_BY_TAB as filtersForView } from 'utils/constants/tabs';
import { useSearch } from 'utils/hooks/use-search';
import { useDesignFilters } from './use-design-filters';
import { designsPollIntervals } from 'utils/constants/poll-interval';
import { DESIGN_STATUS, IBessDesign, IDesign } from 'types/design';
import { useModal } from 'utils/hooks/use-modal';
import { useToasts } from 'utils/hooks/use-toasts';
import { useMutation } from '@apollo/client';
import { GenericStatus } from 'utils/constants/status';
import { COMPARISON_DESIGNS_LIMIT } from 'utils/constants/design-comparison';
import { SCREEN_LOCATION } from 'utils/constants/toasts';
import { DELETE_SIMULATIONS } from 'services/incremental-results-gql';
import { useDesignsActions } from 'pages/designs/use-designs-actions';
import { ISort } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { RudderstackEvent } from 'rudderstack/types';
import { DP_ROUTE, SA_BESS_DP_ROUTE } from 'utils/navigation/navigation';
import { combineSimulations } from 'services/transform-services/map-designs-gql';

export const useDesigns = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { id: projectId } = useParams<{ id: string }>();

  const defaultSortSelector = {
    selector: {
      label: t('date'),
      code: DesignSortKeys.group1.date,
    },
    ascendent: false,
  };

  const [selectedView, setSelectedView] = useState<string>(views.GRID);
  const [selectedTab, setSelectedTab] = useState<string>(tabs.designs);
  const [viewDetailed, setViewDetailed] = useState(false);
  const [tabFilters, setTabFilters] = useState(filtersForView.designs);
  const [sortBy, setSortBy] = useState<ISort>(defaultSortSelector);
  const [rowsSelected, setRowsSelected] = useState<(IDesign | IBessDesign)[]>([]);
  const [itemsToDelete, setItemsToDelete] = useState<(IDesign | IBessDesign)[] | null>(null);

  const deleteModal = useModal();

  const { addToast, removeToast } = useToasts();

  const { getSearchFilter, inputSearchProps } = useSearch();
  const { serverFilters, overlayProps, toggleOverlay } = useDesignFilters(selectedTab);

  function changeView(icon: any) {
    setSelectedView(icon.value);
    setViewDetailed(false);
    setSortBy(defaultSortSelector);
  }

  const {
    loading,
    data: fetchedData,
    refetch,
    error,
    stopPolling,
    startPolling,
    currentPage,
    paginationComponent,
  } = useCustomQuery(GET_COMBINED_PROJECT_DESIGNS, {
    variables: {
      projectId,
      pagination: {
        page: 0,
        size: entriesPerPage,
      },
      sorting: {
        property: DesignSortKeysAtServer[sortBy.selector.code],
        direction: getSortDirection(sortBy.ascendent),
      },
      filters: [...(tabFilters || []), ...serverFilters.filters],
      isFavorite: selectedTab === 'favorites',
      search: getSearchFilter(),
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  });

  const designs: ((IDesign | IBessDesign) & { projectTeamId: any })[] = fetchedData
    ? combineSimulations(fetchedData).result.content.map((design: IDesign | IBessDesign) => ({
        ...design,
        projectTeamId: fetchedData.project.teamId,
      }))
    : [];

  const {
    itemToEdit,
    editNotesModal,
    viewNotesModal,
    handleSwitchFavorite,
    handleClone,
    handleModalEditNotes,
    handleModalViewNotes,
    handleEditNotes,
    handleEditDesignName,
    updateDesigns,
    handleIlc,
  } = useDesignsActions(refetch, [GET_COMBINED_PROJECT_DESIGNS], projectId ?? '');

  function startDesignProcess() {
    navigate('.' + DP_ROUTE, { state: { projectName: fetchedData.project.name } });
  }

  function startStandaloneBess() {
    navigate('.' + SA_BESS_DP_ROUTE, { state: { projectName: fetchedData.project.name } });
  }

  const [deleteDesigns] = useMutation(DELETE_SIMULATIONS);

  const zeroDesignsCreated = useMemo<boolean>(() => {
    if (!!error || loading || !fetchedData || !designs) return false;
    const noFiltersApplied =
      !serverFilters.filters.length && !getSearchFilter().length && tabFilters === filtersForView.designs;
    const noDesignFound = designs.length < 1;
    return noDesignFound && noFiltersApplied;
  }, [fetchedData, error, loading]);

  function isAnyDesignInProgress(data: any[]): boolean {
    return data.reduce((result, design) => {
      if (!result && design.simulationResult === DESIGN_STATUS.IN_PROGRESS) return true;
      return result;
    }, false);
  }

  function updatePollInterval(fetchedDesigns: any[]) {
    isAnyDesignInProgress(fetchedDesigns) ? startPolling(designsPollIntervals.designInProgress) : stopPolling();
  }

  // Filter data
  useEffect(() => {
    if (!fetchedData) return;
    updatePollInterval(designs);
  }, [fetchedData, currentPage]);

  function handleChangeTab(newtab: string) {
    setTabFilters(filtersForView[newtab]);
    setSelectedTab(newtab);
  }

  const handleSelectAll = () => {
    if (rowsSelected.length > 0) {
      setRowsSelected([]);
    } else {
      const designsWithoutErrorsAndProgress = designs.filter(
        (design: IDesign | IBessDesign) =>
          design.status !== DESIGN_STATUS.ERROR &&
          design.status !== DESIGN_STATUS.IN_PROGRESS &&
          design.simulationResult === GenericStatus.OK
      );
      setRowsSelected([...designsWithoutErrorsAndProgress]);
    }
  };

  const handleSelectRow = (item: IDesign | IBessDesign) => {
    let newRowsSelected = [...rowsSelected];
    if (rowsSelected.some((row) => row.id === item.id)) {
      newRowsSelected = newRowsSelected.filter((e) => e.id !== item.id);
    } else {
      newRowsSelected.push(item);
    }
    setRowsSelected(newRowsSelected);
  };

  const handleDelete = async (items: (IDesign | IBessDesign)[]) => {
    deleteModal.setModalOpen(false);
    setRowsSelected([]);
    const ids = items.map((item) => item.id);
    await deleteDesigns({ variables: { ids } });
    refetch();
  };

  const handleCompare = () => {
    track(RudderstackEvent.RUN_COMPARISON);
    if (rowsSelected.length >= 2 && rowsSelected.length <= COMPARISON_DESIGNS_LIMIT) {
      const selectedDesignsIds = rowsSelected.map((e) => `id=${e.id}`).join('&');
      navigate(`./comparison?${selectedDesignsIds}`, {
        state: {
          projectName: fetchedData.project.name,
          designs: rowsSelected,
        },
      });
    }
  };

  const handleArchive = async (items: (IDesign | IBessDesign)[]) => {
    const designForms = items.map((item) => ({ id: item.id, status: GenericStatus.ARCHIVED }));
    await updateDesigns({ variables: { designForms } });
    refetch();
    if (items.length === 1) {
      addToast(SCREEN_LOCATION.bottomCenter, <>Your design have been archived. &nbsp;</>);
    } else {
      addToast(SCREEN_LOCATION.bottomCenter, <>{items.length} designs have been archived. &nbsp;</>);
    }
    setRowsSelected([]);
  };
  const handleUnarchive = async (items: (IDesign | IBessDesign)[], toastToRemove?: any) => {
    const designForms = items.map((item) => ({ id: item.id, status: GenericStatus.REVIEWED }));
    await updateDesigns({ variables: { designForms } });
    refetch();
    setRowsSelected([]);
    // todo: save toasts at state
    if (!!toastToRemove) removeToast(toastToRemove);
  };

  const handleModalDelete = (items: IDesign[] | any[]) => {
    deleteModal.setModalOpen(true);
    setItemsToDelete(items);
  };

  const handlePerform = (action: string, data: IDesign | IDesign[] | IBessDesign | IBessDesign[]) => {
    const {
      click,
      select,
      favorite,
      clone,
      archive,
      unarchive,
      delete: delDesign,
      editNotes,
      viewNotes,
      ilc,
    } = DESIGN_ACTIONS;
    const availableActions = [click, select, favorite, clone, archive, unarchive, delDesign, editNotes, viewNotes, ilc];
    if (!availableActions.includes(action)) return;

    const itemsArray = Array.isArray(data) ? data : [data];

    switch (action) {
      case select:
        handleSelectRow(itemsArray[0]);
        break;
      case favorite:
        handleSwitchFavorite(itemsArray[0]);
        break;
      case clone:
        handleClone(itemsArray[0]);
        break;
      case archive:
        handleArchive(itemsArray);
        break;
      case unarchive:
        handleUnarchive(itemsArray);
        break;
      case delDesign:
        handleModalDelete(itemsArray);
        break;
      case editNotes:
        handleModalEditNotes(itemsArray[0]);
        break;
      case ilc:
        handleIlc(itemsArray[0]);
        break;
      case viewNotes:
        handleModalViewNotes(itemsArray[0].notes);
        break;
    }
  };

  return {
    changeView,
    handleArchive,
    handleChangeTab,
    handleCompare,
    handleDelete,
    handleModalDelete,
    handlePerform,
    handleSelectAll,
    handleUnarchive,
    setSortBy,
    setViewDetailed,
    startDesignProcess,
    startStandaloneBess,
    toggleOverlay,
    handleEditNotes,
    handleEditDesignName,
    itemToEdit,
    deleteModal,
    designs,
    editNotesModal,
    viewNotesModal,
    error,
    inputSearchProps,
    itemsToDelete,
    loading,
    overlayProps,
    paginationComponent,
    rowsSelected,
    selectedTab,
    selectedView,
    sortBy,
    viewDetailed,
    zeroDesignsCreated,
    projectName: fetchedData?.project?.name,
    projectId,
  };
};
