import React from 'react';
import * as S from './permission-list.styled';
import { IPermission, IPermissionToShow, IPermissionType, IRoleListItem } from 'types/role';
import { usePermissionList } from './hooks/use-permission-list';
import { Button, Checkbox, Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';

interface IProps {
  element?: IRoleListItem;
  onEdit: (roleId: string) => void;
  onCancel: (roleId: string) => void;
  onSave: (permissionsIds: IPermission[], id?: string) => void;
}

const PermissionList: React.FC<IProps> = ({ element, onEdit, onCancel, onSave }) => {
  const { t } = useTranslation();
  const elementEditable = element?.editable;

  const { filteredPermissionsToShow, handleCheckPermission, handleSave, handleCancel, isChecked, canEditPermissions } =
    usePermissionList(onSave, onCancel, element);

  const getPermissionTypeTranslation = (type: string) => {
    if (type === 'SAML') return type;
    return t(`user:roles.${type}`);
  };

  return (
    <>
      <S.PermissionsContainer>
        {!elementEditable && canEditPermissions && (
          <S.EditButtonWrapper>
            <Button
              variant="link"
              textHoverUnderlined
              text={t('action:edit')}
              onClick={() => onEdit(element?.id ?? '')}
            />
          </S.EditButtonWrapper>
        )}
        <S.PermissionsContainerWrapper>
          {filteredPermissionsToShow.map((permission: IPermissionToShow, index: number) => (
            <S.PermissionContainer key={index}>
              <Typography className="group-title" weight="bold">
                {t(`user:roles.${permission.group}`)}
              </Typography>
              <S.CheckboxContainer>
                {permission.permissionType.map((type: IPermissionType) => (
                  <Checkbox
                    id={element?.name + type.value}
                    disabled={!element?.editable}
                    key={element?.name + type.value}
                    label={getPermissionTypeTranslation(type.text)}
                    onChange={() => handleCheckPermission(type.value)}
                    checked={isChecked(type.value)}
                  />
                ))}
              </S.CheckboxContainer>
            </S.PermissionContainer>
          ))}
        </S.PermissionsContainerWrapper>
      </S.PermissionsContainer>
      {elementEditable && canEditPermissions && (
        <S.Actions>
          <S.ActionsCancelSave>
            <Button text={t('action:cancel')} variant="ghost" onClick={handleCancel} />
            <Button text={t('action:save')} onClick={handleSave} variant="accent" />
          </S.ActionsCancelSave>
        </S.Actions>
      )}
    </>
  );
};

export default PermissionList;
