import React from 'react';
import { Point } from '../ilc-types';
import { Circle } from './nodes-without-refs';

interface IClickablePoints {
  coords: Array<Point>;
  onClick: (coords: { x: number; y: number }, event?: any) => void;
  color: string;
  radius: number;
}

const ClickablePoints: React.FC<IClickablePoints> = ({ coords, onClick, color, radius }) => {
  return (
    <>
      {coords.map((coord) => (
        <Circle
          key={`${coord.x}-${coord.y}`}
          onClick={(event) => onClick({ x: coord.x, y: coord.y }, event)}
          radius={radius}
          position={[coord.x, 5, -coord.y]}
          color={color}
        />
      ))}
    </>
  );
};

export { ClickablePoints };
