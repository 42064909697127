import styled from 'styled-components';

export const MaintenanceWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  justify-content: center;
  align-items: center;
`;

export const MaintenanceContainer = styled.div`
  grid-row-gap: ${({ theme }) => theme.v2.spacing.l};
  display: grid;
  grid-template-columns: 400px;
  justify-items: center;
  text-align: center;
`;
