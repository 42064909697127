import React, { useEffect, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  PrimaryActionTypes,
  SecondaryActionTypes,
  useAddNodeAndEdgeToOtherNode,
  useAddNodeToClosestNode,
  useAddNodeToOtherNodeMode,
  useAvailableAreas,
  useBatteryAreas,
  useBbox,
  useDetectIfClickIsInsideAvailableArea,
  useGenerateIlcDesign,
  useGetRoadOfAvailableArea,
  useModalState,
  useModes,
  usePointerType,
  useRectangleElements,
  useRemoveElements,
  useReset,
  useResetMode,
  useResetModeByStep,
  useRestrictedAreas,
  useRoads,
  useSubstationAreas,
  useSwitchingStationsAreas,
} from './ilc-store';
import { CameraControls, PerspectiveCamera } from '@react-three/drei';
import { GuiIn, GuiOut } from './webgl-components/gui';
import { BottomToolbarIn, BottomToolbarOut } from './components/bottom-toolbar';
import GraphLinesController from './webgl-components/interactive-graph-lines';
import StructuresInstancedMesh from './webgl-components/structures-instanced-mesh';
import Controls from './webgl-components/controls';
import SelectedElementsHOC from './webgl-components/selected-elements';
import { useNavigate, useParams } from 'react-router-dom';
import { useKeyboardShortcut } from 'utils/hooks/use-keyboard-shortcut';
import { TopBar } from './components/topbar';
import { TopToolbar } from './components/top-toolbar';
import { ReferenceForRoads } from './webgl-components/reference-for-roads';
import { getWorldCoords } from './hooks/use-world-coords';
import { ReferenceStructureForAdd } from './webgl-components/reference-structure-for-add';
import { CustomRotationModal } from './components/custom-rotation-modal';
import { PolygonWithoutGeometry } from './components/polygon';
import { useILCLayoutElements } from './use-ilc-layout-elements-zustand';
import { RightSideBar } from './components/right-side-bar';

const InteractiveLayoutCreator = () => {
  const cameraRef = useRef<any>();
  const generateIlcDesign = useGenerateIlcDesign();
  const navigate = useNavigate();
  const resetActions = useResetMode();
  const resetActionsByStep = useResetModeByStep();
  const addNodeToOtherNode = useAddNodeAndEdgeToOtherNode();
  const pointerType = usePointerType();
  const modalState = useModalState();
  const flags = useFlags();

  const { projectId } = useParams<{ projectId: string }>();

  const handleGoBack = () => {
    if (projectId) {
      navigate(`/projects/${projectId}`);
    } else {
      navigate(-1);
    }
  };

  const addNodeToOtherNodeMode = useAddNodeToOtherNodeMode();

  const handleGenerateDesign = async () => {
    await generateIlcDesign();
    handleGoBack();
  };

  useILCLayoutElements();

  const action = useModes().mode;
  const secondaryAction = useModes().secondaryMode;

  const controls = useRef<typeof CameraControls>();

  useKeyboardShortcut(['escape'], resetActionsByStep);

  const rectangleElements = useRectangleElements();
  const availableAreas = useAvailableAreas();
  const substationAreas = useSubstationAreas();
  const reset = useReset();

  const roads = useRoads();

  const removeElements = useRemoveElements();

  const deleteElements = () => {
    if (document.activeElement?.tagName === 'BODY') {
      removeElements();
      resetActions();
    }
  };

  useKeyboardShortcut(['delete'], deleteElements);

  const restrictedAreas = useRestrictedAreas();
  const switchingStationAreas = useSwitchingStationsAreas();
  const batteryAreas = useBatteryAreas();

  const bbox = useBbox();

  const addNodeToClosest = useAddNodeToClosestNode();
  const detectIfClickIsInsideAvailableArea = useDetectIfClickIsInsideAvailableArea();
  const getRoadOfAvailableArea = useGetRoadOfAvailableArea();

  const handleOnPointerDown = (event) => {
    if (event.shiftKey) return;
    if (event.buttons === 2) return;
    const clickPosition = getWorldCoords(
      event,
      cameraRef.current,
      {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      'XZ'
    );

    if (action.type === PrimaryActionTypes.ADD_NODE_TO_OTHER_NODE && action.payload?.connectedNode) {
      const newNodeId = addNodeToOtherNode(
        { x: clickPosition.x, y: clickPosition.y },
        action.payload.connectedNode.nodeId,
        action.payload.connectedNode.roadId
      );
      if (newNodeId) {
        addNodeToOtherNodeMode({ connectedNode: { nodeId: newNodeId, roadId: action.payload.connectedNode.roadId } });
      }
    }

    if (action.type === PrimaryActionTypes.ADD_NODE_TO_CLOSEST) {
      const areaId = detectIfClickIsInsideAvailableArea({ x: clickPosition.x, y: clickPosition.y });
      if (areaId) {
        const roadId = getRoadOfAvailableArea(areaId);
        if (roadId) {
          addNodeToClosest({ x: clickPosition.x, y: clickPosition.y }, roadId);
        }
      }
    }
  };

  useEffect(() => {
    return () => reset();
  }, []);

  if (!flags.skuIlc) return <div style={{ padding: '24px' }}>Layout editor not enabled</div>;

  return (
    <>
      <TopBar handleGenerateDesign={handleGenerateDesign} />
      <TopToolbar />
      <RightSideBar controls={controls.current} />
      {modalState && modalState.modalName === 'custom-ps-rotation' && <CustomRotationModal modalState={modalState} />}
      <Canvas
        style={{
          cursor: pointerType === 'MOVE' ? 'grab' : undefined,
          backgroundColor: '#F5F5F5',
        }}
        gl={{ pixelRatio: 2 }}
        onPointerDown={handleOnPointerDown}
        dpr={2}
      >
        <PerspectiveCamera ref={cameraRef} fov={60} far={5000} near={1} makeDefault />

        <CameraControls
          makeDefault
          mouseButtons={{ left: pointerType === 'SELECT' ? 0 : 2, right: 2, wheel: 8, middle: 2 }}
          maxAzimuthAngle={0}
          minPolarAngle={-Infinity}
          maxPolarAngle={Infinity}
          ref={controls}
          dollyToCursor={true}
        />

        {bbox && <Controls bbox={bbox} />}

        {availableAreas &&
          Object.keys(availableAreas).length > 0 &&
          Object.keys(availableAreas).map((key, index) => (
            <PolygonWithoutGeometry
              renderOrder={index}
              key={key}
              userId={key}
              color={availableAreas[key].color}
              areaPolygon={availableAreas[key].exteriorRing}
              innerRings={availableAreas[key].interiorRings}
              type={availableAreas[key].type}
              yPosition={1}
            />
          ))}
        {restrictedAreas &&
          Object.keys(restrictedAreas).length > 0 &&
          Object.keys(restrictedAreas).map((key, index) => (
            <PolygonWithoutGeometry
              renderOrder={index}
              key={key}
              userId={key}
              color={restrictedAreas[key].color}
              areaPolygon={restrictedAreas[key].exteriorRing}
              innerRings={restrictedAreas[key].interiorRings}
              type={restrictedAreas[key].type}
              yPosition={2}
            />
          ))}
        {substationAreas &&
          Object.keys(substationAreas).length > 0 &&
          Object.keys(substationAreas).map((key, index) => (
            <PolygonWithoutGeometry
              renderOrder={index}
              key={key}
              userId={key}
              color={substationAreas[key].color}
              areaPolygon={substationAreas[key].exteriorRing}
              innerRings={substationAreas[key].interiorRings}
              type={substationAreas[key].type}
              yPosition={3}
            />
          ))}

        {switchingStationAreas &&
          Object.keys(switchingStationAreas).length > 0 &&
          Object.keys(switchingStationAreas).map((key, index) => (
            <PolygonWithoutGeometry
              renderOrder={index}
              key={key}
              userId={key}
              color={switchingStationAreas[key].color}
              areaPolygon={switchingStationAreas[key].exteriorRing}
              innerRings={switchingStationAreas[key].interiorRings}
              type={switchingStationAreas[key].type}
              yPosition={3}
            />
          ))}

        {batteryAreas &&
          Object.keys(batteryAreas).length > 0 &&
          Object.keys(batteryAreas).map((key, index) => (
            <PolygonWithoutGeometry
              renderOrder={index}
              key={key}
              userId={key}
              color={batteryAreas[key].color}
              areaPolygon={batteryAreas[key].exteriorRing}
              innerRings={batteryAreas[key].interiorRings}
              type={batteryAreas[key].type}
              yPosition={3}
            />
          ))}

        {rectangleElements && <StructuresInstancedMesh elements={rectangleElements} />}
        <SelectedElementsHOC />
        <ReferenceForRoads />
        <ReferenceStructureForAdd />
        {roads && (
          <GraphLinesController
            nodesGraph={roads}
            isDeletingNode={
              action.type === PrimaryActionTypes.DELETE_NODE ||
              action.type === PrimaryActionTypes.DELETE_NODE_AND_CONNECTING_EDGES
            }
            isSelectingInitialNode={
              action.type === PrimaryActionTypes.ADD_NODE_TO_OTHER_NODE && !action.payload?.connectedNode
            }
            isAddingEdgeBetweenNodes={action.type === PrimaryActionTypes.ADD_EDGE_BETWEEN_NODES}
          />
        )}
        <GuiIn />
        <BottomToolbarIn />
      </Canvas>
      <GuiOut />
      <BottomToolbarOut />
      {(action.type !== PrimaryActionTypes.NONE || secondaryAction.type !== PrimaryActionTypes.NONE) && (
        <div
          style={{
            backgroundColor: 'gray',
            padding: '8px 12px',
            position: 'fixed',
            maxWidth: '380px',
            top: '80px',
            left: '50%',
            transform: 'translate(-50%, 0)',
            color: 'white',
            fontSize: '14px',
            marginTop: '48px',
          }}
        >
          {action.type === PrimaryActionTypes.SELECT_RECTANGLES ? 'Select structures or power stations' : action.type}
          {action.type === PrimaryActionTypes.SELECT_RECTANGLES && action.payload.length > 0 && (
            <div>{action.payload.length} elements selected</div>
          )}
          {action.type === PrimaryActionTypes.ADD_STRUCTURES && (
            <div>
              {action.payload.selectedStructureOption && !action.payload.selectedPs && 'Select PS or structure'}
              {action.payload.selectedStructureOption &&
                action.payload.selectedPs &&
                !action.payload.referenceStructureId &&
                'Click to select reference structure'}
              {action.payload.selectedStructureOption &&
                action.payload.selectedPs &&
                action.payload.referenceStructureId &&
                'Click to add structure'}
            </div>
          )}
          {action.type === PrimaryActionTypes.ADD_EDGE_BETWEEN_NODES && (
            <div>{action.payload?.nodeA ? 'Select node B' : 'Select node A'}</div>
          )}
          {action.type === PrimaryActionTypes.ADD_NODE_TO_CLOSEST && <div>Click to add node</div>}
          {action.type === PrimaryActionTypes.DELETE_NODE && <div>Click to delete node</div>}
          {action.type === PrimaryActionTypes.ADD_NODE_TO_OTHER_NODE && (
            <div>{action.payload?.connectedNode ? 'Click to add node' : 'Select connecting node'}</div>
          )}
          {action.type === PrimaryActionTypes.DELETE_NODE_AND_CONNECTING_EDGES && <div>Click to delete node</div>}
          {secondaryAction.type === SecondaryActionTypes.SELECT_PS_FOR_STRUCTURES && (
            <div>Click PS or structure to modify group</div>
          )}
          {secondaryAction.type === SecondaryActionTypes.SELECT_REFERENCE && !secondaryAction.payload && (
            <div>Click to select reference element</div>
          )}
          {secondaryAction.type === SecondaryActionTypes.SELECT_REFERENCE && secondaryAction.payload && (
            <div>
              First, select a point on one of the moving elements <span style={{ color: 'green' }}>(green)</span>. Next,
              select a point on the reference element <span style={{ color: 'purple' }}>(purple)</span>.
            </div>
          )}
          {secondaryAction.type === SecondaryActionTypes.ALIGN_SELECTED_STRUCTURES_WITH_SETBACKS && (
            <div>
              {secondaryAction.payload === 'closest'
                ? 'Click on the setback line to move your structures the closest possible'
                : 'Click on the setback line to move your structures in parallel'}
            </div>
          )}
          {secondaryAction.type === SecondaryActionTypes.ALIGN_UNIFORMLY && (
            <div>Align structures uniformly. Click on one of the reference points</div>
          )}
        </div>
      )}
      {action.type === PrimaryActionTypes.ADD_STRUCTURES && action.payload?.selectedStructureOption && (
        <div
          style={{
            position: 'fixed',
            top: '60px',
            left: '60px',
            backgroundColor: 'white',
            border: '1px solid black',
            borderRadius: '4px',
            padding: '12px 8px',
          }}
          onClick={() => {
            resetActions();
          }}
        >
          Exit add structures
        </div>
      )}
    </>
  );
};

export default InteractiveLayoutCreator;
