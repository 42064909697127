import styled from 'styled-components';

export const KpisDisplayWrapper = styled.div`
  position: fixed;
  top: 115px;
  right: 50px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.v2.elevation.shadow.m};
  width: 308px;
`;

export const KpisContent = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.xs};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.v2.spacing.xs};
  background-color: ${({ theme }) => theme.v2.bg.secondary.default};
`;

export const PowerDisplay = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.xs};
  background-color: ${({ theme }) => theme.v2.bg.primary.default};
  border-radius: ${({ theme }) => theme.v2.spacing.xxs};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.v2.spacing.xs};
`;
