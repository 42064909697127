export const IndexIds = {
  Homepage: {
    PopupNewFeatureCloseButton: 'popup-new-feature-close-btn',
    Logo: 'header-company-logo',
    AccountButton: 'account-button',
    PageTitle: 'welcome-to-ratedpower',
    ProjectsSubtitle: 'projects-subtitle',
    ProjectsOfCompany: 'projects-of-company',
    Designs: 'designs',
    Map: 'mapbox-canvas',
    DesignsContainer: 'home-projects-list-designs-container',
    SideBarIds: {
      Container: 'side-bar-container',
      Icon: 'side-bar-icon',
      CollapseButton: 'side-bar-collapse-button',
    },
    Tabs: 'home-designs-tabs',
    CreateProject: 'create-project-button',
  },
  SideBarListItemsIds: {
    Icons: {
      Home: 'home-tab',
      MyCompany: 'company-tab',
      Capex: 'sidebar-btn-capex',
      Statistics: 'sidebar-btn-statistics',
      Users: 'sidebar-btn-users',
      Communication: 'sidebar-btn-communication',
    },
  },
  MyCompany: {
    Name: 'company-name',
    NotificationEmail: 'company-email',
    Location: 'company-location',
    AccountTab: 'tab-button-account',
    PlanTab: 'tab-button-plan',
    IntegrationsTab: 'tab-button-integrations',
    CurrentPlan: 'current-plan',
    Header: 'meteorological-data-integrations-header-container',
  },
  Capex: {
    CapexCardPublic: 'capex-card-public',
    CapexCardPrivate: 'capex-card',
    CloneButton: 'capex-clone-btn',
    PublicTab: 'capex-tab-btn-public',
    PrivateTab: 'capex-tab-btn-private',
    SearchInput: 'capex-search-input',
    CreateSheet: {
      NewButton: 'capex-new-sheet-btn',
      NewNameInput: 'capex-new-sheet-modal-name-input',
      CurrencyDropdown: 'capex-new-sheet-modal-currency-dropdown',
      StartFromScratchRadioOption: 'capex-new-sheet-modal-radio-option',
      CreateButton: 'capex-create-sheet-button',
      NameInput: 'capex-name-input-text',
      SaveButton: 'capex-new-sheet-modal-save-btn',
      SaveButtonConfirm: 'capex-save-confirm-btn',
      FolderDropdown: 'capex-new-sheet-modal-folder-dropdown',
      FolderOption: 'capex-new-sheet-modal-folder-dropdown-option-1',
      CountryDropdown: 'capex-new-sheet-modal-country-dropdown',
      CountryOption: 'capex-new-sheet-modal-country-dropdown-option-1',
      CurrencyOption: 'capex-new-sheet-modal-currency-dropdown-option-1',
    },
    CapexTable: {
      RowContainer: 'capex-row-container',
    },
    UpdateSheet: {
      EditableName: 'title-editable',
      EditableInput: 'title-textfield',
    },
    DeleteSheet: {
      DeleteButton: 'capex-card-delete-btn',
      DeleteButtonConfirm: 'capex-delete-confirm-btn',
    },
  },
  CollapsibleSectionHeader: 'collapsible-section-header',
  Loader: 'loader-icon-id',
  Designs: {
    StartDesignButton: 'start-design-button',
    StartPvDesignButton: 'start-pv-design-button',
    RatedPower: 'design-card-main-power-value-text',
    MoreOptions: 'design-card-more-options-btn',
    DesignDeleteBtn: 'design-delete-btn',
    ModalDeleteConfirmBtn: 'modal-delete-confirm-btn',
  },
  DesignProcess: {
    SkipTourButton: 'dp-welcome-modal-skip-btn',
    NewSiteSplitButton: 'dp-new-site-split-button',
    UploadSiteOption: 'dp-upload-site-option',
    CreateNewSiteOption: 'dp-new-site-option',
    RunDesignButton: 'dp-run-design-button',
    SaveDesignButton: 'dp-save-design-button',
    ProjectPageBreadcrumb: 'prev-page-crumb-btn',
    DocumentationButton: 'dp-documentation-button',
    SelectAllDocumentsCheckbox: 'select-all-checkbox',
    UploadTopography: 'dp-upload-topography-btn',
    UploadMeteo: 'dp-upload-meteo-btn',
    SiteSelected: 'site-card-selected',
    HorizonSelected: 'horizon-card-selected',
    TopographySelected: 'topography-card-selected',
    ShowMoreButton: 'topography-show-more-btn',
    TopographyErrorCard: 'topography-error-card',
    MeteoSourceDropdown: 'source-dropdown',
    MeteoSourceOption: 'source-dropdown-option-0',
    UploadMeteoModalButton: 'meteo-upload-btn',
    MeteoTempMin: 'minimum-temperature-input',
    DesignResultParameter: 'design-result-parameter',
    OutputValues: 'output-values',
    TabEnergy: 'tab-button-7',
    UploadHorizon: 'horizon-upload-btn',
    ImportSiteInfoButton: 'import-site-info-btn',
    ImportSiteUploadFileOption: 'upload-file-option',
    ImportModalUploadedFile: 'import-modal-uploaded-file',
    ImportModalButton: 'modal-import-btn',
  },
  Projects: {
    SearchInput: 'projects-search-input',
    NewProjectButton: 'new-project-btn',
    CreateProjectNameInput: 'new-project-name',
    DeleteProject: 'project-delete-btn',
    DeleteProjectConfirm: 'project-delete-modal-confirm-btn',
  },
  CompaniesActivity: {
    NewCompanyButton: 'activity-new-company-btn',
    Tabs: 'company-activity-tabs',
    ActivityTab: 'company-activity-tabs-activity',
    ProjectsTab: 'company-activity-tabs-projects',
    DesignsTab: 'company-activity-tabs-designs',
    CompanyTab: 'company-activity-tabs-company',
    UsersTab: 'company-activity-tabs-users',
    TeamsTab: 'company-activity-tabs-teams',
    CompanyNameCell: 'activity-table-cell-name',
    Capex: 'capex-table-cell-name',
    CompanyName: 'company-name-id',
    DropdownSource: 'dropdown-label-source',
    CompanyNameDropdown: 'dropdown-option-name',
    SearchButton: 'company-search-btn',
    CreateCompanyFromScratch: 'company-create-from-scratch-link',
    CompanyCreateButton: 'company-create-btn',
    CompanyEmail: 'company-email-input',
    CompanyLocation: 'company-location-dropdown',
    SuccessToast: 'info-toast-success',
    ErrorToast: 'info-toast-error',
    NewUserButton: 'companies-new-user-btn',
    UserName: 'new-user-name',
    Surname: 'new-user-surname',
    Email: 'new-user-email',
    Role: 'user-dropdown-label-role',
    UserType: 'user-dropdown-label-user-type',
    Language: 'user-dropdown-label-language',
    JobTitle: 'user-dropdown-label-job-title',
    CreateButton: 'user-create-btn',
    EditButton: 'actions-option-edit',
    Actions: 'actions-list-btn',
    SearchUserInput: 'activity-search-input',
  },
  CustomerStatistics: {
    PlanInformationContainer: 'statistics-plan-info-container',
    Tabs: {
      Projects: 'tab-button-projects',
      Designs: 'tab-button-designs',
      Sites: 'tab-button-sites',
      Users: 'tab-button-users',
      Equipment: 'tab-button-equipment',
    },
    SiteNetSurfaceArea: 'statistics-site-net-surface-area-wrapper',
  },
  Equipment: {
    ModuleDetails: 'equipment-view-details-btn',
    StructureDetails: 'structure-view-details-btn',
    StructureDetailsContainer: 'structure-characteristics-container',
    SaveStructureButton: 'save-structure-btn',
    ChangeDefaultEquipmentButton: 'change-default-equipment-btn',
    ModulesTableManufacturerColumn: 'modules-table-manufacturer-column',
    ModulesTableModelColumn: 'modules-table-model-column',
    ChangeDefaultStructureButton: 'change-default-structure-btn',
    ChangeDefaultInverterColumn: 'change-default-inverter-column',
    ChangeDefaultInverterButton: 'change-default-inverter-btn',
    InverterModelColumnName: 'inverter-model-column-name',
    ChangeDefaultStructureColumn: 'change-default-structure-column',
    StructureModelColumnName: 'structure-model-column-name',
  },
  Toasts: {
    SUCCESS_DEFAULT: 'toast-success-default',
  },
  Users: {
    Tabs: {
      Users: 'tab-button-users',
      Teams: 'tab-button-teams',
      Roles: 'tab-button-roles',
    },
    TeamsTabs: {
      Projects: 'teams-projects-tab-btn',
      Members: 'teams-members-tab-btn',
      Settings: 'teams-settings-tab-btn',
    },
    NewUserButton: 'users-new-user-btn',
    NewTeamButton: 'users-new-team-btn',
    CloseButton: 'users-close-btn',
    SuccessToast: 'info-toast-success',
    ErrorToast: 'info-toast-error',
    SaveUserButton: 'save-user-button',
    EditUserButton: 'edit-user-button',
    TeamsTitle: 'teams-title',
    CreateTeamButton: 'create-team-btn',
    TeamTitle: 'team-card-team-name-text',
    TeamTitleLeftList: 'team-card-team-name-text-left-list',
    TeamCard: 'team-card',
    AddMemberButton: 'add-members-btn',
    CreateProjectButton: 'create-btn',
    ProjectsNameTitle: 'projects-name-title',
    AddMembersButton: 'add-members-btn',
    AddMembersButtonModal: 'add-members-btn-modal',
    MemberName: 'table-name-value',
    DeleteIcon: 'table-user-delete-btn',
    RemoveMemberButton: 'remove-user-from-team-btn',
    SearchUserInput: 'team-search-input',
    TeamsDropdown: 'teams-dropdown',
  },
  Communication: {
    CreateNotificationButton: 'create-notification-btn',
    CreateNotificationModal: {
      Modal: 'create-notification-modal',
      Title: 'modal-section-title-input',
      Description: 'modal-description-input',
      DescriptionCounter: 'modal-description-counter',
      IconSelect: 'modal-icon-dropdown-select',
      IconSelectSearch: 'modal-icon-search-input',
      IconSelectOption: 'modal-icon-option',
      AddListItemButton: 'modal-add-list-item-btn',
      ListItemContainer: 'list-item-container',
      ListItemInput: 'list-item-input',
      DeleteListItem: 'delete-list-item-btn',
      LinkContainer: 'link-container',
      AddLinkItemButton: 'add-links-btn',
      LinkUrlInput: 'link-url-input',
      LinkTitleInput: 'link-title-input',
      LinkItemDelete: 'link-delete-btn',
      SaveNotificationButton: 'modal-save-btn',
      CancelNotificationButton: 'modal-cancel-btn',
      CloseModalButton: 'modal-close-btn',
    },
    EditNotificationModal: {
      NotifyAllUsersButton: 'Notify-all-checkbox',
    },
    Notification: {
      Container: 'communication-notification-container',
      ContainerUnread: 'communication-notification-container-unread',
      Date: 'notification-date',
      EditButton: 'notification-edit-btn',
      DeleteButton: 'notification-delete-btn',
      Icon: 'notification-displayed-icon',
      Title: 'notification-displayed-title',
      Content: 'notification-content',
      ExpandContentButton: 'notification-expand-content-btn',
      CollapsedArea: 'notification-content-collapsable',
      ListItem: 'notification-content-item',
      LinkItem: 'notification-content-link',
      PaginationContainer: 'notification-pagination-container',
      PaginationLeftButton: 'pagination-arrow-left',
      PaginationRightButton: 'pagination-arrow-right',
      EnvelopeOpenIcon: 'envelope-icon-open',
      EnvelopeClosedIcon: 'envelope-icon-closed',
    },
    NotificationHeaderIcon: {
      Container: 'notification-header-notification-icon',
      Dot: 'notification-dot-indicator',
    },
    MarkAllAsReadButton: 'mark-all-as-read-btn',
  },
  Maintenance: {
    Container: 'maintenance-container',
  },
};
