import { Permission } from 'access-control/permissions';
import {
  AnalyticsSvg,
  BriefcaseSvg,
  CommunicationSvg,
  ComputerSvg,
  EquipmentSvg,
  FolderSvg,
  HomeSvg,
  PeopleSvg,
  SheetSvg,
  StatisticsSvg,
} from 'assets/icons';
import { dateHasExpired } from 'utils/date';
import CommunicationPermissions from 'pages/communication/communication-permissions';
import { IndexIds } from 'index.ids';

export const paths = {
  home: '/',
  profile: '/profile',
  projects: '/projects',
  equipment: '/equipment',
  capex: '/capex',
  myCompany: '/my-company',
  statistics: '/statistics',
  customerStatistics: '/customer-statistics',
  analytics: '/analytics',
  usersManagement: '/users-management',
  companiesActivity: '/companies-activity',
  communication: '/communication',
  notifications: '/notifications',
};

type SidebarListItem = {
  perform: Permission | Permission[];
  pathTo: string;
  t: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  hasSeparator: boolean;
  isNew?: boolean;
  iconDimensions?: { width: string; height: string };
  dataTest?: string;
};

const getPermissionsBySuffix = (suffix: string): Permission[] =>
  Object.values(Permission)
    .filter((permissionName) => permissionName.split('_')[0] === suffix)
    .map((permission) => Permission[permission] as Permission);

const daysToExpire = 20;
const sidebarListItems: SidebarListItem[] = [
  {
    perform: [...getPermissionsBySuffix('PROJECT')],
    pathTo: paths.home,
    t: 'home:title',
    Icon: HomeSvg,
    hasSeparator: false,
    dataTest: IndexIds.SideBarListItemsIds.Icons.Home,
  },
  {
    perform: [...getPermissionsBySuffix('PROJECT')],
    pathTo: paths.projects,
    t: 'project:projects',
    Icon: FolderSvg,
    hasSeparator: false,
    dataTest: 'projects-tab',
  },
  {
    perform: [Permission.EQUIPMENT_READ, Permission.EQUIPMENT_SUPPORT_READ],
    pathTo: paths.equipment,
    t: 'equipment',
    Icon: EquipmentSvg,
    hasSeparator: false,
    dataTest: 'equipment-tab',
  },
  {
    perform: [Permission.FINANCIAL_READ, Permission.FINANCIAL_SUPPORT_READ],
    pathTo: paths.capex,
    t: 'design:capex-templates',
    Icon: SheetSvg,
    hasSeparator: true,
    dataTest: IndexIds.SideBarListItemsIds.Icons.Capex,
  },
  {
    perform: [Permission.COMPANY_READ, Permission.COMPANY_SUPPORT_READ],
    pathTo: paths.myCompany,
    t: 'company:my-company',
    Icon: BriefcaseSvg,
    hasSeparator: false,
    dataTest: IndexIds.SideBarListItemsIds.Icons.MyCompany,
  },
  {
    perform: [
      Permission.USER_READ_FROM_USER_COMPANY,
      Permission.USER_READ_FROM_USER_TEAM,
      Permission.USER_SUPPORT_READ,
    ],
    pathTo: paths.usersManagement,
    t: 'user:users-management',
    Icon: PeopleSvg,
    hasSeparator: true,
    dataTest: IndexIds.SideBarListItemsIds.Icons.Users,
  },
  {
    perform: Permission.COMPANY_SUPPORT_READ,
    pathTo: paths.companiesActivity,
    t: 'company:companies-activity',
    Icon: ComputerSvg,
    hasSeparator: false,
    dataTest: 'companies-activity-tab',
  },
  {
    perform: Permission.STATISTICS_SUPPORT_READ,
    pathTo: paths.statistics,
    t: 'statistics:internal-statistics',
    Icon: StatisticsSvg,
    hasSeparator: false,
  },
  {
    perform: [Permission.CUSTOMER_STATISTICS_READ],
    pathTo: paths.customerStatistics,
    t: 'statistics:statistics-and-kpis',
    Icon: StatisticsSvg,
    hasSeparator: false,
    isNew: !dateHasExpired(new Date('2024-04-28'), daysToExpire),
    dataTest: IndexIds.SideBarListItemsIds.Icons.Statistics,
  },
  {
    perform: Permission.CLUSTER_SUPPORT_READ,
    pathTo: paths.analytics,
    t: 'analytics:analytics',
    Icon: AnalyticsSvg,
    hasSeparator: false,
  },
  {
    perform: CommunicationPermissions,
    pathTo: paths.communication,
    t: 'communication:communication-page-title',
    Icon: CommunicationSvg,
    hasSeparator: false,
    dataTest: IndexIds.SideBarListItemsIds.Icons.Communication,
  },
];

export default sidebarListItems;
