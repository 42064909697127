/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createColumnHelper } from '@tanstack/table-core';
import {
  ConfigSvg,
  CrossSvg,
  DotsSvg,
  FolderSvg,
  GridSvg,
  LegendSvg,
  PeopleSvg,
  TeamsSvg,
  UserRolesSvg,
} from 'assets/icons';
import { TFunction } from 'i18next';
import React from 'react';
import {
  Button,
  ConditionalWrapper,
  ICheckboxOption,
  IPopupIconButtonOption,
  ITab,
  LinkText,
  PopupIconButton,
  SortableHeader,
  SVGWrapper,
  Tag,
  Tooltip,
  Typography,
} from '@ratedpower/components';
import { ISort } from 'types/filter';
import { IProjectListItem } from 'types/projects';
import { IMemberDTO, ITeam, ITeamsFilters, IUserTeamsAssigned, TeamActions, TeamContentType } from 'types/teams';
import { getDateMonthDDYYYY } from './date';
import { DefaultTheme } from 'styled-components';
import { IUserMinInfoDTO } from 'types/users';
import { GenericStatus, STATUS_COLORS } from 'utils/constants/status';
import { NavigateFunction } from 'react-router-dom';
import { IndexIds } from 'index.ids';

export const CARD_VIEW_PAGE_SIZE = 10;
export const LIST_VIEW_PAGE_SIZE = 20;
export const SIDEBAR_VIEW_PAGE_SIZE = 10;
export const TEAMS_PAGE_SIZE = 10;
export const ADD_PROJECT_PAGE_SIZE = 8;
export const ADD_MEMBER_PAGE_SIZE = 8;
export const TEAM_PROJECTS_PAGE_SIZE = 12;

export const viewOptions = [
  {
    Icon: GridSvg,
    value: 'grid',
  },
  {
    Icon: LegendSvg,
    value: 'list',
  },
];

export const teamActions: IPopupIconButtonOption[] = [
  {
    label: 'user:teams.add-projects',
    value: TeamActions.ADD_PROJECT,
    Icon: FolderSvg,
  },
  {
    label: 'user:teams.add-members',
    value: TeamActions.ADD_MEMBER,
    Icon: PeopleSvg,
  },
  {
    label: 'user:teams.team-settings',
    value: TeamActions.TEAM_SETTINGS,
    Icon: ConfigSvg,
    borderBottom: true,
  },
  {
    label: 'user:teams.delete-team',
    value: TeamActions.DELETE_TEAM,
    Icon: CrossSvg,
  },
];

export const filterCountProjectsOptions: ICheckboxOption[] = [
  { label: 'all', value: 'All', checked: true },
  { label: '< 5', value: '< 5', checked: false },
  { label: '5 - 10', value: '5 - 10', checked: false },
  { label: '> 10', value: '> 10', checked: false },
];

export const filterCountMembersOptions: ICheckboxOption[] = [
  { label: 'all', value: 'All', checked: true },
  { label: '< 5', value: '< 5', checked: false },
  { label: '5 - 10', value: '5 - 10', checked: false },
  { label: '> 10', value: '> 10', checked: false },
];

export const initialTeamFilters: ITeamsFilters = {
  countProjects: ['All'],
  countMembers: ['All'],
  teamManagers: [],
  countries: [],
};

export const teamTabs: ITab[] = [
  {
    id: TeamContentType.PROJECTS,
    label: 'project:projects',
    Icon: PeopleSvg,
    dataTest: IndexIds.Users.TeamsTabs.Projects,
  },
  {
    id: TeamContentType.MEMBERS,
    label: 'user:teams.members',
    Icon: TeamsSvg,
    dataTest: IndexIds.Users.TeamsTabs.Members,
  },
  {
    id: TeamContentType.SETTINGS,
    label: 'user:teams.settings',
    Icon: UserRolesSvg,
    dataTest: IndexIds.Users.TeamsTabs.Settings,
  },
];

export const getDefaultCoverColors = (theme: DefaultTheme) => [
  theme.chart_blue_light,
  theme.purple_light,
  theme.green_light,
  theme.chart_yellow_light,
];

export const getDefaultCoverColorsForUpdate = (theme: DefaultTheme) => [
  theme.chart_blue_light,
  theme.purple_light,
  theme.green_light,
  theme.chart_yellow_light,
  theme.chart_pink,
  theme.chart_red_dark,
  theme.purple,
  theme.chart_cyan,
];

const teamColumnHelper = createColumnHelper<ITeam>();

export const teamsColumns = (
  t: TFunction,
  options: {
    isExternalAdmin: boolean;
    sortedBy: ISort;
    dropdownActions: IPopupIconButtonOption[];
    setSortedBy: (filter: ISort) => void;
    handleTeamAction: (id: string, teamName: string) => (option: IPopupIconButtonOption) => void;
  }
) => [
  teamColumnHelper.accessor('name', {
    cell: (info) => <Typography variant="body-text">{info.getValue() ?? ''}</Typography>,
    header: () => (
      <SortableHeader
        selector={{ code: 'name', label: t('name') }}
        sortedBy={options.sortedBy}
        onSort={options.setSortedBy}
      />
    ),
  }),
  teamColumnHelper.accessor('teamManagersList', {
    cell: (info) => {
      const printTeamManagersList = (teamManagers: IUserMinInfoDTO[]) => {
        if (teamManagers.length === 1) {
          return teamManagers[0].name + ' ' + teamManagers[0].surname;
        }
        return teamManagers[0].name + ' ' + teamManagers[0].surname + '...';
      };
      return (
        <>
          {info.getValue() && !!info.getValue()?.length && (
            <ConditionalWrapper
              condition={info.getValue()!.length > 1}
              wrapper={(children) => (
                <Tooltip
                  description={info
                    .getValue()
                    ?.reduce<string>(
                      (previous, currentValue) => previous + ' ' + currentValue.name + ' ' + currentValue.surname + ',',
                      ''
                    )
                    .slice(0, -1)}
                >
                  {children}
                </Tooltip>
              )}
            >
              <Typography>{printTeamManagersList(info.getValue()!)}</Typography>
            </ConditionalWrapper>
          )}
        </>
      );
    },
    header: () => (
      <SortableHeader
        selector={{ code: 'createdBy.name', label: t('user:teams.team-manager') }}
        sortedBy={options.sortedBy}
        onSort={options.setSortedBy}
      />
    ),
  }),
  teamColumnHelper.accessor('numProjects', {
    cell: (info) => <Typography variant="body-text">{info.getValue() ?? ''}</Typography>,
    header: () => (
      <SortableHeader
        selector={{ code: 'numProjects', label: t('project:projects') }}
        sortedBy={options.sortedBy}
        onSort={options.setSortedBy}
      />
    ),
  }),
  teamColumnHelper.accessor('numMembers', {
    cell: (info) => <Typography variant="body-text">{info.getValue() ?? ''}</Typography>,
    header: () => (
      <SortableHeader
        selector={{ code: 'numMembers', label: t('user:teams.members') }}
        sortedBy={options.sortedBy}
        onSort={options.setSortedBy}
      />
    ),
  }),
  teamColumnHelper.accessor('description', {
    cell: (info) => <Typography variant="body-text">{info.getValue() ?? ''}</Typography>,
    header: () => (
      <SortableHeader
        selector={{ code: 'description', label: t('description') }}
        sortedBy={options.sortedBy}
        onSort={options.setSortedBy}
      />
    ),
  }),
  teamColumnHelper.accessor('id', {
    cell: (info) => (
      <PopupIconButton
        Icon={DotsSvg}
        onClickOption={options.handleTeamAction(info.getValue(), info.row.original.name)}
        size="medium"
        popupPosition="leftCenter"
        containerWidth="180px"
        options={options.dropdownActions.map((teamAction: IPopupIconButtonOption) => ({
          ...teamAction,
          label: t(teamAction.label),
        }))}
      />
    ),
    header: undefined,
  }),
];

const teamProjectColumnHelper = createColumnHelper<IProjectListItem>();

export const teamProjectsListColumns = (
  t: TFunction,
  theme: DefaultTheme,
  navigate: NavigateFunction,
  options: {
    sortedBy: ISort;
    projectNameClickable: boolean;
    setSortedBy: (filter: ISort) => void;
    onUnassignProject?: (projectId: string, projectName: string) => void;
  }
) => [
  teamProjectColumnHelper.accessor('name', {
    cell: (info) =>
      options.projectNameClickable ? (
        <LinkText
          color={theme.v2.btn.link.text.default}
          textUnderlined
          onClick={() => navigate(`/projects/${info.row.original.id}`)}
          dataTest={IndexIds.Users.ProjectsNameTitle}
        >
          {info.getValue() ?? ''}
        </LinkText>
      ) : (
        <Typography>{info.getValue() ?? ''}</Typography>
      ),
    header: () => (
      <SortableHeader
        selector={{ code: 'name', label: t('name') }}
        sortedBy={options.sortedBy}
        onSort={options.setSortedBy}
      />
    ),
  }),

  teamProjectColumnHelper.accessor('numDesigns', {
    cell: (info) => <Typography>{info.getValue() ?? ''}</Typography>,
    header: () => (
      <SortableHeader
        selector={{ code: 'numDesigns', label: t('project:num-designs') }}
        sortedBy={options.sortedBy}
        onSort={options.setSortedBy}
      />
    ),
  }),
  teamProjectColumnHelper.accessor('country', {
    cell: (info) => (
      <Typography nowrap>
        {info.row.original.country || '-'}
        {info.row.original.region ? `, ${info.row.original.region}` : ''}
        {info.row.original.city ? `, ${info.row.original.city}` : ''}
      </Typography>
    ),
    header: () => (
      <SortableHeader
        selector={{ code: 'country', label: t('company:location') }}
        sortedBy={options.sortedBy}
        onSort={options.setSortedBy}
      />
    ),
  }),
  teamProjectColumnHelper.accessor('createdBy', {
    cell: (info) => (
      <Typography nowrap>{`${info.row.original.createdBy?.name} ${info.row.original.createdBy?.surname}`}</Typography>
    ),
    header: () => (
      <SortableHeader
        selector={{ code: 'createdBy.name', label: t('created-by') }}
        sortedBy={options.sortedBy}
        onSort={options.setSortedBy}
      />
    ),
  }),
  teamProjectColumnHelper.accessor('publishedDate', {
    cell: (info) => (
      <Typography nowrap>
        {t('project:list-project-date', { dateFormat: getDateMonthDDYYYY(new Date(info.getValue())) })}
      </Typography>
    ),
    header: () => (
      <SortableHeader
        selector={{ label: t('date'), code: 'publishedDate' }}
        sortedBy={options.sortedBy}
        onSort={options.setSortedBy}
      />
    ),
  }),
  teamProjectColumnHelper.accessor('status', {
    header: () => (
      <SortableHeader
        onSort={options.setSortedBy}
        selector={{
          label: t('support:status'),
          code: 'status',
        }}
        sortedBy={options.sortedBy}
      />
    ),
    cell: (info) => {
      if (![GenericStatus.FINISHED, GenericStatus.ARCHIVED].includes(info.getValue() as GenericStatus)) return <></>;
      return (
        <Tag color={STATUS_COLORS[info.getValue() as GenericStatus]} ellipsis={false}>
          {info.getValue() === GenericStatus.FINISHED ? t('user:active') : t('archived')}
        </Tag>
      );
    },
  }),
  teamProjectColumnHelper.accessor('id', {
    cell: (info) =>
      !!options.onUnassignProject ? (
        <SVGWrapper
          icon={CrossSvg}
          onClick={() => options.onUnassignProject!(info.getValue(), info.row.original.name)}
          size="m"
        />
      ) : (
        <></>
      ),
    header: undefined,
  }),
];

const teamMemberColumnHelper = createColumnHelper<IMemberDTO>();

export const teamMemberListColumns = (
  t: TFunction,
  options: {
    sortedBy: ISort;
    setSortedBy: (filter: ISort) => void;
    onUnassignUser?: (userId?: string, userName?: string) => void;
    onClickTeamsAssigned: (userId?: string) => void;
  }
) => [
  teamMemberColumnHelper.accessor('name', {
    header: () => (
      <SortableHeader
        selector={{ label: t('name'), code: 'name' }}
        sortedBy={options.sortedBy}
        onSort={options.setSortedBy}
      />
    ),
    cell: (info) => <Typography dataTest={IndexIds.Users.MemberName}>{info.getValue() ?? ''}</Typography>,
  }),
  teamMemberColumnHelper.accessor('lastname', {
    header: () => (
      <SortableHeader
        selector={{ label: t('user:lastname'), code: 'surname' }}
        sortedBy={options.sortedBy}
        onSort={options.setSortedBy}
      />
    ),
    cell: (info) => <Typography>{info.getValue() ?? ''}</Typography>,
  }),
  teamMemberColumnHelper.accessor('codeLanguage', {
    header: () => t('user:teams.teams-assigned'),
    cell: (info) => (
      <Button
        variant="link"
        textUnderlined
        onClick={(e) => {
          e.stopPropagation();
          options.onClickTeamsAssigned(info.row.original.id);
        }}
        text={t('user:teams.view-teams')}
      />
    ),
  }),
  teamMemberColumnHelper.accessor('email', {
    header: () => (
      <SortableHeader
        selector={{ label: t('user:email'), code: 'email' }}
        sortedBy={options.sortedBy}
        onSort={options.setSortedBy}
      />
    ),
    cell: (info) => <Typography>{info.getValue() ?? ''}</Typography>,
  }),
  teamMemberColumnHelper.accessor('jobTitle', {
    header: () => (
      <SortableHeader
        selector={{ label: t('user:job-title'), code: 'jobTitle' }}
        sortedBy={options.sortedBy}
        onSort={options.setSortedBy}
      />
    ),
    cell: (info) => <Typography>{info.getValue() ?? ''}</Typography>,
  }),
  teamMemberColumnHelper.accessor('roleName', {
    header: () => (
      <SortableHeader
        selector={{ label: t('user:role'), code: 'role.name' }}
        sortedBy={options.sortedBy}
        onSort={options.setSortedBy}
      />
    ),
    cell: (info) => <Typography>{info.getValue() ?? ''}</Typography>,
  }),

  teamMemberColumnHelper.accessor('id', {
    cell: (info) =>
      options.onUnassignUser ? (
        <SVGWrapper
          icon={CrossSvg}
          dataTest={IndexIds.Users.DeleteIcon}
          onClick={() => options.onUnassignUser!(info.getValue(), info.row.original.name)}
          size="m"
        />
      ) : (
        <></>
      ),
    header: undefined,
  }),
];

const userTeamsAssignedHelper = createColumnHelper<IUserTeamsAssigned>();

export const userTeamsAssignedColumns = (t: TFunction, isTeamManager: boolean) => [
  userTeamsAssignedHelper.accessor('name', {
    header: () => t('user:teams.team-name'),
    cell: (info) => {
      const isLastRow = info.table.getRowModel().rows.length - 1 === info.row.index;
      return (
        <Typography>
          {isLastRow && isTeamManager && !!Number(info.getValue())
            ? `${t('user:teams.other-teams')} (${info.getValue()})`
            : info.getValue()}
        </Typography>
      );
    },
  }),
  userTeamsAssignedHelper.accessor('numProjects', {
    header: () => t('user:teams.no-projects'),
    cell: (info) => {
      const isLastRow = info.table.getRowModel().rows.length - 1 === info.row.index;
      if (isLastRow && isTeamManager && !!Number(info.row.original.name)) {
        return <Typography>{Number(info.getValue()) || '-'}</Typography>;
      }
      return <Typography>{info.getValue()}</Typography>;
    },
  }),
];

export const coverIsColor = (teamCover: string) => {
  if (teamCover[0] === '#') {
    teamCover = teamCover.slice(1);
  }

  if (teamCover.length !== 3 && teamCover.length !== 6) {
    return false;
  }

  for (const char of teamCover) {
    const teamCoverChar = char.toLowerCase();
    const isHexDigit = (teamCoverChar >= '0' && teamCoverChar <= '9') || (teamCoverChar >= 'a' && teamCoverChar <= 'f');
    if (!isHexDigit) {
      return false;
    }
  }
  return true;
};
