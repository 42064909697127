import React, { useRef } from 'react';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';
import ProjectsFilterPanel from 'components/filters/projects-filter-panel/projects-filter-panel';
import { RPTable } from '@ratedpower/components';
import EmptyTeamContent from '../components/empty-team-content';
import { useTranslation } from 'react-i18next';
import CreateProjectModal from '../components/create-project-modal';
import TopbarTeamContent from './topbar-team-content';
import { useListTeamProjects } from '../hooks/use-list-team-projects';
import { ErrorWrapper } from './topbar-team-content/topbar-team-content.styled';
import UnassignProjectModal from '../components/unassign-project-modal';
import { TeamContentContainer } from './team-management.styled';
import { useTeamsProvider } from '../teams-data';
import { TEAM_PROJECTS_PAGE_SIZE } from 'utils/teams';
import { useLocation, useParams } from 'react-router-dom';

const TeamProjects: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { addProjectsModal, createProjectModal } = useTeamsProvider();
  const selectedTeamId = useRef<string | undefined>('');
  const { id: companyId, teamId } = useParams<{ id: string; teamId: string }>();
  const routeState = location.state as { teamName: string };

  const {
    table,
    error,
    isEmptyData,
    inputSearchProps,
    projectFilterPanelProps: { overlayProps, toggleOverlay },
    unassignProjectModal,
    paginationComponent,
  } = useListTeamProjects(TEAM_PROJECTS_PAGE_SIZE, teamId);

  const handleCreateProjectModal = (open: boolean) => () => {
    selectedTeamId.current = open ? teamId : '';
    createProjectModal.setModalOpen(open);
  };

  return (
    <TeamContentContainer>
      <TopbarTeamContent onSearch={inputSearchProps.onChange} onOpenFiltersPanel={toggleOverlay} />
      {isEmptyData ? (
        <EmptyTeamContent
          onAddProject={() => addProjectsModal.setModalOpen(true)}
          onCreateProject={handleCreateProjectModal(true)}
        />
      ) : (
        table && !error && <RPTable table={table as any} zeroResultsFoundText={t('common:zero-results-found')} />
      )}
      {paginationComponent}
      {error && (
        <ErrorWrapper>
          <ErrorRetrievingData />
        </ErrorWrapper>
      )}
      <ProjectsFilterPanel
        filters={overlayProps.filters || []}
        isOpen={overlayProps.isOpen}
        onApply={overlayProps.onApply}
        onSelect={overlayProps.onSelect}
        onClear={overlayProps.onClear}
        onClose={toggleOverlay}
      />
      {createProjectModal.isOpen && (
        <CreateProjectModal
          addPublicOption={false}
          selectedTeamId={selectedTeamId.current || teamId}
          isActive={createProjectModal.isOpen}
          title={
            selectedTeamId.current
              ? t('user:teams.create-new-project-in', { team: routeState?.teamName })
              : t('user:teams.create-new-project')
          }
          isSupport={!!companyId}
          disableClickOutside
          onClickPrimaryBtn={handleCreateProjectModal(false)}
          onClickSecondaryBtn={handleCreateProjectModal(false)}
          onClose={handleCreateProjectModal(false)}
          width="456px"
        />
      )}

      {unassignProjectModal?.isOpen && (
        <UnassignProjectModal
          selectedTeamId={teamId ?? ''}
          description={t('user:teams.unassign-project-modal-description', {
            project: unassignProjectModal.data.projectName,
            team: routeState?.teamName,
          })}
          selectedProjectId={unassignProjectModal.data.projectId}
          title={t('user:teams.remove-project-from-team')}
          isActive={unassignProjectModal?.isOpen}
          onClose={() => unassignProjectModal.setModalOpen(false)}
        />
      )}
    </TeamContentContainer>
  );
};

export default TeamProjects;
