import styled from 'styled-components';

export const TopToolbarWrapper = styled.div`
  position: fixed;
  top: 49px;
  z-index: 1;
  width: 100%;
`;

export const TopToolbarTabRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.v2.spacing.xs};
  align-self: center;
  padding: 0 ${({ theme }) => theme.v2.spacing.l};
  background-color: ${({ theme }) => theme.v2.bg.primary.default};
  box-shadow: ${({ theme }) => theme.v2.elevation.shadow.l};
  width: 100%;
`;

export const TopToolbarButtonsRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.v2.spacing.xs};
  align-self: center;
  padding: ${({ theme }) => theme.v2.spacing.xxs} ${({ theme }) => theme.v2.spacing.l};
  background-color: ${({ theme }) => theme.v2.bg.primary.default};
  box-shadow: ${({ theme }) => theme.v2.elevation.shadow.l};
  width: 100%;
`;

export const Divider = styled.div`
  height: 16px;
  width: 1px;
  background-color: ${({ theme }) => theme.v2.stroke.primary.default};
  align-self: center;
`;
