import styled from 'styled-components';

export const ReadOnlyRolesCustomStyled = styled.div`
  display: grid;
`;

export const ReadOnlyRolesRow = styled.div`
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
`;

export const BaseCell = styled.div<{ $textAlign?: string; $bgColor?: string }>`
  padding: ${({ theme }) => theme.v2.spacing.s};
  background-color: ${({ $bgColor }) => $bgColor ?? 'transparent'};
  text-align: ${({ $textAlign }) => $textAlign ?? 'center'};
  border-bottom: 1px solid ${({ theme }) => theme.v2.stroke.primary.default};
`;
