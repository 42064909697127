import { SIMULATION_CATEGORY } from 'types/design';

const DP_ROUTE = '/design-process';
const SA_BESS_DP_ROUTE = '/standalone-bess';

const buildRelativeDesignProjectUrl = (designId: string, simulationCategory: SIMULATION_CATEGORY): string => {
  if (simulationCategory === SIMULATION_CATEGORY.STANDALONE_BATTERY) {
    return `standalone-bess/${designId}`;
  } else {
    return `design-process/${designId}`;
  }
};

const buildProjectUrl = (projectId = ''): string => `/projects/${projectId}`;

const isDesignProcess = (path: string): boolean => path.includes(DP_ROUTE) || path.includes(SA_BESS_DP_ROUTE);

const buildDesignProcessUrl = (
  projectId: string,
  designId: string,
  simulationCategory: SIMULATION_CATEGORY,
  isResults: boolean
): string => {
  const projectUrl = buildProjectUrl(projectId);
  const dpRoute = simulationCategory === SIMULATION_CATEGORY.STANDALONE_BATTERY ? SA_BESS_DP_ROUTE : DP_ROUTE;
  return isResults ? `${projectUrl}${dpRoute}/${designId}` : `${projectUrl}${dpRoute}?clonedDesignId=${designId}`;
};

const buildIscUrl = (
  endpoint: 'uploadSiteISC' | 'createDraftSite' | 'updateDraftSite',
  entityId: string,
  category?: SIMULATION_CATEGORY
): string => {
  const endpointWithParams = endpoint === 'uploadSiteISC' && category ? `${endpoint}?category=${category}` : endpoint;
  return buildProjectUrl(entityId) + `/${endpointWithParams}`;
};

export {
  buildRelativeDesignProjectUrl,
  buildProjectUrl,
  isDesignProcess,
  buildDesignProcessUrl,
  buildIscUrl,
  DP_ROUTE,
  SA_BESS_DP_ROUTE,
};
