import React from 'react';
import HomeProjectsDesigns from 'pages/home/home-designs/home-project-item/home-projects-designs/home-projects-designs';
import * as S from './home-project-item.styled';
import { HomeProjectInfoContainer } from './home-project-item.styled';
import { Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { IProjectListItem } from 'types/projects';
import { getDateShortMonthDDYYYY } from 'utils/date';
import HomeProjectInfoRow from 'pages/home/home-designs/home-project-item/home-project-info-row';

interface IHomeProjectItem {
  project: IProjectListItem;
  isFavorite: boolean;
}

interface IRowConfig {
  label: string;
  content: string;
  condition?: () => boolean;
}

const HomeProjectItem = ({ project, isFavorite }: IHomeProjectItem) => {
  const { t } = useTranslation();
  const creationDate = getDateShortMonthDDYYYY(new Date(project.publishedDate));

  const rowsConfig: IRowConfig[] = [
    {
      label: '',
      content: `${project.city}, ${project.region}. ${project.country}`,
      condition: () => !!(project.region && project.country),
    },
    {
      label: 'user:teams.team',
      content: project.teamName ?? '',
      condition: () => !!project.teamName,
    },
    {
      label: 'home:designs.projects-list.total-designs',
      content: `${project.numDesigns}`,
    },
    {
      label: 'home:designs.projects-list.my-designs',
      content: `${project.numDesignsCreatedByUser}`,
      condition: () => project.numDesignsCreatedByUser !== undefined,
    },
    {
      label: 'home:designs.projects-list.project-created',
      content: creationDate,
    },
  ];

  return (
    <S.HomeProjectItemContainer>
      <HomeProjectInfoContainer>
        <S.ProjectLink to={`/projects/${project.id}`}>
          <Typography size={'m'} weight={'bold'} nowrap>
            {project.name}
          </Typography>
        </S.ProjectLink>
        {rowsConfig.map(({ label, content, condition }, index) => {
          const conditionToDisplay: boolean = !condition || condition();
          const rowKey = label || index.toString();

          return conditionToDisplay && <HomeProjectInfoRow key={rowKey} content={content} label={t(label)} />;
        })}
      </HomeProjectInfoContainer>
      <HomeProjectsDesigns projectId={project.id} isFavorite={isFavorite} />
    </S.HomeProjectItemContainer>
  );
};
export default HomeProjectItem;
