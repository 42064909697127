import { IServerFilter } from 'types/filter';

export const DesignSortKeys: { [key: string]: { [key: string]: string } } = {
  group1: {
    name: 'name',
    date: 'publishedDate',
  },
  group2: {
    designType: 'simulationType',
    ratedPower: 'outputs.ratedPower',
    peakPower: 'outputs.peakPower',
    ratioDcAc: 'outputs.ratioDcAc',
    specificProduction: 'outputs.specificProduction',
    plantPR: 'outputs.plantPR',
  },
  group3: {
    meteoGhi: 'meteoData.summary.ghi',
    meteoTempAvg: 'meteoData.summary.tempAvg',
  },
  group4: {
    gcr: 'outputs.gcr',
  },
};

export const DesignSortKeysAtServer: { [key: string]: string } = {
  'name': 'name',
  'publishedDate': 'publishedDate',
  'simulationType': 'simulationType',
  'outputs.ratedPower': 'outputsSimulation.ratedPower',
  'outputs.peakPower': 'outputsSimulation.peakPower',
  'outputs.ratioDcAc': 'outputsSimulation.ratioDcAc',
  'outputs.specificProduction': 'outputsSimulation.specificProduction',
  'outputs.plantPR': 'outputsSimulation.plantPR',
  'meteoData.summary.ghi': 'meteoData.meteoDataSummary.yearGhiWh',
  'meteoData.summary.tempAvg': 'meteoData.meteoDataSummary.tempAvgHistoric',
  'outputs.gcr': 'outputsSimulation.gcr',
};

export const LV_CONFIG: { [key: string]: string } = {
  CONFIG_SBOX_L1: 'String Box',
  CONFIG_DCBUS: 'DC Bus System',
  CONFIG_SBOX_L2_FIELD: 'String Box L2 (Field)',
  CONFIG_SBOX_L2_PS: 'String Box L2 (Station)',
};

export const DESIGN_ACTIONS = {
  click: 'click',
  select: 'select',
  favorite: 'favorite',
  clone: 'clone',
  archive: 'archive',
  unarchive: 'unarchive',
  delete: 'delete',
  confirmDelete: 'confirmDelete',
  editNotes: 'editNotes',
  ilc: 'ilc',
  viewNotes: 'viewNotes',
};

// eslint-disable-next-line quotes
export const DESIGN_NAME_UNALLOWED_CHARS = ['[', '^', '+', '/', ':', '*', '?', '<', '>', '|', ']', '`', "'", '"'];

export const DESIGN_FOLDER_FILTERS: { [key: string]: IServerFilter[] } = {
  default: [
    {
      field: 'STATUS',
      operator: 'IN',
      values: ['FINISHED', 'REVIEWED', 'SAVED_USER', 'SAVED_SUPPORT'],
    },
  ],
  favorites: [
    {
      field: 'STATUS',
      operator: 'IN',
      values: ['FINISHED', 'REVIEWED', 'SAVED_USER', 'SAVED_SUPPORT'],
    },
  ],
  archived: [
    {
      field: 'STATUS',
      operator: 'IN',
      values: ['ARCHIVED'],
    },
  ],
};

export const DESIGN_TYPE_FILTERS: { [key: string]: IServerFilter[] } = {
  default: [
    {
      field: 'SIMULATION_TYPE',
      operator: 'IN',
      values: ['SINGLE', 'BATCH_SIMULATION', 'BATCH_CONTAINER'],
    },
  ],
  single: [
    {
      field: 'SIMULATION_TYPE',
      operator: 'EQUAL',
      values: ['SINGLE'],
    },
  ],
  batch: [
    {
      field: 'SIMULATION_TYPE',
      operator: 'IN',
      values: ['BATCH_SIMULATION', 'BATCH_CONTAINER'],
    },
  ],
};

export interface ILanguages {
  [key: string]: {
    url: string;
    filename: string;
  };
}
export interface IDocFormat {
  name: string;
  languages: ILanguages;
}

export interface IDoc {
  name: string;
  formats: IDocFormat[];
  new?: boolean;
}
export interface ICategory {
  category: string;
  documents: IDoc[];
}
