import styled from 'styled-components';

export const BottomToolbarWrapper = styled.div`
  position: fixed;
  bottom: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
`;

export const BottomToolbarStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.v2.spacing.xs};
  align-self: center;
  padding: ${({ theme }) => theme.v2.spacing.xs} ${({ theme }) => theme.v2.spacing.s};
  background-color: ${({ theme }) => theme.v2.bg.primary.default};
  box-shadow: ${({ theme }) => theme.v2.elevation.shadow.l};
  pointer-events: auto;
`;

export const Divider = styled.div`
  height: 16px;
  width: 1px;
  background-color: ${({ theme }) => theme.v2.stroke.primary.default};
  align-self: center;
`;