import styled from 'styled-components';

const BaseCell = styled.div<{ $textAlign?: string; $bgColor?: string }>`
  padding: ${({ theme }) => theme.v2.spacing.s};
  background-color: ${({ $bgColor }) => $bgColor ?? 'transparent'};
  text-align: ${({ $textAlign }) => $textAlign ?? 'center'};
  border-bottom: 1px solid ${({ theme }) => theme.v2.stroke.primary.default};
`;

export const HeaderCell = styled(BaseCell)<{ $hide: boolean }>`
  visibility: ${({ $hide }) => ($hide ? 'hidden' : 'visible')};
`;
