import React from 'react';
import { DesignGridItemErrorContainer, HeaderWrapper, ImageSpace } from './design-grid-item-error-card.styled';
import { useTranslation } from 'react-i18next';
import { IBessDesign, IDesign } from 'types/design';
import DesignGridItemHeader from '../header/design-grid-item-header';
import { Button, Typography } from '@ratedpower/components';
import { useTheme } from 'styled-components';
import { SUPPORT_EMAIL } from 'utils/constants/contact';
import DesignGridItemNote from 'pages/designs/grid/item/design-grid-item-note/design-grid-item-note';

interface IProps {
  item: IDesign | IBessDesign;
  selected: boolean;
  canEditDesign: boolean;
  canCloneDesign: boolean;
  onPerform: (action: string, item: IDesign | IBessDesign) => void;
  handleSelect: () => void;
  handleFavorite: () => void;
  handleClone: (e: any) => void;
  handleDelete: () => void;
  handleEditName: (newName: string) => void;
  handleEditNotes: (e: React.MouseEvent<Element, MouseEvent>) => void;
  handleViewNotes: (e: React.MouseEvent<Element, MouseEvent>) => void;
  showDetails?: boolean;
}

const DesignGridItemErrorCard: React.FC<IProps> = ({
  handleSelect,
  handleClone,
  handleDelete,
  handleEditName,
  item,
  canEditDesign,
  canCloneDesign,
  selected,
  handleEditNotes,
  handleViewNotes,
  handleFavorite,
  showDetails,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getErrorMessage = () => {
    switch (item.simulationResult) {
      case 'TASK_1_NOT_POSSIBLE_TO_FIT_PS':
        return t('errors:design.cant-fit-ps');
      case 'TASK_1_NO_COMBINATION_GROUP':
        return t('errors:design.cant-locate-ps-in-road');
      case 'TASK_3_NO_FEASIBLE_PS_LOCATIONS':
        return t('errors:design.no-feasible-ps-locations');
      default:
        return t('errors:design.generic');
    }
  };

  return (
    <DesignGridItemErrorContainer>
      <ImageSpace $showDetails={!!showDetails}>
        <Typography size="xl" color={theme.v2.error.text.contrast}>
          {t('design:oops-error')}
        </Typography>
        <Typography size="s" className="subtitle">
          {getErrorMessage()}
        </Typography>
        <Button
          variant="link"
          textUnderlined
          onClick={() => {
            window.open(`mailto:${SUPPORT_EMAIL}`, '_blank');
          }}
          text={t('design:get-help')}
        />
      </ImageSpace>
      <HeaderWrapper>
        <DesignGridItemHeader
          canEditDesign={canEditDesign}
          canCloneDesign={canCloneDesign}
          item={item}
          selected={selected}
          handleSelect={handleSelect}
          handleClone={handleClone}
          handleDelete={handleDelete}
          handleEditName={handleEditName}
          handleFavorite={handleFavorite}
        />
      </HeaderWrapper>
      <DesignGridItemNote
        item={item}
        canEditNotes={canEditDesign}
        handleEditNotes={handleEditNotes}
        handleViewNotes={handleViewNotes}
      />
    </DesignGridItemErrorContainer>
  );
};

export default DesignGridItemErrorCard;
