import React from 'react';
import * as S from './notifications-button.styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { BellSvg } from 'assets/icons';
import { paths } from 'components/bars/side-bar/sidebar-list-items';
import { TopbarMyAccountContainer } from 'components/bars/layout-topbar/topbar-my-account/topbar-my-account.styled';
import { SVGWrapper } from '@ratedpower/components';
import { useGetNotificationsCount } from 'utils/hooks/use-get-notifications-count';
import { useTheme } from 'styled-components';
import { IndexIds } from 'index.ids';

const NotificationsButton: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const notificationsCount = useGetNotificationsCount();
  const theme = useTheme();

  const handleLogoClick = () => {
    navigate(paths.notifications);
  };

  return (
    <TopbarMyAccountContainer $active={pathname.startsWith(paths.notifications)} onClick={handleLogoClick}>
      <SVGWrapper
        icon={BellSvg}
        size="l"
        color={theme.v2.icon.primary.default}
        dataTest={IndexIds.Communication.NotificationHeaderIcon.Container}
      />
      {notificationsCount > 0 && (
        <S.ParentContainer>
          <S.UnreadCounter data-testid={IndexIds.Communication.NotificationHeaderIcon.Dot} />
        </S.ParentContainer>
      )}
    </TopbarMyAccountContainer>
  );
};

export default NotificationsButton;
