import React, { useEffect, useState } from 'react';
import { SidebarButton, SideBarContainer } from './side-bar.styled';
import { useTranslation } from 'react-i18next';
import { isSmallDevice, useWindowSize } from 'utils/hooks/use-window-size';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { setSidebar } from 'redux/sidebar-slice';
import SidebarListItem from './sidebar-list-item';
import { useLocation, useNavigate } from 'react-router-dom';
import sidebarListItems from './sidebar-list-items';
import { CollapseSvg, ExpandSvg } from 'assets/icons';
import { IndexIds } from 'index.ids';
import { isDesignProcess as checkIsDesignProcess } from 'utils/navigation/navigation';

const SideBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { device } = useWindowSize();
  const dispatch = useDispatch();

  const {
    sidebar: { collapsed: sidebarCollapsed },
  } = useSelector((state: RootState) => state);

  const [pathActive, setPathActive] = useState('');

  useEffect(() => {
    setPathActive(location.pathname);
  }, [location.pathname]);

  const isPathActive = (actualPath: string) => {
    if (actualPath === '/') {
      return pathActive === actualPath;
    }
    return pathActive.includes(actualPath);
  };

  const isDesignProcess = checkIsDesignProcess(location.pathname);
  const isInteractiveLayoutCreator = location.pathname.includes('/interactive-layout-webgl');

  const hideSidebar = isDesignProcess || isInteractiveLayoutCreator;

  const handleClickItem = (path: string) => {
    navigate(path);
    if (isSmallDevice(device)) dispatch(setSidebar({ collapsed: true }));
  };

  return (
    <SideBarContainer
      $hideSidebar={hideSidebar}
      $collapsed={sidebarCollapsed}
      data-testid={IndexIds.Homepage.SideBarIds.Container}
    >
      {sidebarListItems.map((sidebarListItem, key) => (
        <SidebarListItem
          key={`sidebar-item${key}`}
          perform={sidebarListItem.perform}
          pathTo={sidebarListItem.pathTo}
          isPathActive={isPathActive}
          handleClickItem={handleClickItem}
          sidebarCollapsed={sidebarCollapsed}
          description={t(sidebarListItem.t)}
          Icon={sidebarListItem.Icon}
          hasSeparator={sidebarListItem.hasSeparator}
          isNew={sidebarListItem.isNew && !sidebarCollapsed}
          dataTest={sidebarListItem.dataTest}
        />
      ))}
      <SidebarButton
        variant="ghost"
        size="m"
        onClick={() => {
          dispatch(setSidebar({ collapsed: !sidebarCollapsed }));
        }}
        $collapsed={sidebarCollapsed}
        text={sidebarCollapsed ? undefined : t('common:collapse')}
        Icon={sidebarCollapsed ? ExpandSvg : CollapseSvg}
        dataTest={IndexIds.Homepage.SideBarIds.CollapseButton}
      />
    </SideBarContainer>
  );
};

export default SideBar;
