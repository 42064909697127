/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { IModalProps, Modal, RPTable } from '@ratedpower/components';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';
import { useListUserTeamsAssigned } from '../../hooks/use-list-user-teams-assigned';
import { ErrorWrapper } from '../../team-management/topbar-team-content/topbar-team-content.styled';
import { useTranslation } from 'react-i18next';

interface IProps extends IModalProps {
  userId: string;
}

const UserTeamsAssignedModal: React.FC<IProps> = (props) => {
  const { userId } = props;
  const { table, error } = useListUserTeamsAssigned(userId);
  const { t } = useTranslation();

  return (
    <Modal {...props}>
      {table && !error && <RPTable table={table as any} zeroResultsFoundText={t('common:zero-results-found')} />}
      {error && (
        <ErrorWrapper>
          <ErrorRetrievingData />
        </ErrorWrapper>
      )}
    </Modal>
  );
};

export default UserTeamsAssignedModal;
