import { useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { useDebouncedInput } from 'utils/hooks/use-debounced-input';
import { GET_TRANSLATED_COUNTRIES } from 'services/capex-gql';
import { ICountryTranslated } from 'types/company.types';
import { useSearch } from './use-search';
import { IOption } from '@ratedpower/components';
import { IndexIds } from 'index.ids';

type TranslatedCountry = {
  countryCode: string;
  id: number;
  translation: string;
};

export interface IUseTranslatedCountries {
  search?: string;
  onSearch: (search: string) => void;
  countries: IOption[];
  completeCountriesData?: TranslatedCountry[];
}

export const useTranslatedCountries = (
  restOfTheWorldFirst: boolean,
  value: 'id' | 'countryCode'
): IUseTranslatedCountries => {
  const { getSearchFilter, inputSearchProps } = useSearch();
  const appLanguage = useSelector((state: RootState) => state.currentUser.preferences.language);
  const { data, refetch } = useQuery(GET_TRANSLATED_COUNTRIES, {
    variables: { userLanguage: appLanguage.toUpperCase(), search: getSearchFilter() },
  });

  const { onChange: onSearch, value: search } = useDebouncedInput(300, inputSearchProps.onChange);

  // Temporary solution until we switch InputSearchSelector to a dropdown rp-component
  useEffect(() => {
    refetch();
  }, [inputSearchProps.value]);

  const countries = useMemo(() => {
    const countryList: IOption[] = data?.translatedCountriesList?.map((translatedCountry: ICountryTranslated) => ({
      label: translatedCountry.translation,
      value: translatedCountry[value],
      dataTest: IndexIds.Capex.CreateSheet.CountryOption,
    }));

    if (restOfTheWorldFirst) {
      const restOfTheWorld = countryList?.find((country) => country.value === 'ROW');
      if (restOfTheWorld) {
        return [restOfTheWorld, ...countryList.filter((country) => country.value !== 'ROW')];
      }
    }
    return countryList;
  }, [restOfTheWorldFirst, data]);

  return { countries, onSearch, search, completeCountriesData: data?.translatedCountriesList };
};
