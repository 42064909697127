import styled from 'styled-components';

export const MainGridStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content auto;
`;

export const ReadOnlyRolesWrapperStyled = styled.div`
  grid-row-gap: ${({ theme }) => theme.v2.spacing.l};
  display: grid;
`;

export const ReadOnlyRolesWContainerStyled = styled.div`
  max-height: calc(75vh);
  overflow-y: scroll;

  @media (max-height: 1500px) {
    max-height: calc(65vh);
  }
`;
