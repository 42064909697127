import React, { useMemo } from 'react';

import { PrimaryActionTypes, useDesignInputs, useModes, useRoads } from '../ilc-store';

import ReferencePoints from './reference-points';
import { calculateSelectedReferenceRectangleForRoads } from '../ilc-utils';

export const ReferenceForRoads = () => {
  const designInputs = useDesignInputs();
  const mode = useModes();
  const roads = useRoads();
  const selectedReferenceElement =
    mode.mode.type === PrimaryActionTypes.SELECT_REFERENCE_FOR_ROADS ? mode.mode.payload : null;

  const roadWidth = useMemo(() => {
    const roadValues = roads ? Object.values(roads) : [];
    if (!selectedReferenceElement) return null;
    const areaRoad = roadValues.find((item) => item.areaKey === selectedReferenceElement.areaKey);
    if (!areaRoad) return null;
    return areaRoad.roadWidth;
  }, [roads, selectedReferenceElement]);

  const selectedReferenceRectangle = calculateSelectedReferenceRectangleForRoads(
    selectedReferenceElement,
    designInputs,
    roadWidth
  );

  const showReferencePoints = selectedReferenceElement && roadWidth && selectedReferenceRectangle;

  return showReferencePoints ? (
    <ReferencePoints
      color="purple"
      showReferencePoints={true}
      showMidPoints={true}
      selectedReferencePoint={null}
      onClick={() => void 0}
      center={selectedReferenceElement.centroid}
      userCoords={selectedReferenceRectangle}
    />
  ) : null;
};
