import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import {
  CREATE_USER_BY_SUPERUSER,
  DELETE_USER,
  GET_JOB_TITLES,
  GET_USERS,
  UPDATE_TEMPORARY_PASSWORD,
  UPDATE_USER_BY_SUPERUSER,
} from 'services/user-gql';
import { IUserForm, IUserListItem, IUseUsersObject } from 'types/users';
import { ALL_TEAMS_OPTION, NEW_USER_ROW, USER_ROLES_ACTIONS } from 'utils/constants/users';
import { IServerFilter, ISort } from 'types/filter';
import { SortableHeader } from '@ratedpower/components';
import { useGetUserTableColumns } from '../../table-columns/users-table-columns';
import { Permission } from 'access-control/permissions';
import { useAuthorization } from 'access-control/can';
import { useModal } from 'utils/hooks/use-modal';
import { IRole } from 'types/role';
import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { useSearch } from 'utils/hooks/use-search';
import { useToasts } from 'utils/hooks/use-toasts';
import { getSortDirection } from 'utils/sort';
import { USERS_PER_PAGE as entriesPerPage } from 'utils/constants/pagination';
import { useTranslation } from 'react-i18next';
import { useTeamManagersTeamsMembers } from './use-team-managers-team-members';
import { GET_TEAM_MANAGER_TEAMS_MEMBERS } from 'services/team-gql';
import { useRolesByCurrentUser } from 'utils/hooks/use-roles-by-current-user';
import { useUserRoleSummary } from 'utils/hooks/use-get-role-summary';
import { useListTeams } from 'pages/teams/hooks/use-list-teams';
import { GET_USER_ROLE_SUMMARY } from 'services/roles-gql';
import { useEditableRPTable } from 'utils/hooks/use-editable-rptable';
import { IndexIds } from 'index.ids';

export const useUsers = (): IUseUsersObject => {
  const { t } = useTranslation();
  const deleteUserModal = useModal();
  const updatePasswordConfirmationModal = useModal();
  const userTeamsAssignedModal = useModal();
  const userToDelete = useRef<{ id?: string; email?: string } | undefined>();
  const selectedRoleHierarchy = useRef<number | undefined>();

  const { isAuthorized } = useAuthorization();
  const hasRoleChangePermissions = isAuthorized(Permission.ASSIGN_SUPPORT_ROLE_WRITE);
  const hasRoleReadPermissions = isAuthorized(Permission.ROLE_SUPPORT_READ);
  const hasDeletePermissions = isAuthorized(Permission.USER_SUPPORT_DELETE);
  const isExternalAdmin = isAuthorized(Permission.USER_WRITE_IN_USER_COMPANY);
  const { subscriptionPlan, profile } = useSelector((state: RootState) => state.currentUser);

  const [filters, setFilters] = useState<IServerFilter[]>([]);
  const [users, setUsers] = useState<IUserListItem[]>([]);
  const [teamSelected, setTeamSelected] = useState<string | null>(ALL_TEAMS_OPTION(t).value);
  const [error, setError] = useState(false);

  const { userRoleSummary, userSummaryLoading } = useUserRoleSummary(filters, teamSelected);

  const { rolesByCurrentUser } = useRolesByCurrentUser();
  const { data: jobTitles } = useQuery(GET_JOB_TITLES);
  const { data: myTeamMembers } = useTeamManagersTeamsMembers();
  const { teams, loading: teamsLoading } = useListTeams({ infiniteScroll: false, pageSize: 30 });

  const { getSearchFilter, inputSearchProps } = useSearch();
  const { addErrorToast, addSuccessToast } = useToasts();

  const [sortedBy, setSortedBy] = useState<ISort>({
    selector: {
      label: t('name'),
      code: 'name',
    },
    ascendent: true,
  });

  const {
    loading,
    data: fetchedData,
    currentPage,
    paginationComponent,
  } = useCustomQuery(GET_USERS, {
    variables: {
      pagination: {
        page: 0,
        size: entriesPerPage,
      },
      sorting: {
        property: sortedBy.selector.code,
        direction: getSortDirection(sortedBy.ascendent),
      },
      search: getSearchFilter(),
      filters,
      teamId: teamSelected,
    },
    fetchPolicy: 'network-only',
  });

  const [createUser, { loading: loadingCreate }] = useMutation(CREATE_USER_BY_SUPERUSER, {
    onCompleted: () => {
      addSuccessToast(t('user:user-created-successfully'), 'bottom-center', IndexIds.Users.SuccessToast);
    },
    refetchQueries: [GET_USERS, GET_TEAM_MANAGER_TEAMS_MEMBERS, GET_USER_ROLE_SUMMARY],
    onError: (graphqlError) => {
      const debugInfo: any = graphqlError?.graphQLErrors[0]?.extensions?.debugInfo;
      const errorMessage: string | null = debugInfo?.messageError ?? null;
      const invalidOrGenericError =
        errorMessage && errorMessage === 'INVALID_EMAIL' ? t('user:error-duplicated-email') : t('errors:GENERIC');
      addErrorToast(
        errorMessage && errorMessage === 'PLAN_NO_USERS_AVAILABLE'
          ? t('company:subscription-plans.PLAN_NO_USERS_AVAILABLE')
          : invalidOrGenericError,
        'bottom-center',
        IndexIds.Users.ErrorToast
      );
    },
  });

  const [updateUser, { loading: loadingUpdate }] = useMutation(UPDATE_USER_BY_SUPERUSER, {
    refetchQueries: [GET_USERS, GET_USER_ROLE_SUMMARY],
    onCompleted: () => {
      addSuccessToast(t('user:user-updated-successfully'), 'bottom-center', IndexIds.Users.SuccessToast);
    },
  });
  const [deleteUser] = useMutation(DELETE_USER, {
    refetchQueries: [GET_USERS, GET_USER_ROLE_SUMMARY],
    onCompleted: () => {
      addSuccessToast(t('user:user-deleted-successfully'), 'bottom-center', IndexIds.Users.SuccessToast);
    },
  });

  const [updateTemporaryPassword] = useMutation(UPDATE_TEMPORARY_PASSWORD, {
    onCompleted: () => {
      addSuccessToast(t('user:send-password-success'), 'bottom-center', IndexIds.Users.SuccessToast);
    },
  });

  useEffect(() => {
    if (!loading && fetchedData) {
      if (fetchedData && fetchedData.result?.content && fetchedData.result?.pageInfo) {
        const { content: fetchedUsers } = fetchedData.result;
        setUsers(fetchedUsers);
      }
    }
  }, [fetchedData, currentPage, loading]);

  const hasWritePermission: boolean = useMemo(() => {
    return (
      !!subscriptionPlan &&
      !subscriptionPlan.subscriptionPlanExpired &&
      (isAuthorized(Permission.USER_SUPPORT_WRITE) ||
        isAuthorized(Permission.USER_WRITE_IN_USER_COMPANY) ||
        isAuthorized(Permission.USER_WRITE_IN_USER_TEAM))
    );
  }, [subscriptionPlan, users]);

  const sortableHeader = (label: string, code: string) => (
    <SortableHeader
      {...{ sortedBy, onSort: setSortedBy }}
      selector={{
        label: t(`${label}`),
        code,
      }}
    />
  );

  const handlePerform = (action: string, id?: string, data?: any) => {
    const { save, deleteRow, resendPassword, checkTeamsAssigned } = USER_ROLES_ACTIONS;

    switch (action) {
      case checkTeamsAssigned: {
        userTeamsAssignedModal.setData(id);
        userTeamsAssignedModal.setModalOpen(true);
        break;
      }
      case save: {
        handleSave(id);
        break;
      }
      case deleteRow: {
        userToDelete.current = {
          id,
          email: users.find((u) => u.id === id)?.email,
        };
        deleteUserModal.setModalOpen(true);
        break;
      }
      case resendPassword: {
        updatePasswordConfirmationModal.setData(data?.email);
        updatePasswordConfirmationModal.setModalOpen(true);
      }
    }
  };

  const handleSave = (id?: string) => {
    if (!id) {
      const { name, lastname, email, jobTitle, role: roleSelected } = users[0];
      if (!email) {
        setError(true);
        return;
      }
      createUser({
        variables: {
          userForm: {
            name,
            surname: lastname,
            email,
            jobTitle,
            roleId: roleSelected?.id,
            teamId: teamSelected,
          },
        },
      });
      setError(false);
      return;
    }
    const userEdited = users.find((user) => user.id === id);
    if (!userEdited) return;
    const { status, role } = userEdited;
    editUser({
      id,
      email: userEdited.email,
      name: userEdited.name,
      lastname: userEdited.lastname,
      jobTitle: userEdited.jobTitle,
      status,
      roleId: role?.id,
    });
  };

  const handleNewUserAction = () => {
    if (users.length && !users[0].id) return;
    const newUserRow = createNewUserRow(filters);
    table.options.meta?.addRow(newUserRow);
  };

  const createNewUserRow = (f: IServerFilter[]): IUserListItem => {
    const roleFiltered = rolesByCurrentUser.find(
      (role: IRole) => role.id === f.find(({ field }) => field === 'ROLE')?.values[0]
    );
    const jobTitleFiltered = f.find(({ field }) => field === 'JOB_TITLE')?.values[0];

    return {
      ...NEW_USER_ROW,
      ...(jobTitleFiltered && { jobTitle: jobTitleFiltered }),
      ...(roleFiltered
        ? { role: roleFiltered }
        : { role: { id: rolesByCurrentUser[0].id, name: rolesByCurrentUser[0].name } }),
    };
  };

  const editUser = (form: IUserForm) => {
    const { id, name, lastname: surname, jobTitle, status, roleId } = form;
    const userForm = { id, name, surname, jobTitle, status, roleId };
    updateUser({ variables: { userForm } });
  };

  const handleDeleteUser = () => {
    deleteUser({ variables: { id: userToDelete.current?.id } });
  };

  const handleSendPassword = (email: string) => {
    updateTemporaryPassword({ variables: { email } });
  };

  const columns = useGetUserTableColumns(
    {
      sortableHeader,
      handlePerform,
      rolesByCurrentUser,
      jobTitles: jobTitles?.result,
      userHierarchyLevel: profile?.roleHierarchy || 0,
      hasWritePermission,
      isExternalAdmin,
      error,
      loadingCreate: loadingCreate || loadingUpdate,
      myTeamMembers,
      hasRoleReadPermissions,
      hasRoleChangePermissions,
      hasDeletePermissions,
    },
    // goToRoles, TODO after design system: recover footer navigate to roles link
    !!teams?.length
  );

  const table = useEditableRPTable(users, columns, setUsers);

  return {
    inputSearchProps,
    table,
    users,
    teams,
    loading: loading || teamsLoading,
    paginationComponent,
    userToDelete: userToDelete.current,
    rolesByCurrentUser,
    hasWritePermission,
    userRoleSummary,
    userSummaryLoading,
    filters,
    deleteUserModal,
    updatePasswordConfirmationModal,
    userTeamsAssignedModal,
    selectedRoleHierarchy,
    handlePerform,
    handleDeleteUser,
    handleSendPassword,
    setFilters,
    setTeamSelected,
    handleNewUserAction,
  };
};
