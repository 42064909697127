import * as THREE from 'three';
import { useThree } from '@react-three/fiber';

export const getWorldCoords = (event, camera, size, plane = 'XY') => {
  // Get x,y coords where click occurred
  const x = event.clientX;
  const y = event.clientY;

  // Convert to normalized device coordinates (-1 to +1)
  const mouse = new THREE.Vector2();
  mouse.x = (x / size.width) * 2 - 1;
  mouse.y = -(y / size.height) * 2 + 1; // Note: Y is flipped

  // Setup the raycaster
  const raycaster = new THREE.Raycaster();
  if (camera.isPerspectiveCamera) {
    raycaster.near = camera.near;
    raycaster.far = camera.far;
  }
  raycaster.setFromCamera(mouse, camera);

  // Create a plane to intersect with
  const planeNormal = plane === 'XY' ? new THREE.Vector3(0, 0, 1) : new THREE.Vector3(0, 1, 0);
  const planeConstant = 0; // Distance from origin
  const intersectionPlane = new THREE.Plane(planeNormal, planeConstant);

  // Calculate the intersection point
  const intersectionPoint = new THREE.Vector3();
  raycaster.ray.intersectPlane(intersectionPlane, intersectionPoint);

  return { x: intersectionPoint.x, y: -intersectionPoint.z };
};

export const useWorldCoords = () => {
  const { camera, size } = useThree();

  return (event) => getWorldCoords(event, camera, size, 'XZ');
};
