import { fetchLayoutData } from 'pages/design-process/services/map-service';
import { LayoutResponse } from '../ilc-types';

export const fetchLayout = async (simulationId: string, getRest: any): Promise<LayoutResponse | null> => {
  const layoutUrl = `/interactive-layout/layoutIlc/${simulationId}`;

  const response = await fetchLayoutData(layoutUrl, getRest);
  if (response && response.elements && response.setbacks && response.bbox && response.layersEditable) {
    return {
      layers: response.elements,
      editable: response.layersEditable,
      offset: { x: 0, y: 0 },
      bboxAndOffset: response.bbox,
      setbacks: response.setbacks,
      bbox: {
        min: {
          x: 0,
          y: 0,
        },
        max: {
          x: response.bbox.northeast.x,
          y: response.bbox.northeast.y,
        },
      },
      utmZone: response.utmZone,
    };
  } else {
    return null;
  }
};
