import React from 'react';
import { Button, InputText, LabelWrapper } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { CrossSvg } from 'assets/icons/index';
import {
  DefaultLinkItemNotification,
  ILinkItemNotification,
  ILinkItemNotificationRecord,
} from 'pages/communication/create-notification/link-items-notification/types/link-items-notification';
import * as Styled from 'pages/communication/create-notification/link-items-notification/link-items-notification.styled';
import { ILinkItemNotificationCollection } from 'pages/communication/utils/types/create-notification-form.types';
import { IndexIds } from 'index.ids';

interface IProps {
  handleChange: (value: ILinkItemNotificationRecord) => void;
  knowledgeBaseArticles: ILinkItemNotificationCollection;
}

const LinkItemsNotification: React.FC<IProps> = ({ handleChange, knowledgeBaseArticles }: IProps) => {
  const { t } = useTranslation();
  const { value: linkItems, urlValidator, knowledgeBaseArticlesTitleValidator: titleValidator } = knowledgeBaseArticles;

  const handleItemChange = (key: string, updatedItem: ILinkItemNotification) => {
    const updatedLinkItems = {
      ...linkItems,
      [key]: updatedItem,
    };

    handleChange(updatedLinkItems);
  };

  const addNewItem = (): void => {
    const keys = Object.keys(linkItems);
    const lastKey = keys.length > 0 ? keys[keys.length - 1] : '0';
    const newKey = (parseInt(lastKey) + 1).toString();

    handleItemChange(newKey, DefaultLinkItemNotification);
  };

  const removeItem = (key: string): void => {
    if (Object.keys(linkItems).length <= 0) return;

    const updatedLinkItems = { ...linkItems };
    delete updatedLinkItems[key];

    handleChange(updatedLinkItems);
  };

  return (
    <>
      <Styled.ButtonWrapper>
        <Button
          variant="ghost"
          text={t('communication:add-link-item')}
          onClick={addNewItem}
          dataTest={IndexIds.Communication.CreateNotificationModal.AddLinkItemButton}
        />
      </Styled.ButtonWrapper>
      <Styled.LinkItemsNotificationWrapper>
        {Object.keys(linkItems).map((key: string, position: number) => {
          const linkItem: ILinkItemNotification = linkItems[key];
          const index = position + 1;

          return (
            <div key={key} data-testid={IndexIds.Communication.CreateNotificationModal.LinkContainer}>
              <LabelWrapper size="l" title={t('communication:link-title', { index })}>
                <InputText
                  name={key}
                  value={linkItem.link}
                  onChange={(value: string) => handleItemChange(key, { ...linkItem, link: value })}
                  size="l"
                  warning={!urlValidator(linkItem.link)}
                  maxLength={knowledgeBaseArticles.maxLength}
                  dataTest={IndexIds.Communication.CreateNotificationModal.LinkUrlInput}
                />
              </LabelWrapper>
              <Styled.ListItemsTitleWrapper>
                <LabelWrapper size="l" title={t('communication:link-title-label', { index })}>
                  <InputText
                    name={key}
                    value={linkItem.title}
                    onChange={(value: string) => handleItemChange(key, { ...linkItem, title: value })}
                    size="l"
                    warning={!titleValidator(linkItem.title)}
                    maxLength={knowledgeBaseArticles.maxLength}
                    dataTest={IndexIds.Communication.CreateNotificationModal.LinkTitleInput}
                  />
                </LabelWrapper>
                <Button
                  Icon={CrossSvg}
                  size="medium"
                  variant="ghost"
                  onClick={() => removeItem(key)}
                  dataTest={IndexIds.Communication.CreateNotificationModal.LinkItemDelete}
                />
              </Styled.ListItemsTitleWrapper>
            </div>
          );
        })}
      </Styled.LinkItemsNotificationWrapper>
    </>
  );
};

export default LinkItemsNotification;
