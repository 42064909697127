import * as S from './error-code-group.styled';
import { Checkbox, StatusSVGWrapper, Typography } from '@ratedpower/components';
import { ChevronDownSvg, ChevronUpSvg, TargetSvg } from 'assets/icons';
import { Collapse } from 'react-collapse';
import { ErrorItem } from '../error-item';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IlcValidationError } from '../../../ilc-types';

interface IErrorCodeGroup {
  code: IlcValidationError['code'];
  expandedCodes: string[];
  type: IlcValidationError['type'];
  errorIds: string[];
  selectedTab: string;
  count: number;
  errors: IlcValidationError[];
  selectedRectanglesIds: string[];
  handleToggleExpand: (code: IlcValidationError['code']) => void;
  handleCheckboxChange: (ids: string[], type: IlcValidationError['type']) => void;
  checkAreAllErrorsSelected: (ids: string[]) => boolean;
  handleClickGroupTarget: (e: React.MouseEvent, errors: IlcValidationError[]) => void;
  handleClickItemTarget: (e: React.MouseEvent, error: IlcValidationError) => void;
}

export const ErrorCodeGroup = ({
  code,
  expandedCodes,
  type,
  errorIds,
  selectedTab,
  count,
  errors,
  selectedRectanglesIds,
  handleToggleExpand,
  handleCheckboxChange,
  checkAreAllErrorsSelected,
  handleClickGroupTarget,
  handleClickItemTarget,
}: IErrorCodeGroup) => {
  const { t } = useTranslation('ilc');

  return (
    <S.ErrorListItem $active={expandedCodes.includes(code)} key={code}>
      <S.ErrorListItemHeader>
        <StatusSVGWrapper
          icon={expandedCodes.includes(code) ? ChevronUpSvg : ChevronDownSvg}
          onClick={() => handleToggleExpand(code)}
          size="s"
          active={false}
        />
        <S.CheckboxWrapper
          key={code}
          onClick={() => {
            handleCheckboxChange(errorIds, type);
          }}
        >
          {type !== 'ROADS' && <Checkbox checked={checkAreAllErrorsSelected(errorIds)} onChange={() => void 0} />}
          <Typography size="s">{t(`validation-errors.${selectedTab}-${type}-${code}`, { count })}</Typography>
          {type !== 'ROADS' && (
            <StatusSVGWrapper
              icon={TargetSvg}
              onClick={(e) => handleClickGroupTarget(e, errors)}
              size="s"
              active={false}
            />
          )}
        </S.CheckboxWrapper>
      </S.ErrorListItemHeader>
      <Collapse isOpened={expandedCodes.includes(code)}>
        <S.ErrorItems>
          {errors.map((error, index) => (
            <ErrorItem
              error={error}
              index={index}
              key={`${error.id}-${error.type}`}
              type={type}
              selectedRectanglesIds={selectedRectanglesIds}
              handleCheckboxChange={handleCheckboxChange}
              handleClickItemTarget={handleClickItemTarget}
            />
          ))}
        </S.ErrorItems>
      </Collapse>
    </S.ErrorListItem>
  );
};
