import { Point } from '../ilc-types';

interface Line {
  point1: Point;
  point2: Point;
}

function calculateSlopeAndYIntercept(point1: Point, point2: Point): { slope: number; yIntercept: number } {
  const slope = (point2.y - point1.y) / (point2.x - point1.x);
  const yIntercept = point1.y - slope * point1.x;
  return { slope, yIntercept };
}

export const calculateSlopeAndYInterceptOfPointAndAngle = (point: Point, angle: number) => {
  const slope = Math.tan(angle);
  const yIntercept = point.y - slope * point.x;
  if (slope > 10000000 || slope < -10000000) {
    return {
      slope: Infinity,
      yIntercept: point.x,
    };
  } else {
    return { slope, yIntercept };
  }
};

export function calculateIntersectionBetweenProjectedPointAndLine(
  point: Point,
  angle: number,
  line2: Line
): Point | null {
  const { slope: slope1, yIntercept: yIntercept1 } = calculateSlopeAndYInterceptOfPointAndAngle(point, angle);
  const { slope: slope2, yIntercept: yIntercept2 } = calculateSlopeAndYIntercept(line2.point1, line2.point2);

  // Check if slopes are equal (parallel lines)
  if (slope1 === slope2) {
    return null; // No intersection
  }

  let x;
  let y;

  if (isFinite(slope1) && isFinite(slope2)) {
    // Calculate intersection point for lines with finite slopes
    x = (yIntercept2 - yIntercept1) / (slope1 - slope2);
    y = slope1 * x + yIntercept1;
  } else if (!isFinite(slope1)) {
    // Handle line1 with infinite slope
    x = point.x;
    y = slope2 * x + yIntercept2;
  } else {
    // Handle line2 with infinite slope
    x = line2.point1.x;
    y = slope1 * x + yIntercept1;
  }

  return { x, y };
}

export function calculateIntersectionBetweenProjectedPoints(
  point: Point,
  angle: number,
  point2: Point,
  angle2: number
): Point | null {
  const { slope: slope1, yIntercept: yIntercept1 } = calculateSlopeAndYInterceptOfPointAndAngle(point, angle);
  const { slope: slope2, yIntercept: yIntercept2 } = calculateSlopeAndYInterceptOfPointAndAngle(point2, angle2);

  // Check if slopes are equal (parallel lines)
  if (slope1 === slope2) {
    return null; // No intersection
  }

  let x;
  let y;

  if (isFinite(slope1) && isFinite(slope2)) {
    // Calculate intersection point for lines with finite slopes
    x = (yIntercept2 - yIntercept1) / (slope1 - slope2);
    y = slope1 * x + yIntercept1;
  } else if (!isFinite(slope1)) {
    // Handle line1 with infinite slope
    x = point.x;
    y = slope2 * x + yIntercept2;
  } else {
    // Handle line2 with infinite slope
    x = point2.x;
    y = slope1 * x + yIntercept1;
  }

  return { x, y };
}
