import Can from 'access-control/can';
import { Permission } from 'access-control/permissions';
import CompanyInfoWithLink from 'components/company-info-with-link/company-info-with-link';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  Breadcrumb,
  TopbarWrapper,
  IIconButtonOption,
  StatusSVGWrapper,
  DropdownButton,
  IPopupIconButtonOption,
  IOption,
} from '@ratedpower/components';
import { iconOptions } from 'utils/constants/test-utils/design-constants';
import * as S from './designs-topbar.styled';
import { useNavigate } from 'react-router-dom';
import { IndexIds } from 'index.ids';
import { useTheme } from 'styled-components';

interface IProps {
  title: string;
  selectedView: string;
  startDesignProcess: () => void;
  startStandaloneBess: () => void;
  changeView: (option: IIconButtonOption) => void;
  projectId: string;
  loading?: boolean;
}

enum DesignValue {
  PVDesign = 'start-design',
  BESSDesign = 'start-standalone-bess',
}

const DesignsTopbar: React.FC<IProps> = ({
  title = '',
  selectedView,
  startDesignProcess,
  startStandaloneBess,
  changeView,
  projectId,
  loading,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const prevPage = {
    name: t('project:projects'),
    url: '/projects',
    onClick: (url) => url && navigate(url),
  };

  const options: IOption[] = [
    { label: t('project:pv-design'), value: DesignValue.PVDesign, dataTest: IndexIds.Designs.StartPvDesignButton },
    { label: t('project:standalone-bess'), value: DesignValue.BESSDesign },
  ];

  const onClickOption = (option: IPopupIconButtonOption) => {
    option.value === DesignValue.PVDesign && startDesignProcess();
    option.value === DesignValue.BESSDesign && startStandaloneBess();
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <TopbarWrapper
        type="main"
        left={
          <S.LeftTopbarContainer>
            <Breadcrumb prevPages={[prevPage]} currentPage={{ name: title }} loading={loading} />
            <Can perform={Permission.COMPANY_SUPPORT_READ}>
              <CompanyInfoWithLink projectId={projectId} />
            </Can>
          </S.LeftTopbarContainer>
        }
        right={
          <S.Container>
            {iconOptions.map((option) => (
              <StatusSVGWrapper
                icon={option.Icon}
                key={option.value}
                size="l"
                active={selectedView === option.value}
                onClick={() => changeView(option)}
              />
            ))}
            <Can
              perform={[
                Permission.SIMULATION_SUPPORT_CREATE,
                Permission.SIMULATION_CREATE_IN_USER_COMPANY,
                Permission.SIMULATION_CREATE_IN_USER_TEAM_AND_PUBLIC,
              ]}
            >
              <DropdownButton
                text={t('project:new-design')}
                iconColor={theme.primary}
                popupPosition="fullWidth"
                onClickOption={(option) => onClickOption(option)}
                dataTest={IndexIds.Designs.StartDesignButton}
                options={options}
              />
            </Can>
          </S.Container>
        }
      />
    </>
  );
};

export default DesignsTopbar;
