/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import * as THREE from 'three';
import React, { createContext, forwardRef, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useGesture } from '@use-gesture/react';
import FatLine from './fat-line';
import {
  PrimaryActionTypes,
  useAddEdgeBetweenNodes,
  useAddLineBetweenNodesMode,
  useAddNodeBetweenTwoNodes,
  useAddNodeToOtherNodeMode,
  useDesignInputs,
  useModes,
  usePointerType,
  useResetMode,
  useRoads,
  useUpdateWithPreviousRoads,
} from '../ilc-store';
import { useWorldCoords } from '../hooks/use-world-coords';

import {
  calculateSelectedReferenceRectangleForRoads,
  getMidpoints,
  movePointAlongLineUsingDirectionVector,
} from '../ilc-utils';
import { Point } from '../ilc-types';

function distance(point1: Point, point2: Point): number {
  const dx = point2.x - point1.x;
  const dy = point2.y - point1.y;
  return Math.sqrt(dx * dx + dy * dy);
}

const calculateDirectionVector = (point1: Point, point2: Point) => {
  return { x: point2.x - point1.x, y: point2.y - point1.y };
};

function moveNodeAlongLine(
  mousePosition: Point,
  initialNodePosition: Point,
  connectionNodes: Point[]
): Point | undefined {
  let closestPoint: Point | null = null;
  let closestDistance = Infinity;
  for (const connectionNode of connectionNodes) {
    // const closest = closestPointOnLine(mousePosition, initialNodePosition, connectionNode);
    const dirVector = calculateDirectionVector(initialNodePosition, connectionNode);
    // const closest = closestPointOnDirectionVector(mousePosition, dirVector);
    const closest = movePointAlongLineUsingDirectionVector(initialNodePosition, dirVector, mousePosition);
    const dist = distance(mousePosition, closest);
    if (dist < closestDistance) {
      closestDistance = dist;
      closestPoint = closest;
    }
  }

  if (closestPoint) {
    // Move the node to the closest point
    return closestPoint;
  }
}

const context = createContext<any>(null);
export const Circle: any = forwardRef<any, any>(
  ({ children, opacity = 1, radius = 0.05, segments = 32, color = '#ff1050', ...props }, ref) => (
    <mesh rotation={[-Math.PI / 2, 0, 0]} ref={ref} {...props}>
      <circleGeometry args={[radius, segments]} />
      <meshBasicMaterial transparent={opacity < 1} opacity={opacity} color={color} />
      {children}
    </mesh>
  )
);

export function Nodes({ children, roadId }: { children: any; roadId: string }) {
  const [nodes, set] = useState<any>([]);

  const lines = useMemo(() => {
    const lines: {
      start: { x: number; y: number; z: number };
      end: { x: number; y: number; z: number };
      userId: string;
      color: string;
      node1: any;
      node2: any;
      midPoint: Point;
      width: number;
    }[] = [];
    for (const node of nodes) {
      for (const connectedNode of node.connectedTo) {
        const connectedNodePosition = nodes.find((n: any) => n.userId === connectedNode)?.position;

        if (connectedNodePosition) {
          lines.push({
            start: { x: node.position.x, z: node.position.z, y: 4.5 },
            end: { x: connectedNodePosition.x, z: connectedNodePosition.z, y: 4.5 },
            userId: `${node.userId}-${connectedNode}`,
            node1: node.userId,
            node2: connectedNode,
            color: 'black',
            midPoint: {
              x: (node.position.x + connectedNodePosition.x) / 2,
              y: (node.position.z + connectedNodePosition.z) / 2,
            },
            width: node.roadWidth,
          });
        }
      }
    }

    return lines;
  }, [nodes]);

  const addNodeBetweenTwoNodes = useAddNodeBetweenTwoNodes();

  return (
    <context.Provider value={set}>
      {lines.map((line: any) => (
        <FatLine
          key={line.userId}
          color={'black'}
          coordinates={[line.start, line.end]}
          width={line.width}
          userData={{ node1: line.node1, node2: line.node2 }}
        />
      ))}
      {lines.map((line: any) => (
        <Circle
          key={`${line.userId}-midpoint`}
          radius={2}
          position={[line.midPoint.x, 5, line.midPoint.y]}
          onPointerDown={() => {
            addNodeBetweenTwoNodes({ x: line.midPoint.x, y: -line.midPoint.y }, roadId, line.node1, line.node2);
          }}
          color={'yellow'}
        ></Circle>
      ))}
      {children}
    </context.Provider>
  );
}

interface INodesThreeJs {
  key: string;
  userId: string;
  name: string;
  nodesGraph: {
    [nodeId: string]: Point;
  };
  roadId: string;
  position: [number, number, number];
  color: string;
  connectedToBidirectional: string[];
  connectedTo: string[];
  onUpdate: (nodeId: string, coordinate: Point) => void;
  isSelectingInitialNode: boolean;
  onDeleteNodeAndEdges: (nodeId: string, roadId: string) => void;
  roadWidth: number;
  isDeletingNode: boolean;
  isAddingEdgeBetweenNodes: boolean;
}

export const NodeThreeJS: React.FC<INodesThreeJs> = ({
  key,
  name,
  userId,
  nodesGraph,
  color = 'black',
  connectedToBidirectional = [],
  connectedTo = [],
  position = [0, 0, 0],
  onUpdate,
  roadId,
  onDeleteNodeAndEdges,
  isDeletingNode,
  isAddingEdgeBetweenNodes,
  isSelectingInitialNode,
  roadWidth,
}) => {
  const set = useContext(context);
  const action = useModes();
  const addEdgeBetweenNodes = useAddEdgeBetweenNodes();
  const addLineBetweenNodesMode = useAddLineBetweenNodesMode();
  const resetMode = useResetMode();
  const pointerType = usePointerType();
  const selectedReferenceElement =
    action.mode.type === PrimaryActionTypes.SELECT_REFERENCE_FOR_ROADS && action.mode.payload
      ? action.mode.payload
      : null;
  const designInputs = useDesignInputs();
  const selectedReferenceRectangle = calculateSelectedReferenceRectangleForRoads(
    selectedReferenceElement,
    designInputs,
    roadWidth
  );
  const addNodeToOtherNodeMode = useAddNodeToOtherNodeMode();
  const selectedInitialNodeForAdding =
    action.mode.type === PrimaryActionTypes.ADD_NODE_TO_OTHER_NODE ? action.mode.payload?.connectedNode.nodeId : null;
  const selectedInitialNodeForConnecting =
    action.mode.type === PrimaryActionTypes.ADD_EDGE_BETWEEN_NODES ? action.mode.payload?.nodeA : null;
  const highlightSelectedInitialNode =
    selectedInitialNodeForAdding === userId || selectedInitialNodeForConnecting === userId;

  const midpoints = selectedReferenceRectangle ? getMidpoints(selectedReferenceRectangle) : null;

  const fullRectangleWithMidPoints =
    selectedReferenceRectangle && midpoints ? [...selectedReferenceRectangle, ...midpoints] : null;

  const [initialPosition, setInitialPosition] = useState(position);

  const pos = new THREE.Vector3(...position);
  const state = useMemo(
    () => ({ position: pos, userId, connectedTo, roadId, roadWidth }),
    [pos, userId, connectedTo, roadWidth, roadId]
  );

  // Register this node on mount, unregister on unmount
  useLayoutEffect(() => {
    set((nodes: any) => [...nodes, state]);
    return () => set((nodes: any) => nodes.filter((n: any) => n !== state));
  }, [state, pos]);

  // Drag n drop, hover
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    document.body.style.cursor = hovered ? 'grab' : 'auto';
  }, [hovered]);

  const roads = useRoads();

  const updateWithPreviousRoads = useUpdateWithPreviousRoads();

  const getWorldCoords = useWorldCoords();

  const [initialRoads, setInitialRoads] = useState<any>(null);
  const bind: any = useGesture({
    onDragStart: () => {
      if (isSelectingInitialNode) return;
      if (isAddingEdgeBetweenNodes) return;
      if (isDeletingNode) return;
      if (roads) setInitialRoads(roads);
    },
    onDrag: ({ down, xy: [x, y], ctrlKey, metaKey }) => {
      if (isSelectingInitialNode || isAddingEdgeBetweenNodes || isDeletingNode || pointerType === 'MOVE') return;
      document.body.style.cursor = down ? 'grabbing' : 'grab';

      const newPosition = getWorldCoords({ clientX: x, clientY: y });

      if (fullRectangleWithMidPoints) {
        const distanceToRespect = 10;
        let closestDistance = Infinity;
        let closestPoint: Point | null = null;
        for (let i = 0; i < fullRectangleWithMidPoints.length; i++) {
          const dist = distance(newPosition, fullRectangleWithMidPoints[i]);
          if (dist < closestDistance) {
            closestDistance = dist;
            closestPoint = fullRectangleWithMidPoints[i];
          }
        }
        if (closestDistance < distanceToRespect && closestPoint) {
          onUpdate(userId, { x: closestPoint.x, y: closestPoint.y });
          return;
        }
      }

      if (ctrlKey || metaKey) {
        const newPositionAlongLine = moveNodeAlongLine(
          { x: newPosition.x, y: newPosition.y },
          { x: initialPosition[0], y: -initialPosition[2] },
          connectedToBidirectional.map((connectedNode: any) => ({
            x: nodesGraph[connectedNode].x,
            y: nodesGraph[connectedNode].y,
          }))
        );

        if (newPositionAlongLine) onUpdate(userId, { x: newPositionAlongLine.x, y: newPositionAlongLine.y });
      } else {
        onUpdate(userId, { x: newPosition.x, y: newPosition.y });
      }
    },
    onDragEnd: () => {
      if (isSelectingInitialNode) return;
      if (isAddingEdgeBetweenNodes) return;
      if (isDeletingNode) return;
      document.body.style.cursor = 'auto';
      setInitialPosition([nodesGraph[userId].x, 5, -nodesGraph[userId].y]);
      updateWithPreviousRoads(initialRoads);
    },
  });

  const handleSelectNodeForAddingEdge = (nodeId: string) => {
    if (action.mode.type === PrimaryActionTypes.ADD_EDGE_BETWEEN_NODES) {
      if (!action.mode.payload) {
        addLineBetweenNodesMode({ nodeA: nodeId });
      } else {
        addEdgeBetweenNodes(action.mode.payload.nodeA, nodeId, roadId);
        resetMode();
      }
    }
  };

  return (
    <Circle
      {...bind()}
      opacity={0.5}
      radius={4}
      onClick={() => {
        if (isSelectingInitialNode) {
          addNodeToOtherNodeMode({ connectedNode: { roadId, nodeId: userId } });
        }
        if (isAddingEdgeBetweenNodes) {
          handleSelectNodeForAddingEdge(userId);
        }
        if (!isDeletingNode) return;
        onDeleteNodeAndEdges(userId, roadId);
      }}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
      color={hovered || highlightSelectedInitialNode ? '#ff1050' : color}
      position={pos}
      roadId={roadId}
      key={key}
      name={name}
    />
  );
};
