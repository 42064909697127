import React from 'react';
import Integration from './integration';
import {
  CompanyIntegrationsContainer,
  CompanyIntegrationsTitle,
  IntegrationsWrapper,
  ToastWrapper,
} from './integrations.styled';
import { useTranslation } from 'react-i18next';
import useSecrets from 'utils/hooks/use-secrets';
import { useKnowledgeBase } from 'utils/knowledge-base/use-knowledge-base';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_ACCOUNT_INFO } from 'services/user-gql';
import { Button, Notification, ScreenLoader, Typography } from '@ratedpower/components';
import { InfoSvg } from 'assets/icons';
import { IndexIds } from 'index.ids';

const Integrations: React.FC = () => {
  const { KBhowToSetYourAPIMeteo: howToLink } = useKnowledgeBase();
  const { t } = useTranslation();
  const { loading: loadingId, data: companyData } = useQuery(GET_COMPANY_ACCOUNT_INFO, {
    fetchPolicy: 'cache-first',
  });
  const { loadingSecrets, integrationSecrets } = useSecrets(
    companyData ? companyData.companyAccountInfo.company.id : undefined,
    false
  );

  if (loadingId) return <ScreenLoader />;

  return (
    <CompanyIntegrationsContainer>
      <ToastWrapper>
        <Notification status="informative" description={t('company:meteorological-integration-info-top-placeholder')} />
      </ToastWrapper>
      <CompanyIntegrationsTitle>
        <Typography weight="bold" size="l" dataTest={IndexIds.MyCompany.Header}>
          {t('company:meteorological-integrations')}
        </Typography>
        <Button
          variant="link"
          text={t('design-process:how-to')}
          Icon={InfoSvg}
          iconPosition="left"
          onClick={() => window.open(howToLink, '_blank')}
          textUnderlined
        />
      </CompanyIntegrationsTitle>
      {loadingSecrets && <ScreenLoader />}
      <IntegrationsWrapper>
        {!loadingSecrets && (
          <>
            {integrationSecrets.map((integrationSecret) => (
              <Integration
                key={integrationSecret.secret}
                integrationSecret={integrationSecret}
                companyId={companyData.companyAccountInfo.company.id}
              />
            ))}
          </>
        )}
      </IntegrationsWrapper>
    </CompanyIntegrationsContainer>
  );
};

export default Integrations;
