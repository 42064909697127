import React from 'react';
import * as S from './teams-card-view.styled';
import { ConditionalWrapper, Tooltip, Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { ITeam, TeamContentType } from 'types/teams';
import TeamBigCard from '../components/team-big-card';
import { useTeamActions } from '../hooks/use-team-actions';
import { useTeamsPermissions } from '../hooks/use-teams-permissions';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { IUserMinInfoDTO } from 'types/users';
import { IndexIds } from 'index.ids';

interface IProps {
  teams: ITeam[];
}

const TeamsCardsView: React.FC<IProps> = ({ teams }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const goToTeamView = (id: string) => () =>
    navigate(`${id}/${TeamContentType.PROJECTS}`, {
      state: { teamName: teams.find((team) => team.id === id)?.name },
    });

  const { handleTeamAction } = useTeamActions();
  const { teamsAllowedDropdownActions } = useTeamsPermissions();

  const printTeamManagersList = (teamManagers: IUserMinInfoDTO[]) => {
    if (teamManagers.length === 1) {
      return teamManagers[0].name + ' ' + teamManagers[0].surname;
    }
    return teamManagers[0].name + ' ' + teamManagers[0].surname + '...';
  };

  return (
    <S.CardsContainer>
      {teams.map((team: ITeam) => (
        <TeamBigCard
          key={team.id}
          cover={team.coverColor || team.coverImage?.highResUrl}
          title={team.name}
          onClick={goToTeamView(team.id)}
          onClickOption={handleTeamAction(team.id, team.name)}
          popupIconOptions={teamsAllowedDropdownActions()}
          highlightOnHover
          dataTest={IndexIds.Users.TeamCard}
        >
          <S.DescriptionContainer>
            {!!team.teamManagersList?.length && (
              <ConditionalWrapper
                condition={team.teamManagersList.length > 1}
                wrapper={(children) => (
                  <Tooltip
                    description={team.teamManagersList
                      ?.reduce<string>(
                        (previous, currentValue) =>
                          previous + ' ' + currentValue.name + ' ' + currentValue.surname + ',',
                        ''
                      )
                      .slice(0, -1)}
                  >
                    {children}
                  </Tooltip>
                )}
              >
                <Typography nowrap>
                  {t('user:teams.team-manager')}: {printTeamManagersList(team.teamManagersList)}
                </Typography>
              </ConditionalWrapper>
            )}
            <Typography nowrap>{team.numProjects} projects</Typography>
            <Typography nowrap>{team.numMembers} members</Typography>
            <Typography color={theme.v2.text.secondary.default} nowrap>
              {team.description}
            </Typography>
          </S.DescriptionContainer>
        </TeamBigCard>
      ))}
    </S.CardsContainer>
  );
};

export default TeamsCardsView;
