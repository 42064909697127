import React, { useState } from 'react';
import * as S from './teams-tab.styled';
import { NotificationBar, RatedPowerLoader, Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import TeamsCardsView from './teams-card-view';
import useQuerySearchParams from 'utils/hooks/use-query-search-params';
import TeamsListView from './teams-list-view';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';
import TeamsFilterPanel from './teams-filter.panel';
import TeamModals from './team-management/team-modals';
import { ITeamsFilters } from 'types/teams';
import { Permission } from 'access-control/permissions';
import { useAuthorization } from 'access-control/can';
import { InfoSvg } from 'assets/icons';
import { useListTeams } from './hooks/use-list-teams';
import { CARD_VIEW_PAGE_SIZE, LIST_VIEW_PAGE_SIZE, viewOptions } from 'utils/teams';
import { ContentTopBar } from 'components/content-top-bar';
import { IndexIds } from 'index.ids';

const Teams: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthorized } = useAuthorization();
  const viewQueryParam = useQuerySearchParams().get('view');
  const [filtersPanelOpened, setFiltersPanelOpened] = useState(false);

  const isCardsView = viewQueryParam === viewOptions[0].value || !viewQueryParam;
  const isListView = viewQueryParam === viewOptions[1].value;

  const { loading, teams, error, sortedBy, paginationComponent, setSortedBy, setTeamsFilters, inputSearchProps } =
    useListTeams({
      infiniteScroll: false,
      pageSize: isCardsView ? CARD_VIEW_PAGE_SIZE : LIST_VIEW_PAGE_SIZE,
    });

  const canDisplayCardsView = teams && isCardsView;
  const canDisplayListView = teams && isListView;

  const handleOpenFiltersPanel = (open: boolean) => () => setFiltersPanelOpened(open);

  return (
    <>
      <S.TeamsTabContainer>
        <S.TeamsTabContent>
          <S.ContentTopbarWrapper>
            <Typography weight="bold" dataTest={IndexIds.Users.TeamsTitle}>
              {t('user:teams.all-teams')}
            </Typography>
            <ContentTopBar
              searchPlaceholder={t('user:teams.search-team')}
              searchOnChange={(search: string) => inputSearchProps.onChange(search)}
              filterOnClick={handleOpenFiltersPanel(true)}
            />
          </S.ContentTopbarWrapper>
          {loading && !teams && <RatedPowerLoader />}
          {error && (
            <S.ErrorContainer>
              <ErrorRetrievingData />
            </S.ErrorContainer>
          )}
          {!isAuthorized(Permission.TEAM_WRITE_IN_USER_COMPANY) && !teams?.length && (
            <NotificationBar
              status="informative"
              description={t('user:teams.team-managers-no-teams-assigned')}
              Icon={() => <InfoSvg />}
              fitContent
            />
          )}
          {canDisplayCardsView && <TeamsCardsView teams={teams} />}
          {canDisplayListView && <TeamsListView teams={teams} sortedBy={sortedBy} setSortedBy={setSortedBy} />}
        </S.TeamsTabContent>
        <TeamModals />
        <TeamsFilterPanel
          isOpen={filtersPanelOpened}
          onApply={(filters: ITeamsFilters) => {
            setTeamsFilters(filters);
            handleOpenFiltersPanel(false)();
          }}
          onClose={handleOpenFiltersPanel(false)}
        />
      </S.TeamsTabContainer>
      {paginationComponent}
    </>
  );
};

export default Teams;
