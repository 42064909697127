import styled from 'styled-components';

export const KpisDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.v2.spacing.xs};
`;

export const KpisDataRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
