import React, { Suspense, useState } from 'react';
import * as S from '../design-comparison.styled';
import { IComparisonColumn as IColumn } from './data-table-types';
import ComparisonDesignHeader from './design-header/comparison-design-header';
import { PencilSvg } from 'assets/icons';
import useDesignComparisonTable from '../use-design-comparison-table';
import { IComparisonFilter } from 'types/design-comparison.types';
import { useDesignComparisonData } from '../design-comparison-state';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import ModalEditNotes from '../../designs/modal-edit-notes/modal-edit-notes';
import { IDesign } from 'types/design';
import { useModal } from 'utils/hooks/use-modal';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useCanEditDesigns } from 'utils/hooks/use-can-edit-designs';
import { RatedPowerLoader, StatusSVGWrapper, Typography } from '@ratedpower/components';
import { designAttributes } from 'utils/constants/design-attributes';

interface IDataTableProps {
  activeAttributes: IComparisonFilter[];
  updateActiveFilters: (updatedFilters: IComparisonFilter[]) => void;
}

const reorder = (list: IComparisonFilter[], startIndex: any, endIndex: any): IComparisonFilter[] => {
  const result: IComparisonFilter[] = Array.from(list);
  const [removedLabel] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removedLabel);

  return result;
};

const ComparisonTable: React.FC<IDataTableProps> = ({ activeAttributes, updateActiveFilters }) => {
  const { designs, loading, removeDesign, toggleFavorite, refetch } = useDesignComparisonData();
  const { rowLabels, rowData, columnHeaders, isLayoutShown, noLayoutAttributes, sortLoading } =
    useDesignComparisonTable(designs, activeAttributes);
  const { t } = useTranslation();

  const [itemToEdit, setItemToEdit] = useState<IDesign | null>(null);
  const editNotesModal = useModal();

  const { canEditDesign } = useCanEditDesigns();

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(noLayoutAttributes, result.source.index, result.destination.index);
    updateActiveFilters(isLayoutShown ? [designAttributes.satellitePicture.low, ...items] : items);
  };

  const handleModalEditNotes = (id: string) => {
    editNotesModal.setModalOpen(true);
    const item: IDesign[] = designs.filter((design) => design.id === id);
    setItemToEdit(item[0]);
  };

  const handleEditNotes = () => {
    editNotesModal.setModalOpen(false);
    setItemToEdit(null);
    refetch();
  };

  return (
    <Suspense fallback={<RatedPowerLoader />}>
      <S.ComparisonTableContainer>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided: any) => (
              <S.Table className="table" {...provided.droppableProps} ref={provided.innerRef}>
                <div className="row">
                  <div className="headers last-header"></div>
                  {columnHeaders.map((column: IColumn) => (
                    <div key={`${column.data.number}. ${column.data.name}`}>
                      {column.data && (
                        <ComparisonDesignHeader
                          design={column.data}
                          onFavorite={() => toggleFavorite(column.data.id)}
                          onRemove={() => removeDesign(column.data.id)}
                          isLayoutShown={isLayoutShown}
                        />
                      )}
                    </div>
                  ))}
                </div>
                {loading || sortLoading ? (
                  <RatedPowerLoader />
                ) : (
                  rowData.map((row: any, i: any) => (
                    <Draggable
                      key={Array.isArray(row.key) ? row.key[0] : row.key}
                      draggableId={typeof row.key === 'string' ? row.key : row.key[0]}
                      index={i}
                    >
                      {(providedDraggable: any) => (
                        <div className="row" {...providedDraggable.draggableProps} ref={providedDraggable.innerRef}>
                          <div className={classNames('headers', row.className)} {...providedDraggable.dragHandleProps}>
                            {rowLabels[i].cell}
                          </div>
                          {providedDraggable.placeholder}
                          {columnHeaders.map((column: IColumn) => {
                            const highlightedCell = row.values?.greatest?.id.includes(column.selector);
                            return (
                              <div
                                key={`${column.data.number}. ${column.data.name}`}
                                className={classNames('cell', {
                                  highlight: highlightedCell,
                                })}
                                onClick={() =>
                                  canEditDesign(column.data.createdBy.id, column.data.projectTeamId) &&
                                  row.key === 'notes'
                                    ? handleModalEditNotes(column.selector)
                                    : () => false
                                }
                              >
                                {!!column.cell ? (
                                  column.cell(row.values[column.selector].render)
                                ) : (
                                  <Typography nowrap>{row.values[column.selector].render}</Typography>
                                )}
                                {canEditDesign(column.data.createdBy.id, column.data.projectTeamId) &&
                                  row.key === 'notes' && (
                                    <StatusSVGWrapper active={false} icon={PencilSvg} size="s" className="notes-edit" />
                                  )}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </Draggable>
                  ))
                )}
                {provided.placeholder}
              </S.Table>
            )}
          </Droppable>
        </DragDropContext>
      </S.ComparisonTableContainer>
      {editNotesModal.isOpen && itemToEdit && (
        <ModalEditNotes
          isActive={editNotesModal.isOpen}
          width="small"
          title={t('design:edit-notes')}
          item={itemToEdit}
          onClose={() => editNotesModal.setModalOpen(false)}
          onSubmit={() => handleEditNotes()}
        />
      )}
    </Suspense>
  );
};

export default ComparisonTable;
