import styled from 'styled-components';

export const ValidationErrorsDisplayWrapper = styled.div`
  position: fixed;
  top: 115px;
  right: 50px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.v2.elevation.shadow.l};
  width: 308px;
`;
export const ValidationStatusWrapper = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.xs};
  box-shadow: ${({ theme }) => theme.v2.elevation.shadow.l};
  background-color: ${({ theme }) => theme.v2.bg.secondary.default};
`;
