import styled from 'styled-components';

export const CurrentPageContentContainer = styled.div<{ $takeFullHeight: boolean }>`
  flex-grow: 1;
  max-height: ${({ $takeFullHeight }) => ($takeFullHeight ? '100%' : 'calc(100% - 52px)')};
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .banner-description {
    display: flex;
    column-gap: ${({ theme }) => theme.v2.spacing.xxs};
  }
`;

const AppContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  font-family: 'Lato', 'Ubuntu', 'Open Sans';

  #main {
    display: flex;
    height: 100%;
  }

  #content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    height: 100%;
  }
`;

export const CustomNotificationContainer = styled.div`
  right: 10px;
  top: 10px;
  position: absolute;
`;

export default AppContainer;
