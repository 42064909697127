import React, { forwardRef } from 'react';

// eslint-disable-next-line react/prop-types
const InstancedMeshPrimitive: any = forwardRef<any, any>(({ children, ...props }, meshRef) => (
  <instancedMesh ref={meshRef} {...props}>
    {children}
  </instancedMesh>
));

InstancedMeshPrimitive.displayName = 'InstancedMeshPrimitive';

export default InstancedMeshPrimitive;
