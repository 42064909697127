import { useThree } from '@react-three/fiber';
import React, { useEffect } from 'react';
import * as THREE from 'three';

interface IControlsProps {
  bbox: {
    min: { x: number; y: number };
    max: { x: number; y: number };
  };
}

const Controls: React.FC<IControlsProps> = ({ bbox }) => {
  const { controls } = useThree();

  const fitToBox = (newBbox: any) => {
    const minVector = new THREE.Vector3(newBbox.min.x, 0, -newBbox.min.y);
    const maxVector = new THREE.Vector3(newBbox.max.x, 0, -newBbox.max.y);
    const box3 = new THREE.Box3(minVector, maxVector);
    controls.setTarget(0, 3, 0, false);
    controls.setPosition(0, 3, 0, false);
    controls.fitToBox(box3, false);
  };

  useEffect(() => {
    if (bbox && controls) fitToBox(bbox);
  }, [bbox, controls]);

  return <></>;
};

export default Controls;
