import React, { useState } from 'react';
import * as S from './user-notification.styled';
import { LinkText, StatusSVGWrapper, SVGWrapper, Tooltip, Typography } from '@ratedpower/components';
import { BellSvg, MailClosedSvg, MailOpened, PencilSvg, TrashSvg } from 'assets/icons';
import { getDateMonthDDYYYY } from 'utils/date';
import { Collapse } from 'react-collapse';
import { INotification } from 'types/notifications';
import NotificationsCollapseIcon from 'components/notifications/notifications-collapse-icon/notifications-collapse-icon';
import { useTheme } from 'styled-components';
import useMarkNotificationAsRead from 'components/notifications/hooks/use-mark-notifications-as-read';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data/index';
import { ISVGCustomDimensions } from '@ratedpower/components/dist/types/svg-wrapper/svg-wrapper';
import useDeleteNotification from 'components/notifications/hooks/delete-notification';
import DeleteUserNotificationModal from 'components/notifications/user-notification/delete-user-notification-modal/delete-user-notification.modal';
import EditNotificationModal from 'pages/communication/edit-notification/edit-notification-modal/edit-notification-modal';
import {
  CreateNotificationIcon,
  NotificationsIconMap,
} from 'pages/communication/create-notification/create-notification-form/create-notification-icon-options';
import { ToastType, useToasts } from 'utils/hooks/use-toasts';
import useGetNotificationToast, { NotificationsOperation } from 'pages/communication/hooks/use-get-notifications-toast';
import { useTranslation } from 'react-i18next';
import { IndexIds } from 'index.ids';
import useMarkNotificationsAsUnread from 'components/notifications/hooks/use-mark-notifications-as-unread';

export const UserNotificationTestIds = {
  unreadNotification: IndexIds.Communication.Notification.EnvelopeOpenIcon,
  readNotification: IndexIds.Communication.Notification.EnvelopeClosedIcon,
  editNotification: IndexIds.Communication.Notification.EditButton,
  deleteNotification: IndexIds.Communication.Notification.DeleteButton,
  title: IndexIds.Communication.Notification.Title,
  description: 'notification-description',
  icon: IndexIds.Communication.Notification.Icon,
};

interface IProps {
  notification: INotification;
  isNotificationsPanel: boolean;
}

// Some icons were not properly scaling due to fixed viewbox size on the SVGs files
const svgDimensions: ISVGCustomDimensions = { width: '16px', height: '16px' };

const DEFAULT_ICON = BellSvg;

const UserNotification: React.FC<IProps> = ({ notification, isNotificationsPanel }: IProps) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const { title, description, notificationRead, knowledgeBaseArticles, publishedDate, notificationSubsections } =
    notification;
  const theme = useTheme();
  const notificationIcon = NotificationsIconMap.get(notification.iconName as CreateNotificationIcon) || DEFAULT_ICON;
  const { addToast } = useToasts();
  const getNotificationToast = useGetNotificationToast();
  const isNotificationReadCase = isNotificationsPanel && !notificationRead;
  const iconColor = isNotificationReadCase ? theme.v2.icon.accent.default : theme.v2.icon.primary.default;

  const createToastHandler = (statusType: ToastType, operation: NotificationsOperation): (() => void) => {
    return (): void => {
      showToast(statusType, operation);
    };
  };

  const onReadCompleted = createToastHandler(ToastType.SUCCESS, NotificationsOperation.READ);

  const onReadError = createToastHandler(ToastType.ERROR, NotificationsOperation.READ);

  const onDeletedCompleted = createToastHandler(ToastType.SUCCESS, NotificationsOperation.DELETE);

  const onDeletedError = createToastHandler(ToastType.ERROR, NotificationsOperation.DELETE);

  const onUnreadCompleted = createToastHandler(ToastType.SUCCESS, NotificationsOperation.UNREAD);

  const onUnreadError = createToastHandler(ToastType.ERROR, NotificationsOperation.UNREAD);

  const { deleteNotification, error: errorOnDelete } = useDeleteNotification(
    notification.id,
    onDeletedCompleted,
    onDeletedError
  );

  const { markNotificationAsRead, error: errorOnRead } = useMarkNotificationAsRead(
    notification.id,
    onReadCompleted,
    onReadError
  );

  const { markNotificationAsUnread, error: errorOnUnread } = useMarkNotificationsAsUnread(
    notification.id,
    onUnreadCompleted,
    onUnreadError
  );

  const error = errorOnRead || errorOnDelete || errorOnUnread;

  const showToast = (statusType: ToastType, operation: NotificationsOperation): void => {
    const { location, message, type, duration } = getNotificationToast(operation, statusType);

    addToast(location, message, type, duration);
  };

  const handleReadNotification = (): void => {
    if (notificationRead) return;

    markNotificationAsRead();
  };

  const handleUnreadNotification = (): void => {
    if (!notificationRead) return;

    markNotificationAsUnread();
  };

  const handleModal =
    (setModalState: React.Dispatch<React.SetStateAction<boolean>>) =>
    (e: React.MouseEvent<HTMLDivElement>): void => {
      e.stopPropagation();
      setModalState(true);
    };

  const handleDeleteNotification = handleModal(setShowDeleteModal);

  const handleEditModal = handleModal(setShowEditModal);

  const readIconConfig = notificationRead
    ? {
        icon: MailClosedSvg,
        dataTest: UserNotificationTestIds.readNotification,
        onClick: handleUnreadNotification,
      }
    : {
        icon: MailOpened,
        dataTest: UserNotificationTestIds.unreadNotification,
        onClick: handleReadNotification,
      };

  return (
    <>
      <DeleteUserNotificationModal
        show={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={deleteNotification}
      />
      <EditNotificationModal show={showEditModal} onClose={() => setShowEditModal(false)} notification={notification} />
      <div>
        {error && <ErrorRetrievingData />}
        <S.NotificationContainer
          $isRead={isNotificationReadCase}
          onClick={() => setIsCollapsed(!isCollapsed)}
          data-testid={
            isNotificationReadCase
              ? IndexIds.Communication.Notification.Container
              : IndexIds.Communication.Notification.ContainerUnread
          }
        >
          <S.TopContainer>
            <S.TitleContainer>
              <NotificationsCollapseIcon isCollapsed={isCollapsed} />
              <SVGWrapper icon={notificationIcon} size="m" color={iconColor} dataTest={UserNotificationTestIds.icon} />
              <Typography weight="bold" size="l" dataTest={UserNotificationTestIds.title}>
                {title}
              </Typography>
            </S.TitleContainer>
            <S.RightTopContainer>
              <Typography size="s" dataTest={IndexIds.Communication.Notification.Date}>
                {getDateMonthDDYYYY(publishedDate)}
              </Typography>
              {isNotificationsPanel && (
                <StatusSVGWrapper
                  icon={readIconConfig.icon}
                  size="s"
                  active={true}
                  hoverColor={theme.v2.icon.accent.default}
                  color={theme.v2.icon.primary.default}
                  tooltip={notificationRead ? undefined : { place: 'bottom', text: t('communication:mark-as-read') }}
                  svgDimensions={svgDimensions}
                  onClick={readIconConfig.onClick}
                  dataTest={readIconConfig.dataTest}
                />
              )}
              {!isNotificationsPanel && (
                <>
                  <Tooltip description={t('action:edit')} place="bottom">
                    <SVGWrapper
                      icon={PencilSvg}
                      size="s"
                      color={theme.v2.icon.primary.default}
                      svgDimensions={svgDimensions}
                      onClick={handleEditModal}
                      dataTest={UserNotificationTestIds.editNotification}
                    />
                  </Tooltip>
                  <Tooltip description={t('action:delete')} place="bottom">
                    <SVGWrapper
                      icon={TrashSvg}
                      size="s"
                      color={theme.v2.icon.primary.default}
                      svgDimensions={svgDimensions}
                      onClick={handleDeleteNotification}
                      dataTest={UserNotificationTestIds.deleteNotification}
                    />
                  </Tooltip>
                </>
              )}
            </S.RightTopContainer>
          </S.TopContainer>
          <S.DescriptionCollapsed>
            <S.DescriptionWrapper $isCollapsed={isCollapsed} dataTest={UserNotificationTestIds.description}>
              {description}
            </S.DescriptionWrapper>
            <Collapse isOpened={!isCollapsed}>
              <S.CollapseWrapper data-testid={IndexIds.Communication.Notification.CollapsedArea}>
                <S.KnowledgeBaseArticleContainer>
                  {notificationSubsections.map((notificationSubsection) => (
                    <Typography
                      as="li"
                      key={notificationSubsection.id}
                      dataTest={IndexIds.Communication.Notification.ListItem}
                    >
                      {notificationSubsection.description}
                    </Typography>
                  ))}
                  {knowledgeBaseArticles.map(
                    (knowledgeBaseArticle) =>
                      knowledgeBaseArticle.link && (
                        <S.KnowledgebaseArticle key={knowledgeBaseArticle.id}>
                          <LinkText
                            href={knowledgeBaseArticle.link}
                            textUnderlined
                            target="_blank"
                            dataTest={IndexIds.Communication.Notification.LinkItem}
                          >
                            {knowledgeBaseArticle.title || knowledgeBaseArticle.link}
                          </LinkText>
                        </S.KnowledgebaseArticle>
                      )
                  )}
                </S.KnowledgeBaseArticleContainer>
              </S.CollapseWrapper>
            </Collapse>
          </S.DescriptionCollapsed>
        </S.NotificationContainer>
      </div>
    </>
  );
};

export default UserNotification;
