import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ISubscriptionPlanDTO, PlanStatus } from 'types/subscription-plan';
import { IForm } from 'utils/hooks/use-form';
import { IUseModal, useModal } from 'utils/hooks/use-modal';
import { useNotificationToast } from './use-notification-toast';
import {
  GET_SUPPORT_CURRENT_PLAN,
  GET_SUPPORT_PLANS,
  RENEW_SUBSCRIPTION_PLAN,
  UPDATE_SUBSCRIPTION_PLAN,
  UPDATE_SUBSCRIPTION_PLAN_EXTENSION,
  UPDATE_SUBSCRIPTION_PLAN_STATUS,
} from 'services/subscription-plan-gql';

export interface IUseEditPlan {
  editPlanModal: IUseModal;
  handleEditPlan: (inputs: IForm, planId: string, extensionId?: string) => void;
  handleRenewPlan: (inputs: IForm) => void;
  handleOpenEditModal: (id: string, isRenew: boolean, extensionId?: string) => void;
  handleUpdatePlanStatus: (id: string, status: PlanStatus) => void;
}

export const useEditPlan = (currentPlan: ISubscriptionPlanDTO, plans: ISubscriptionPlanDTO[]): IUseEditPlan => {
  const { id: supportPagesCompanyId } = useParams<{ id: string }>();
  const { errorNotification, successNotification } = useNotificationToast();

  const editPlanModal = useModal();

  const [updatePlanStatus] = useMutation(UPDATE_SUBSCRIPTION_PLAN_STATUS, {
    onCompleted: (updatedPlan) => {
      if (updatedPlan) successNotification('company:plan-updated-successfully');
    },
    refetchQueries: [GET_SUPPORT_PLANS],
  });

  const onError = (apolloError: any) => errorNotification(apolloError);

  const [renewCurrentPlan] = useMutation(RENEW_SUBSCRIPTION_PLAN, {
    onCompleted: () => {
      editPlanModal.setModalOpen(false);
      successNotification('support:update-company-success');
    },
    onError,
    refetchQueries: [GET_SUPPORT_PLANS],
  });
  const [updateSubscriptionPlanExtension] = useMutation(UPDATE_SUBSCRIPTION_PLAN_EXTENSION, {
    onCompleted: () => {
      editPlanModal.setModalOpen(false);
      successNotification('company:plan-updated-successfully');
    },
    onError,
    refetchQueries: [GET_SUPPORT_PLANS],
  });

  const [updateSubscriptionPlan] = useMutation(UPDATE_SUBSCRIPTION_PLAN, {
    onCompleted: () => {
      editPlanModal.setModalOpen(false);
      successNotification('company:plan-updated-successfully');
    },
    onError,
    refetchQueries: [GET_SUPPORT_PLANS, GET_SUPPORT_CURRENT_PLAN],
  });

  const handleEditPlan = (inputs: IForm, planId: string, extensionId?: string) => {
    const commonVariables = {
      price: Number(inputs.price) || 0,
      projectsPurchased: Number(inputs.projectsPurchased) || 0,
      expirationDate: inputs.expirationDate,
    };
    !!extensionId
      ? updateSubscriptionPlanExtension({
          variables: {
            subscriptionPlanExtensionUpdateDTO: {
              id: extensionId,
              ...commonVariables,
            },
          },
        })
      : updateSubscriptionPlan({
          variables: {
            subscriptionPlanUpdateDTO: {
              id: planId,
              currency: inputs.currency,
              startDate: inputs.startDate,
              plan: inputs.plan,
              customPlanType: inputs.customPlanType || 'NONE',
              notes: inputs.notes,
              ...commonVariables,
            },
            sendWelcomeEmail: inputs.sendWelcomeEmail,
          },
        });
  };

  const handleRenewPlan = (inputs: IForm) => {
    const variables = {
      companyId: supportPagesCompanyId,
      subscriptionPlanCreateDTO: {
        plan: inputs.plan,
        customPlanType: inputs.customPlanType,
        notes: inputs.notes,
        price: Number(inputs.price) || 0,
        currency: inputs.currency,
        projectsPurchased: Number(inputs.projectsPurchased) || 0,
        startDate: inputs.startDate,
        expirationDate: inputs.expirationDate,
      },
      sendWelcomeEmail: inputs.sendWelcomeEmail,
    };
    renewCurrentPlan({ variables });
  };

  const handleOpenEditModal = (id: string, isRenew: boolean, extensionId?: string) => {
    const plan: ISubscriptionPlanDTO | undefined = plans.find((p: ISubscriptionPlanDTO) => {
      return p.id === id && p;
    });
    editPlanModal.setData({
      isRenew,
      plan,
      extension: plan?.extensions.find((extension) => extension.id === extensionId),
      isCurrentPlan: currentPlan?.id === id,
    });
    editPlanModal.setModalOpen(true);
  };

  const handleUpdatePlanStatus = (id: string, status: PlanStatus) => {
    updatePlanStatus({
      variables: {
        subscriptionPlanUpdateStatusDTO: {
          id,
          status,
        },
      },
    });
  };

  return {
    editPlanModal,
    handleEditPlan,
    handleRenewPlan,
    handleOpenEditModal,
    handleUpdatePlanStatus,
  };
};
