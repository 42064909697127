import React from 'react';
import { useProjects } from 'pages/projects/use-projects';
import * as S from 'pages/home/home-projects-map/home-projects-map.styled';
import { useHomeProjectsMapBox } from 'pages/home/home-projects-map/use-home-projects-map';
import GeoPinsMapbox from 'components/maps/geo-pins-map/geo-pins-mapbox';
import { Typography } from '@ratedpower/components';
import { useCompanyDetails } from 'pages/company/details/hooks/use-company-details';
import { useTranslation } from 'react-i18next';
import NewProjectButton from 'pages/projects/topbar-projects/new-project-button';
import { useProjectsActions } from 'pages/projects/use-projects-actions';
import CreateProjectModal from 'pages/teams/components/create-project-modal';
import { useNavigate } from 'react-router-dom';
import { IndexIds } from 'index.ids';

const HomeProjectsMap = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: company } = useCompanyDetails();
  const { projects, projectsError } = useProjects();
  const geoJson = useHomeProjectsMapBox(projects);
  const { handleNewProjectModal, handleCloseNewProjectModal, newProjectFormModal } = useProjectsActions();

  const handleRedirect = (response: any): void => {
    navigate(`/projects/${response?.id}`);
  };

  return (
    <>
      <div>
        <S.MapTopbarContainer>
          <S.MapSectionTitle>
            <Typography size={'l'} weight={'bold'} dataTest={IndexIds.Homepage.ProjectsOfCompany}>
              {t('home:map.title', { companyName: company?.name })}
            </Typography>
            <Typography size={'m'} dataTest={IndexIds.Homepage.ProjectsSubtitle}>
              {t('home:map.subtitle', { companyName: company?.name })}
            </Typography>
          </S.MapSectionTitle>
          <NewProjectButton
            textKey={'home:create-new-project'}
            createProject={projectsError ? undefined : handleNewProjectModal}
          />
        </S.MapTopbarContainer>
        <S.MapContainer>
          <GeoPinsMapbox geoJson={geoJson} componentUpdateDependency={projects} updateCenter />
        </S.MapContainer>
      </div>

      {newProjectFormModal.isOpen && (
        <CreateProjectModal
          addPublicOption
          isActive={newProjectFormModal.isOpen}
          title={t('home:create-project')}
          disableClickOutside
          onClickPrimaryBtn={handleCloseNewProjectModal}
          onClickSecondaryBtn={handleCloseNewProjectModal}
          onClose={() => newProjectFormModal.setModalOpen(false)}
          onSubmitCallback={handleRedirect}
        />
      )}
    </>
  );
};

export default HomeProjectsMap;
