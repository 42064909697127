import styled from 'styled-components';

export const SingleErrorItem = styled.div`
  margin-left: ${({ theme }) => theme.v2.spacing.xxl};
  display: flex;
  gap: ${({ theme }) => theme.v2.spacing.xxs};

  & > p {
    flex-grow: 1;
  }
`;
