import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { CREATE_TEAM_BY_COMPANY, GET_TEAMS_BY_COMPANY } from 'services/team-gql';
import { CreatedTeam, ICreateTeamDTO } from 'types/teams';
import { useToasts } from 'utils/hooks/use-toasts';
import { IndexIds } from 'index.ids';

export const useCreateTeamInCompany = (callback?: (createdTeam: CreatedTeam) => void) => {
  const { t } = useTranslation();
  const { addErrorToast, addSuccessToast } = useToasts();

  const [createTeam] = useMutation(CREATE_TEAM_BY_COMPANY, {
    refetchQueries: [GET_TEAMS_BY_COMPANY],
    context: {
      headers: {
        'apollo-require-preflight': true,
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      addSuccessToast(t('user:teams.team-created-succesfully'), 'bottom-center', IndexIds.Users.SuccessToast);
      if (callback) callback(data.createTeamByCompany);
    },
    onError: () => {
      addErrorToast(t('user:teams.team-creation-error'));
    },
  });

  const handleCreateTeamInSupport = (createTeamByCompanyDTO: ICreateTeamDTO, coverImage?: File) => {
    createTeam({ variables: { createTeamByCompanyDTO, coverImage } });
  };

  return {
    handleCreateTeamInSupport,
  };
};
