import { ApolloError } from '@apollo/client/errors';
import { TFunction } from 'i18next';
import { IAttribute } from 'utils/constants/design-attributes';
import { IUseModal } from 'utils/hooks/use-modal';
import { IOption } from './common';
import { IHoursStepDTO, IInputs, Input, IUpdatedInputs, IupdateInputsWithAffectsPreview } from './design-process';
import { IUserMinInfoDTO } from './users';

export interface IModule {
  id: string;
  companyId: string | null;
  manufacturer: string;
  model: string;
  moduleType: string;
  voltage: number;
  power: number;
  technology: string;
  originalFileName: string;
  date: string;
  status: string;
  type: string;
  uploadedBy: string;
  createdBy?: IUserMinInfoDTO;
}

export interface IInverter {
  id: string;
  companyId: string | null;
  manufacturer: string;
  model: string;
  powerAt30: string;
  powerAt50: string;
  maxVoltage: number;
  efficiency: number;
  status: string;
  uploadedBy: string;
  date: string;
  originalFileName: string;
  createdBy?: IUserMinInfoDTO;
}

export interface IStructure {
  id: string;
  companyId: string | null;
  manufacturer: string;
  model: string;
  type: string;
  positionNumber: string;
  positionLetter: string;
  modulesPerRow: number;
  maxRowsPerMotor: number;
  status: string;
  createdBy?: IUserMinInfoDTO;
  date: string;
  uploadedBy: string;
}

export interface IUseInverterSection {
  companyId?: string | null;
  inputs: IInputs;
  inverter2Error?: ApolloError;
  inverter2Loading: boolean;
  inverterError?: ApolloError;
  inverterLoading: boolean;
  inverterModal: IUseModal;
  isEWFixedStructure: Input;
  secondaryInverterModal: IUseModal;
  selectedInverter: any;
  selectedInverter2: any;
  handleMpptClick: (value: string) => () => void;
  onSelectInverter: React.Dispatch<any>;
  t: TFunction;
  updateInputs: (
    inputsUpdated: IUpdatedInputs,
    options?: { refetch?: boolean; affectsPreview?: boolean }
  ) => Promise<void>;
  setSecondaryInverterModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IUseBatteryInverter {
  inputs: IInputs;
  inverterData: any;
  inverterDetailsModal: any;
  inverterError?: ApolloError;
  inverterLoading: boolean;
  inverterModal: IUseModal;
  selectedInverter: any;
  onSeeDetailsInverter: (idInverter: string) => void;
  onSelectInverter: React.Dispatch<any>;
  t: TFunction;
  updateBatteryInverterPMax: (value: string) => void;
  updateBatteryContainerOption: (value: string) => void;
  updateBatteryCapacityPerContainer: (value: string) => void;
  updateBatteryEnergyPerRack: (value: string) => void;
  updateBatteryRacksPerContainer: (value: string) => void;
  updateInvertersPerPcs: (value: string) => void;
  updateBatteryCapacityOption: (value: string) => void;
  updateBatteryNumberOfPcs: (value: string) => void;
  updateBatteryCycleDuration: (value: IHoursStepDTO[]) => void;
  updateInputsWithAffectsPreview: (params: IupdateInputsWithAffectsPreview) => void;
  updateBatteryAccountLosses: (value: boolean) => void;
  updateBatteryIronLoss: (value: number) => void;
  updateBatteryCopperLoss: (value: number) => void;
}

export interface IFilter {
  field: string;
  operator: string;
  values: any[];
}

export interface IModalTableAttribute extends IAttribute {
  selectedHidden?: boolean;
  tableHidden?: boolean;
  sortable?: boolean;
  newDesignShown?: boolean;
  needsAuthorization?: boolean;
  trackerShown?: boolean;
}
export interface IModuleAttributesOptions {
  onChangeModule?: () => void;
  onDownloadModule?: (module: any) => void;
  onSeeDetails?: (idModule: string) => void;
  onDeleteModule?: () => void;
  type?: 'DESIGN_PROCESS' | 'EQUIPMENT';
  readonly?: boolean;
}

export interface IStructureAttributesOptions {
  onChangeStructure?: () => void;
  onSeeDetails?: (id: string) => void;
  type?: 'DESIGN_PROCESS' | 'EQUIPMENT';
  readonly?: boolean;
}

export interface IInverterOption extends IOption {
  image?: string;
}

export enum EquipmentDatabase {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export type TypeEquipmentDatabase = keyof typeof EquipmentDatabase;

export enum EquipmentStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  ERROR = 'ERROR',
  PRE_SAVE = 'PRE_SAVE',
}

export enum TypeInverter {
  CENTRAL = 'CENTRAL',
  STRING = 'STRING',
}

export enum TypeStructure {
  FIXED = 'FIXED',
  TRACKER = 'TRACKER',
  EAST_WEST = 'EAST_WEST',
}

export enum EquipmentType {
  MODULE = 'MODULE',
  INVERTER = 'INVERTER',
  STRUCTURE = 'STRUCTURE',
}

export enum DesignFor {
  MONOFACIAL = 'MONOFACIAL',
  BIFACIAL = 'BIFACIAL',
}

export interface ISingleGapCreateDTO {
  position: number;
  gap: number;
}

export interface IPostTemplateCreateDTO {
  maximumOverhang: number;
  gaps: ISingleGapCreateDTO[];
}

export interface ICreateStructureDTO {
  companyId: string | null;
  model: string;
  manufacturer: string;
  structureTypeTracker: boolean;
  source?: string;
  sourceLink?: string;
  positionLetter: string;
  positionNumber: number;
  axisGap: number;
  stackGap: number;
  axisModuleGap: number;
  stackModuleGap: number;
  postTemplate: IPostTemplateCreateDTO;
  numModules?: number;
  GCRMin?: number;
  GCRMax?: number;
  slopeMaxNorthSouth?: number;
  slopeMaxEastWest?: number;
  numRowsMax?: number;
  trackingAngleMin?: number;
  trackingAngleMax?: number;
}

export interface IStructureDTO extends ICreateStructureDTO {
  id: string;
  visible?: boolean;
  publishedDate?: Date;
}

export interface IUploadEquipmentForm {
  companyId: string | null;
  type?: string;
}
