import React, { PropsWithChildren } from 'react';
import * as S from './team-big-card.styled';
import { IPopupIconButtonOption, PopupWrapper, SVGWrapper, Typography } from '@ratedpower/components';
import { DotsSvg } from 'assets/icons';
import { useTranslation } from 'react-i18next';
import TeamCover from '../team-cover';
import { IndexIds } from 'index.ids';

interface IProps {
  title: string;
  popupIconOptions?: IPopupIconButtonOption[];
  onClick?: () => void;
  onClickOption?: (selectedOption: IPopupIconButtonOption) => void;
  highlightOnHover?: boolean;
  cover?: string;
  dataTest?: string;
}

const TeamBigCard: React.FC<PropsWithChildren<IProps>> = ({
  cover,
  title,
  popupIconOptions,
  onClick,
  onClickOption,
  highlightOnHover = false,
  children,
  dataTest,
}) => {
  const { t } = useTranslation();

  return (
    <S.CardContainer onClick={onClick} $highlightOnHover={highlightOnHover} data-testid={dataTest}>
      <TeamCover cover={cover} width={328} height={200} editable={false} />
      <S.CardContent>
        <S.CardTitleContainer>
          <Typography size="l" weight="bold" dataTest={IndexIds.Users.TeamTitle}>
            {title}
          </Typography>
          {popupIconOptions && !!popupIconOptions.length && (
            <PopupWrapper
              onClickOption={onClickOption}
              options={popupIconOptions.map((teamAction: IPopupIconButtonOption) => ({
                ...teamAction,
                label: t(teamAction.label),
              }))}
            >
              <SVGWrapper icon={DotsSvg} size="s" />
            </PopupWrapper>
          )}
        </S.CardTitleContainer>

        {children}
      </S.CardContent>
    </S.CardContainer>
  );
};

export default TeamBigCard;
