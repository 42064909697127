import React, { useMemo } from 'react';
import * as S from './company-info-tab.styled';
import { AlertsForm } from './alerts-form/alerts-form';
import Can, { useAuthorization } from 'access-control/can';
import { CompanyFormManagerSelectors } from './company-form-manager-selectors';
import CompanyLogo from 'components/company-logo/company-logo';
import { CompanySamlForm } from './company-saml-form';
import { DeleteCompany } from './delete-company/delete-company';
import PlanHistory from 'pages/company/plan/plan-history/plan-history';
import Skeleton from 'react-loading-skeleton';
import { DefaultLogo } from 'assets/icons';
import { IOption } from 'types/common';
import { Permission } from 'access-control/permissions';
import { RemoteImage } from 'utils/hooks/use-remote-image';
import { useCompanyInfoTab } from './use-company-info-tab';
import { useTheme } from 'styled-components';
import { useTranslatedCountries } from 'utils/hooks/use-translated-countries';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  InputNumber,
  Dropdown,
  InputText,
  LabelWrapper,
  Banner,
  Separator,
  Typography,
  BaseTemplateInput,
  Toggle,
  LinkText,
} from '@ratedpower/components';
import { IndexIds } from 'index.ids';
import { devSecOpsForm } from 'utils/constants/support';

const CompanyInfoTab: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    customerSupportUsers,
    businessUsers,
    sdrUsers,
    formError,
    loadingOptionsData,
    edition,
    companyUpdateLoading,
    setEdition,
    handleSubmit,
    setNewCompanyLogo,
    inputs,
    handleInputChangeManual,
    loadingCompanyData,
    contextCompany,
  } = useCompanyInfoTab();

  const { countries } = useTranslatedCountries(false, 'id');
  const { isAuthorized } = useAuthorization();
  const loader = useMemo(() => <Skeleton style={{ color: theme.v2.bg.secondary.default }} height="20px" />, [theme]);

  const selectedCountry = countries?.find(
    (countryOption: IOption) => contextCompany?.country?.id === countryOption.value
  );

  return (
    <>
      {new Date(contextCompany?.currentPlan.expirationDate) < new Date('2023-06-01') && (
        <Banner
          header="This company has not been yet migrated to Enverus Account Service."
          description={
            <S.BannerDescriptionContainer>
              <Typography>Prior to reactivating the license, please reach out to DevSecOps</Typography>
              <LinkText
                href={devSecOpsForm}
                target="_blank"
                rel="noopener noreferrer"
                color={theme.v2.btn.link.text.hover}
                textUnderlined
              >
                here.
              </LinkText>
            </S.BannerDescriptionContainer>
          }
          status="warning"
          closable
        />
      )}
      <S.CompanyInfoContainer>
        <S.CompanyInfoColumnContainer>
          <S.Block>
            <S.CompanyFormSection>
              <S.CompanyLogoHeadline>
                <Typography weight="bold">{t('user:account-info')}</Typography>
                {isAuthorized(Permission.COMPANY_SUPPORT_WRITE) && (
                  <Button text={t('action:edit')} variant="link" onClick={() => setEdition(!edition)} textUnderlined />
                )}
              </S.CompanyLogoHeadline>

              <S.CompanyLogoInfo>{t('company:company-logo-info')}</S.CompanyLogoInfo>
              {loadingCompanyData ? (
                <Skeleton height="110px" width="160px" />
              ) : edition ? (
                <CompanyLogo
                  canSelect={isAuthorized(Permission.COMPANY_SUPPORT_WRITE)}
                  onSelect={(file: File) => setNewCompanyLogo(file)}
                  url={contextCompany?.logo?.lowResUrl}
                  hideInfo
                  customHeight="110px"
                  customWidth="160px"
                />
              ) : (
                <S.CompanyLogoImg>
                  {contextCompany?.logo?.lowResUrl ? (
                    <RemoteImage url={contextCompany?.logo?.lowResUrl} alt="Company Logo" />
                  ) : (
                    <DefaultLogo className="default-img" />
                  )}
                </S.CompanyLogoImg>
              )}

              <Separator />
              <Typography>{t('user:profile')}</Typography>
              <LabelWrapper
                title={t('support:company-name')}
                loading={loadingCompanyData}
                Loader={loader}
                size="s"
                alignItems="center"
              >
                <InputText
                  dataTest={IndexIds.CompaniesActivity.CompanyName}
                  warning={!!formError}
                  name="name"
                  value={inputs.name}
                  readOnly={!edition}
                  onChange={(newValue) => handleInputChangeManual('name', newValue)}
                  size="s"
                />
              </LabelWrapper>
              <LabelWrapper
                size="s"
                alignItems="center"
                title={t('company:notification-email')}
                loading={loadingCompanyData}
                Loader={loader}
              >
                <BaseTemplateInput
                  warning={!!formError}
                  type="email"
                  name="email"
                  value={inputs.email}
                  size="s"
                  readOnly={!edition}
                  onChange={(newValue) => handleInputChangeManual('email', newValue)}
                />
              </LabelWrapper>
              <LabelWrapper
                title={t('support:salesforce-company-id')}
                loading={loadingCompanyData}
                Loader={loader}
                size="s"
                alignItems="center"
              >
                <InputText
                  name="salesforceId"
                  dataTest="salesforce-company-id"
                  value={inputs.salesforceId}
                  onChange={(newValue) => handleInputChangeManual('salesforceId', newValue)}
                  size="s"
                  readOnly={!edition}
                />
              </LabelWrapper>
              <Separator />
              <Typography>{t('location')}</Typography>
              <LabelWrapper
                title={t('company:country')}
                loading={loadingCompanyData || !contextCompany || !countries}
                Loader={loader}
                size="s"
                alignItems="center"
              >
                <Dropdown
                  name="countryId"
                  options={countries}
                  value={selectedCountry ? [selectedCountry] : []}
                  readonly={!edition}
                  size="s"
                  onChange={([item]) => handleInputChangeManual('country', item)}
                />
              </LabelWrapper>
              <Separator />
              <Typography>{t('company:managers')}</Typography>
              <CompanyFormManagerSelectors
                loading={loadingOptionsData}
                customerSupportUsers={customerSupportUsers}
                businessUsers={businessUsers}
                sdrUsers={sdrUsers}
                edition={edition}
                loader={loader}
                inputs={inputs}
                handleInputChangeManual={handleInputChangeManual}
                inputSize="s"
              />
              <Separator />
              <Typography>{t('company:users')}</Typography>
              <LabelWrapper
                title={t('company:number-of-users')}
                size="s"
                alignItems="baseline"
                loading={loadingCompanyData}
                Loader={loader}
              >
                {edition ? (
                  <S.InputWithUnlimitedOption>
                    <InputNumber
                      size="s"
                      warning={!!formError}
                      name="userLimit"
                      value={inputs.userLimit.numberOfUsers}
                      onChange={(newValue) =>
                        handleInputChangeManual('userLimit', { ...inputs.userLimit, numberOfUsers: newValue })
                      }
                      min={
                        !contextCompany?.unlimitedUsers && contextCompany?.usersCount ? contextCompany?.usersCount : 0
                      }
                      disabled={inputs.userLimit.unlimitedUsers}
                    />
                    <Checkbox
                      id="plan-details-unlimited-users"
                      name="unlimitedUsers"
                      checked={inputs.userLimit.unlimitedUsers}
                      onChange={() =>
                        handleInputChangeManual('userLimit', {
                          unlimitedUsers: !inputs.userLimit.unlimitedUsers,
                          numberOfUsers: 0,
                        })
                      }
                      label={t('company:unlimited')}
                    />
                  </S.InputWithUnlimitedOption>
                ) : (
                  <Typography>
                    {inputs.userLimit?.unlimited ? t('company:unlimited') : inputs.userLimit?.numberOfUsers}
                  </Typography>
                )}
              </LabelWrapper>
              <LabelWrapper
                title={t('company:number-of-users-created')}
                loading={loadingCompanyData}
                Loader={loader}
                size="s"
              >
                <Typography>{`${contextCompany?.usersCount}/${
                  contextCompany?.unlimitedUsers ? t('company:unlimited') : contextCompany?.userLimit
                }`}</Typography>
              </LabelWrapper>
              <Separator />
              <Typography>{t('company:site-boundary', 'Site boundary')}</Typography>
              <LabelWrapper
                title={t('company:kilometers', 'Kilometers')}
                loading={loadingCompanyData}
                Loader={loader}
                size="s"
                alignItems="center"
              >
                <InputNumber
                  warning={!!formError}
                  name="maxSiteToProjectDistance"
                  value={inputs.maxSiteToProjectDistance / 1000}
                  size="s"
                  onChange={(newValue) =>
                    handleInputChangeManual('maxSiteToProjectDistance', parseInt(newValue) * 1000)
                  }
                  readOnly={!edition}
                />
              </LabelWrapper>
              <Separator />
              <Typography>{t('design:documentation', 'Documentation')}</Typography>
              <Typography>{t('support:logo-documentation-info')}</Typography>
              <Toggle
                disabled={!isAuthorized(Permission.COMPANY_SUPPORT_WRITE) || !edition}
                className="logo-documentation-toggle"
                label={t('support:logo-documentation-label')}
                onToggle={() => handleInputChangeManual('showLogoInDocuments', !inputs.showLogoInDocuments)}
                name="showLogoInDocuments"
                isOn={inputs.showLogoInDocuments}
                alignItems="center"
              />
              {formError && (
                <Typography size="s" color={theme.v2.error.text.contrast}>
                  {t(`errors:${formError}`)}
                </Typography>
              )}
              {edition && (
                <S.FormActions>
                  <Button text={t('action:discard')} variant="ghost" onClick={() => setEdition(false)} fullWidth />
                  <Button
                    text={t('action:save')}
                    variant="accent"
                    loading={companyUpdateLoading}
                    fullWidth
                    onClick={handleSubmit}
                  />
                </S.FormActions>
              )}
            </S.CompanyFormSection>
          </S.Block>

          <S.Block>
            <S.CompanyFormSection>
              <AlertsForm />
            </S.CompanyFormSection>
          </S.Block>
          <S.Block>
            <Can perform={Permission.COMPANY_SUPPORT_SAML}>
              <CompanySamlForm />
            </Can>
          </S.Block>
          <S.Block>
            <Can perform={Permission.COMPANY_SUPPORT_DELETE}>
              <DeleteCompany companyId={contextCompany?.id} companyName={contextCompany?.name} />
            </Can>
          </S.Block>
        </S.CompanyInfoColumnContainer>
        <S.PlansHistorySection>
          <PlanHistory />
        </S.PlansHistorySection>
      </S.CompanyInfoContainer>
    </>
  );
};

export default CompanyInfoTab;
