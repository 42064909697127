import styled from 'styled-components';
import { comparisonMaxWidth } from 'pages/design-comparison/design-comparison.styled';

export const ComparisonDesignHeaderContainer = styled.div`
  cursor: pointer;
  width: ${comparisonMaxWidth};
  margin-bottom: ${({ theme }) => theme.v2.spacing.s};
  border-radius: ${({ theme }) => theme.v2.radius.s};
  box-shadow: ${({ theme }) => theme.v2.elevation.shadow.m};
`;

export const DesignInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DesignInfoTop = styled.div`
  display: flex;
  column-gap: ${({ theme }) => theme.v2.spacing.xs};
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.v2.spacing.m};

  .buttons {
    display: flex;
    column-gap: ${({ theme }) => theme.v2.spacing.xs};
  }
`;

export const DesignInfoBottom = styled.div`
  padding: ${({ theme }) => `${theme.v2.spacing.xs} ${theme.v2.spacing.m} ${theme.v2.spacing.m} ${theme.v2.spacing.m}`};
`;

export const NotesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 6px;
  margin: ${({ theme }) => `${theme.v2.spacing.xs} 0 ${theme.v2.spacing.m} 0`};
`;

export const BottomData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ImageContainer = styled.div`
  position: relative;

  .design-tag {
    position: absolute;
    right: ${({ theme }) => theme.v2.spacing.xs};
    top: ${({ theme }) => theme.v2.spacing.xs};
  }
`;
