import React from 'react';
import * as S from './teams-list-view.styled';
import { ITeam, TeamContentType } from 'types/teams';
import { RPTable } from '@ratedpower/components';
import { ISort } from 'types/filter';
import { useReactTable } from '@tanstack/react-table';
import { getCoreRowModel } from '@tanstack/table-core';
import { t } from 'i18next';
import { teamsColumns } from 'utils/teams';
import { useTeamActions } from '../hooks/use-team-actions';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { useNavigate } from 'react-router-dom';
import { useTeamsPermissions } from '../hooks/use-teams-permissions';

interface IProps {
  teams: ITeam[];
  sortedBy: ISort;
  setSortedBy: (sort: ISort) => void;
}

const TeamsListView: React.FC<IProps> = ({ teams, sortedBy, setSortedBy }) => {
  const navigate = useNavigate();
  const { isAuthorized } = useAuthorization();
  const { handleTeamAction } = useTeamActions();
  const { teamsAllowedDropdownActions } = useTeamsPermissions();

  const dropdownActions = teamsAllowedDropdownActions();

  const teamsTable = useReactTable({
    data: teams,
    columns: teamsColumns(t, {
      isExternalAdmin: isAuthorized(Permission.TEAM_READ_FROM_USER_COMPANY),
      sortedBy,
      dropdownActions: dropdownActions || [],
      setSortedBy,
      handleTeamAction,
    }),
    getCoreRowModel: getCoreRowModel(),
  });

  const goToTeamView = (currentTeam: ITeam) =>
    navigate(`${currentTeam.id}/${TeamContentType.PROJECTS}`, {
      state: {
        teamName: teams.find((team) => team.id === currentTeam.id)?.name,
      },
    });

  if (!teams?.length) return <></>;

  return (
    <S.TableContainer>
      <RPTable table={teamsTable as any} onRowClick={goToTeamView} zeroResultsFoundText={t('common:zero-results-found')} />
    </S.TableContainer>
  );
};

export default TeamsListView;
