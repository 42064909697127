import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, IOption, Separator, Typography } from '@ratedpower/components';
import { ButtonsGroup, CompanyAccountInfoContainer, CompanyProfileSection } from './company-details.styled';
import CompanyEditableInput from './company-editable-input/company-editable-input';
import DetailsInfoHeader from './details-info-header/details-info-header';
import { useCompanyDetails } from './hooks/use-company-details';
import DetailsInfoLogo from './details-info-logo/details-info-logo';
import CompanyCountrySelector from './company-country-selector/company-country-selector';
import { useTheme } from 'styled-components';
import { IndexIds } from 'index.ids';

const CompanyDetails: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const separatorMargin = theme.v2.spacing.m;

  const {
    data,
    inputs,
    handleInputChangeManual,
    countries,
    loading,
    isEditing,
    image,
    cantEditCompanyInfo,
    setImage,
    handleSubmitForm,
    handleEdit,
    handleDiscard,
    loadingUpdateCompany,
  } = useCompanyDetails();

  return (
    <CompanyAccountInfoContainer onSubmit={handleSubmitForm}>
      <DetailsInfoHeader isEditing={isEditing} onEdit={handleEdit} />
      <DetailsInfoLogo isEditing={isEditing} image={image} setImage={setImage} loading={loading} data={data} />
      <Separator marginTop={separatorMargin} marginBottom={separatorMargin} />
      <CompanyProfileSection>
        <CompanyEditableInput
          loading={loading}
          title={t('company')}
          disabled={cantEditCompanyInfo}
          editing={isEditing}
          inputName="name"
          value={inputs.name || data?.name}
          onChange={(value: string) => handleInputChangeManual('name', value)}
          dataTest={IndexIds.MyCompany.Name}
        />
        <CompanyEditableInput
          loading={loading}
          title={t('company:notification-email')}
          disabled={cantEditCompanyInfo}
          editing={isEditing}
          inputName="email"
          value={inputs.email || data?.email}
          onChange={(value: string) => handleInputChangeManual('email', value)}
          dataTest={IndexIds.MyCompany.NotificationEmail}
        />
        <Separator marginTop={separatorMargin} marginBottom={separatorMargin} />
        <Typography size="l">{t('location')}</Typography>
        <CompanyCountrySelector
          loading={loading}
          editing={isEditing}
          countries={countries}
          countrySelected={inputs.country || data?.country?.id}
          onChange={(country: IOption[]) => handleInputChangeManual('country', country[0].value)}
        />
      </CompanyProfileSection>
      {isEditing && (
        <ButtonsGroup>
          <Button variant="ghost" onClick={handleDiscard} text={t('action:discard')} fullWidth />
          <Button variant="accent" loading={loadingUpdateCompany} type="submit" text={t('action:save')} fullWidth />
        </ButtonsGroup>
      )}
    </CompanyAccountInfoContainer>
  );
};

export default CompanyDetails;
