import { Point } from '../ilc-types';

export function distance(p1: Point, p2: Point): number {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  return Math.sqrt(dx * dx + dy * dy);
}

export function getPerpendicularAngle(angleInRadians: number): number {
  return angleInRadians - Math.PI / 2;
}

export function degreesToRadians(degrees: number) {
  return degrees * (Math.PI / 180);
}
