import { t } from 'i18next';

export function getTimeZoneInHours(date: Date): string {
  let response = '';
  const timeZone: number = (date.getTimezoneOffset() * -1) / 60;
  if (Math.sign(timeZone) === 0 || Math.sign(timeZone) === 1) {
    response = `+${getUnits(timeZone)}:00`;
  } else {
    response = `-${getUnits(timeZone * -1)}:00`;
  }

  return response;
}

function getUnits(unit: number): string {
  if (unit.toString().length > 1) return `${unit}`;
  return `0${unit}`;
}

export function addDays(fecha: Date, dias: number) {
  const newDate = new Date(fecha);
  return new Date(newDate.setDate(newDate.getDate() + dias));
}

export function addYears(fecha: Date, years: number) {
  const newDate = new Date(fecha);
  return new Date(newDate.setFullYear(newDate.getFullYear() + years));
}

export function subtractDays(fecha: Date, dias: number) {
  const newDate = new Date(fecha);
  return new Date(newDate.setDate(newDate.getDate() - dias));
}

export function getDaysDiff(date1: Date, date2: Date) {
  const diffMs = Math.abs(date2.getTime() - date1.getTime());

  return Math.floor(diffMs / (1000 * 60 * 60 * 24));
}

export function dateHasExpired(date: Date, daysToExpire: number) {
  return getDaysDiff(date, new Date()) > daysToExpire;
}

export function getDateParse(date: Date): string {
  return date.toISOString().slice(0, date.toISOString().length - 8);
}

export function getDateYYYYMMDD(date: Date | null): string {
  if (!date) return '';
  return date.toISOString().split('T')[0];
}

export function getOnCurrentTimezoneDateYYYYMMDD(date: Date | null): string {
  if (!date) return '';
  return getShortDate(getOnCurrentTimezoneDate(date));
}

export function getOnCurrentTimezoneDate(date: Date | null): string {
  if (!date) return '';
  return toCurrentTimeZone(date).toISOString();
}

function addZero(i: number): string {
  return i < 10 ? `0${i}` : `${i}`;
}

export function getHourAndMinutesHHmm(date: Date | string) {
  const newDate = new Date(date);

  return `${addZero(newDate.getHours())}:${addZero(newDate.getMinutes())}`;
}

export function getDateMonthDDYYYY(date: Date | string) {
  const newDate = new Date(date);
  return `${t(`common:month-${newDate.getMonth() + 1}`)} ${addZero(newDate.getDate())}, ${newDate.getFullYear()}`;
}

export function getDaysBetweenDates(date1: Date, date2: Date) {
  return Math.abs((date1.getTime() - date2.getTime()) / (1000 * 3600 * 24)).toFixed();
}

export function isDateBetweenDates(date: Date, startDate: Date, endDate: Date) {
  return date > toMidnight(startDate) && date < toDayEnd(endDate);
}

export function getDynamicDate(date: Date | string) {
  const newDate = new Date(date);
  const today = new Date();

  return newDate.toLocaleDateString() === today.toLocaleDateString()
    ? getHourAndMinutesHHmm(date)
    : getDateMonthDDYYYY(date);
}

export const getShortDate = (date: string) => date.split('T')[0];

export const toCurrentTimeZone = (date: Date) => {
  const currentTZoffset = new Date().getTimezoneOffset() * 60000;
  return new Date(date.getTime() - currentTZoffset);
};

export const toMidnight = (date: Date): Date => {
  const newDate = new Date(date);
  return new Date(newDate.setHours(0, 0, 0, 0));
};

export const toDayEnd = (date: Date): Date => {
  const newDate = new Date(date);
  return new Date(newDate.setHours(23, 59, 59, 59));
};

export const getDateShortMonthDDYYYY = (date: Date, locale?: string) => {
  if (!isFinite(+date)) return '';
  const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' };
  const formatter = new Intl.DateTimeFormat(locale, options);

  return formatter.format(date);
};

export const getFormattedDateTimeHistoryItem = (date: string): string => {
  const time = getHourAndMinutesHHmm(new Date(date));
  const shortDate = getShortDate(date);

  return `${time} ${shortDate.split('-').reverse().join('/')}`;
};
