import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tabs, Breadcrumb, TopbarWrapper } from '@ratedpower/components';
import { PlusSvg } from 'assets/icons';
import { Helmet } from 'react-helmet';
import { useUsersManagementContext } from '../users-management-data';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-reducer';
import { MAXIMUM_ROLE_HIERARCHY } from 'utils/constants/users';
import { useTheme } from 'styled-components';
import { IndexIds } from 'index.ids';
import { Permission } from 'access-control/permissions';
import { useAuthorization } from 'access-control/can';

interface ITopbarUsersManagement {
  onClickTab: (tabSelected: string) => void;
  activeTab: string;
}

interface ITopbarButton {
  hasPermission: boolean;
  buttonText: string;
  dataTest?: string;
  buttonAction?: () => void;
  dropdownActions?: {
    buttonText: string;
    buttonAction: () => void;
  }[];
}

const TopbarUsersManagement: React.FC<ITopbarUsersManagement> = ({ activeTab, onClickTab }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isAuthorized } = useAuthorization();
  const hasCreateUserPermission = isAuthorized(Permission.USER_WRITE_IN_USER_COMPANY);

  const {
    tabs,
    useUsersObject: { handleNewUserAction, hasWritePermission, users, selectedRoleHierarchy },
  } = useUsersManagementContext();

  const currentUser = useSelector((state: RootState) => state.currentUser.profile);

  const getTopbarButton = (): ITopbarButton => {
    return {
      buttonText: t('user:new-user'),
      buttonAction: handleNewUserAction,
      hasPermission: hasWritePermission,
      dataTest: IndexIds.Users.NewUserButton,
    };
  };

  const { hasPermission, buttonText, buttonAction, dropdownActions, dataTest } = getTopbarButton();

  const isAdmin = currentUser?.roleHierarchy === MAXIMUM_ROLE_HIERARCHY;

  const hasCorrectHierarchyLevelToCreateUser =
    currentUser?.roleHierarchy !== undefined &&
    (isAdmin ||
      selectedRoleHierarchy.current === undefined ||
      selectedRoleHierarchy.current < currentUser?.roleHierarchy);

  return (
    <>
      <Helmet>
        <title>{t('user:users-management')}</title>
      </Helmet>
      <TopbarWrapper
        type="main"
        left={<Breadcrumb currentPage={{ name: t('user:users-management') }} />}
        right={
          hasPermission &&
          hasCreateUserPermission &&
          hasCorrectHierarchyLevelToCreateUser &&
          !dropdownActions?.length && (
            <Button
              disabled={activeTab === 'USERS' && !!users.length && !users[0].id}
              onClick={buttonAction}
              Icon={PlusSvg}
              text={buttonText}
              dataTest={dataTest}
            />
          )
        }
      />
      <TopbarWrapper
        padding={`${theme.v2.spacing.l} ${theme.v2.spacing.l} 0 ${theme.v2.spacing.l}`}
        type="secondary"
        left={
          <Tabs
            onClickTab={onClickTab}
            tabs={tabs.map((tab) => ({ ...tab, label: t(tab.label) }))}
            tabActive={activeTab}
          />
        }
        noBorder
      />
    </>
  );
};

export default TopbarUsersManagement;
