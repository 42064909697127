import { ThreeElements } from '@react-three/fiber';
import React, { useRef } from 'react';
import * as THREE from 'three';
import { Point } from '../../ilc-types';

interface IPolygon {
  yPosition: number;
  type: string;
  innerRings: Point[];
  areaPolygon: Point[];
  color: string;
  userId: string;
  key: string;
  renderOrder: number;
  geometry: ThreeElements['mesh']['geometry'];
}

export const Polygon = (props: IPolygon) => {
  const ref = useRef<any>(null);
  const material = new THREE.MeshBasicMaterial({
    color: props.color,
  });
  material.toneMapped = false;

  return (
    <mesh
      ref={ref}
      {...props}
      rotation={[-Math.PI / 2, 0, 0]}
      renderOrder={props.renderOrder}
      geometry={props.geometry}
      position={[0, props.yPosition ? props.yPosition : 0, 0]}
      material={material}
    />
  );
};

export const PolygonWithoutGeometry = (props: Omit<IPolygon, 'geometry'>) => {
  const shape = new THREE.Shape(props.areaPolygon.map((coord: any) => new THREE.Vector2(coord.x, coord.y)));
  shape.holes =
    props.innerRings && props.innerRings.length > 0
      ? props.innerRings.map(
          (ring: any) => new THREE.Path(ring.map((coord: any) => new THREE.Vector2(coord.x, coord.y)))
        )
      : [];
  const geometry = new THREE.ShapeGeometry(shape, 12);

  return <Polygon {...props} geometry={geometry} />;
};
