import React from 'react';
import { Button, InputText } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import {
  DefaultListItemNotification,
  IListItemNotification,
  IListItemNotificationRecord,
} from 'pages/communication/create-notification/list-items-notification/types/list-item-notification';
import * as Styled from 'pages/communication/create-notification/list-items-notification/list-items-notification.styled';
import { CrossSvg } from 'assets/icons/index';
import { IListItemNotificationCollection } from 'pages/communication/utils/types/create-notification-form.types';
import { IndexIds } from 'index.ids';

interface IProps {
  handleChange: (value: IListItemNotificationRecord) => void;
  notificationSubsections: IListItemNotificationCollection;
}

const ListItemsNotification: React.FC<IProps> = ({ handleChange, notificationSubsections }: IProps) => {
  const { t } = useTranslation();
  const { value: listItems, descriptionValidator } = notificationSubsections;

  const handleItemChange = (key: string, updatedItem: IListItemNotification) => {
    const updatedListItems = {
      ...listItems,
      [key]: updatedItem,
    };

    handleChange(updatedListItems);
  };

  const addNewItem = (): void => {
    const keys = Object.keys(listItems);
    const lastKey = keys.length > 0 ? keys[keys.length - 1] : '0';
    const newKey = (parseInt(lastKey) + 1).toString();

    handleItemChange(newKey, DefaultListItemNotification);
  };

  const removeItem = (key: string): void => {
    if (Object.keys(listItems).length <= 0) return;

    const updatedListItems = { ...listItems };
    delete updatedListItems[key];

    handleChange(updatedListItems);
  };

  return (
    <>
      <Styled.ButtonWrapper>
        <Button
          variant="ghost"
          text={t('communication:add-list-item')}
          onClick={addNewItem}
          dataTest={IndexIds.Communication.CreateNotificationModal.AddListItemButton}
        />
      </Styled.ButtonWrapper>
      {Object.keys(listItems).map((key: string) => {
        const listItem: IListItemNotification = listItems[key];

        return (
          <Styled.ListItemsNotificationWrapper
            key={key}
            data-testid={IndexIds.Communication.CreateNotificationModal.ListItemContainer}
          >
            <Styled.ListItemsNotificationInputWrapper>
              <InputText
                name={key}
                value={listItem.description}
                onChange={(value: string) => handleItemChange(key, { ...listItem, description: value })}
                size="l"
                warning={!descriptionValidator(listItem.description)}
                maxLength={notificationSubsections.maxLength}
                dataTest={IndexIds.Communication.CreateNotificationModal.ListItemInput}
              />
            </Styled.ListItemsNotificationInputWrapper>
            <Styled.ListItemsNotificationIconWrapper>
              <Button
                Icon={CrossSvg}
                onClick={() => removeItem(key)}
                variant="ghost"
                size="m"
                dataTest={IndexIds.Communication.CreateNotificationModal.DeleteListItem}
              />
            </Styled.ListItemsNotificationIconWrapper>
          </Styled.ListItemsNotificationWrapper>
        );
      })}
    </>
  );
};

export default ListItemsNotification;
