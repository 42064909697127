/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo, useState } from 'react';
import * as S from './add-members-modal.styled';
import { Button, Dropdown, IModalProps, IOption, Modal, RatedPowerLoader, RPTable } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { ModalButtons } from '../../teams-tab.styled';
import { ContentTopBar } from 'components/content-top-bar';
import { useListTeamMembers } from '../../hooks/use-list-team-members';
import { useListTeams } from '../../hooks/use-list-teams';
import { ITeam } from 'types/teams';
import { useAddMembersToTeam } from '../../hooks/use-add-members-to-team';
import UsersTabFiltersPanel from 'pages/users-management/users-tab/users-tab-filters-panel/users-tab-filters-panel';
import { IServerFilter } from 'types/filter';
import { ALL_TEAMS_OPTION } from 'utils/constants/users';
import UserTeamsAssignedModal from '../user-teams-assigned-modal';
import { ADD_MEMBER_PAGE_SIZE } from 'utils/teams';
import { IndexIds } from 'index.ids';

interface IProps extends IModalProps {
  currentTeamId: string;
}

const AddMembersModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { currentTeamId, onClose } = props;
  const { table, loading, inputSearchProps, paginationComponent, teamsAssignedModal, setFilterTeamId, setFilters } =
    useListTeamMembers({
      showCurrentTeamMembers: false,
      teamId: props.currentTeamId,
      pageSize: ADD_MEMBER_PAGE_SIZE,
    });
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
  const [filterPanelOpened, setFilterPanelOpened] = useState(false);

  const { addMembersToTeam } = useAddMembersToTeam(currentTeamId, onClose);
  const { teams } = useListTeams({ infiniteScroll: true, pageSize: 50 });

  const onAddMember = () => {
    addMembersToTeam(selectedMemberIds);
  };

  const handleOpenFiltersPanel = (open: boolean) => () => setFilterPanelOpened(open);

  const handleRowClick = (row: any) => {
    if (!selectedMemberIds.includes(row.id)) {
      setSelectedMemberIds((currentMemberIds: string[]) => [...currentMemberIds, row.id]);
    } else {
      const selectedMemberIdx = selectedMemberIds.indexOf(row.id);
      const copyOfSelectedMemberIds = [...selectedMemberIds];
      copyOfSelectedMemberIds.splice(selectedMemberIdx, 1);
      setSelectedMemberIds(copyOfSelectedMemberIds);
    }
  };

  const handleApplyFilters = (filters: IServerFilter[]) => {
    setFilters(filters);
    handleOpenFiltersPanel(false)();
  };

  const teamsOptions: IOption[] = useMemo(() => {
    const mappedTeams = teams
      ?.filter((team: ITeam) => team.id !== currentTeamId)
      .map((team: ITeam) => ({ label: team.name, value: team.id }));
    mappedTeams?.push(ALL_TEAMS_OPTION(t));
    return mappedTeams;
  }, [teams]);

  return (
    <Modal {...props} size="l">
      <S.Container>
        <S.Topbar>
          {teams && (
            <Dropdown
              dataTest={IndexIds.Users.TeamsDropdown}
              options={teamsOptions}
              onChange={(option: IOption[]) => setFilterTeamId(option[0].value)}
              value={teamsOptions}
            />
          )}
          {inputSearchProps && (
            <ContentTopBar
              searchPlaceholder={t('user:teams.search-users')}
              searchOnChange={inputSearchProps?.onChange}
              filterOnClick={handleOpenFiltersPanel(true)}
            />
          )}
        </S.Topbar>

        {table && (
          <S.TableContainer>
            {loading ? (
              <RatedPowerLoader />
            ) : (
              <RPTable
                table={table as any}
                onRowClick={handleRowClick}
                selectedRows={selectedMemberIds}
                zeroResultsFoundText={t('common:zero-results-found')}
              />
            )}
          </S.TableContainer>
        )}

        <S.Pagination>{paginationComponent}</S.Pagination>
        <ModalButtons>
          <Button variant="ghost" text={t('action:cancel')} onClick={onClose} />
          <Button
            variant="accent"
            disabled={!selectedMemberIds.length}
            text={t('user:teams.add-members')}
            dataTest={IndexIds.Users.AddMembersButtonModal}
            onClick={onAddMember}
          />
        </ModalButtons>
        <UsersTabFiltersPanel
          id="add-members-filters"
          active={filterPanelOpened}
          onHide={handleOpenFiltersPanel(false)}
          onApplyFilters={handleApplyFilters}
        />
      </S.Container>
      {teamsAssignedModal.isOpen && (
        <UserTeamsAssignedModal
          title={t('user:teams.teams-assigned')}
          isActive={teamsAssignedModal.isOpen}
          userId={teamsAssignedModal.data}
          onClose={() => teamsAssignedModal.setModalOpen(false)}
        />
      )}
    </Modal>
  );
};

export default AddMembersModal;
