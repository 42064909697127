import React from 'react';
import * as S from './read-only-roles-tab.styled';
import { useUsersManagementContext } from '../users-management-data';
import TopbarReadOnlyRolesTab from './topbar-read-only-roles-tab';
import { usersManagementTabs } from 'utils/constants/users';
import { useNavigate } from 'react-router-dom';
import { Tabs } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import ReadOnlyRolesWrapper from 'pages/users-management/read-only-roles-tab/read-only-roles-wrapper/read-only-roles-wrapper';

const ReadOnlyRolesTab: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tabs } = useUsersManagementContext();

  const navigateToTab = (tab: string) => {
    navigate(`/users-management/${tab.toLowerCase()}`);
  };

  return (
    <>
      <TopbarReadOnlyRolesTab />
      <S.Container>
        <Tabs
          onClickTab={navigateToTab}
          tabs={tabs.map((tab) => ({ ...tab, label: t(tab.label) }))}
          tabActive={usersManagementTabs[2].id}
        />
        <ReadOnlyRolesWrapper />
      </S.Container>
    </>
  );
};

export default ReadOnlyRolesTab;
