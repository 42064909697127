import { DefaultTheme } from 'styled-components';
import { Permission } from 'access-control/permissions';

interface RoleConfig {
  texts?: string[];
  permissions?: Permission[];
}

interface ActionConfig {
  title: string;
  roles: {
    [key in ReadOnlyRoles]?: RoleConfig;
  };
}

interface FeatureConfig {
  title: string;
  actions: {
    [key: string]: ActionConfig;
  };
}

interface ReadOnlyRolesConfig {
  [key: string]: FeatureConfig;
}

export enum ReadOnlyRolesConfigFeature {
  projects = 'projects',
  designs = 'designs',
  teams = 'teams',
  users = 'users',
  equipment = 'equipment',
  capex = 'capex',
  statistics = 'statistics',
  myCompany = 'myCompany',
}

export enum ReadOnlyRoles {
  admin = 'Admin',
  teamManager = 'Team manager',
  user = 'User',
  basicUser = 'Basic user',
  readOnly = 'Read-only',
}

const createGetReadOnlyRolesColors = () => {
  let cachedColors: Record<ReadOnlyRoles, string> | null = null;

  return (theme: DefaultTheme): Record<ReadOnlyRoles, string> => {
    if (!cachedColors) {
      cachedColors = {
        [ReadOnlyRoles.admin]: theme.v2.orange[100],
        [ReadOnlyRoles.teamManager]: theme.v2.accent[100],
        [ReadOnlyRoles.user]: theme.v2.green[100],
        [ReadOnlyRoles.basicUser]: theme.v2.pink[100],
        [ReadOnlyRoles.readOnly]: theme.v2.teal[100],
      };
    }

    return cachedColors;
  };
};

export const getReadOnlyRolesColors = createGetReadOnlyRolesColors();

export const readOnlyRolesConfig: ReadOnlyRolesConfig = {
  [ReadOnlyRolesConfigFeature.projects]: {
    title: 'project:projects',
    actions: {
      view: {
        title: 'user:read-only-roles.view-projects',
        roles: {
          [ReadOnlyRoles.admin]: {
            texts: ['all-teams-and-public'],
            permissions: [Permission.PROJECT_READ_FROM_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {
            texts: ['their-teams-and-public'],
            permissions: [Permission.PROJECT_READ_FROM_USER_TEAM_AND_PUBLIC],
          },
          [ReadOnlyRoles.user]: {
            texts: ['their-teams-and-public'],
            permissions: [Permission.PROJECT_READ_FROM_USER_TEAM_AND_PUBLIC],
          },
          [ReadOnlyRoles.basicUser]: {
            texts: ['their-teams-and-public'],
            permissions: [Permission.PROJECT_READ_FROM_USER_TEAM_AND_PUBLIC],
          },
          [ReadOnlyRoles.readOnly]: {
            texts: ['their-teams-and-public'],
            permissions: [Permission.PROJECT_READ_FROM_USER_TEAM_AND_PUBLIC],
          },
        },
      },
      create: {
        title: 'user:read-only-roles.create-projects',
        roles: {
          [ReadOnlyRoles.admin]: {
            texts: ['all-teams-and-public'],
            permissions: [Permission.PROJECT_CREATE_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {
            texts: ['their-teams-and-public'],
            permissions: [Permission.PROJECT_CREATE_IN_USER_TEAM_AND_PUBLIC],
          },
          [ReadOnlyRoles.user]: {
            texts: ['their-teams-and-public'],
            permissions: [Permission.PROJECT_CREATE_IN_USER_TEAM_AND_PUBLIC],
          },
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      edit: {
        title: 'user:read-only-roles.edit-project-names',
        roles: {
          [ReadOnlyRoles.admin]: {
            texts: ['all-teams-and-public'],
            permissions: [Permission.PROJECT_WRITE_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {
            texts: ['any-teams-and-public'],
            permissions: [Permission.PROJECT_WRITE_IN_USER_TEAM_ALL_AND_PUBLIC_IF_IS_CREATOR],
          },
          [ReadOnlyRoles.user]: {
            texts: ['only-by-them'],
            permissions: [Permission.PROJECT_WRITE_IN_USER_TEAM_AND_PUBLIC_IF_IS_CREATOR],
          },
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      delete: {
        title: 'user:read-only-roles.delete-projects',
        roles: {
          [ReadOnlyRoles.admin]: {
            texts: ['all-teams-and-public'],
            permissions: [Permission.PROJECT_WRITE_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {
            texts: ['any-teams-and-public'],
            permissions: [Permission.PROJECT_WRITE_IN_USER_TEAM_ALL_AND_PUBLIC_IF_IS_CREATOR],
          },
          [ReadOnlyRoles.user]: {
            texts: ['only-by-them'],
            permissions: [Permission.SIMULATION_WRITE_IN_USER_TEAM_AND_PUBLIC_IF_IS_CREATOR],
          },
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
    },
  },
  [ReadOnlyRolesConfigFeature.designs]: {
    title: 'common:designs',
    actions: {
      view: {
        title: 'user:read-only-roles.view-results',
        roles: {
          [ReadOnlyRoles.admin]: {
            permissions: [],
          },
          [ReadOnlyRoles.teamManager]: {
            permissions: [],
          },
          [ReadOnlyRoles.user]: {
            permissions: [],
          },
          [ReadOnlyRoles.basicUser]: {
            permissions: [],
          },
          [ReadOnlyRoles.readOnly]: {
            permissions: [],
          },
        },
      },
      create: {
        title: 'user:read-only-roles.create-designs',
        roles: {
          [ReadOnlyRoles.admin]: {
            texts: ['in-any-projects'],
            permissions: [Permission.SIMULATION_CREATE_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {
            texts: ['their-teams-and-public'],
            permissions: [Permission.SIMULATION_CREATE_IN_USER_TEAM_AND_PUBLIC],
          },
          [ReadOnlyRoles.user]: {
            texts: ['their-teams-and-public'],
            permissions: [Permission.SIMULATION_CREATE_IN_USER_TEAM_AND_PUBLIC],
          },
          [ReadOnlyRoles.basicUser]: {
            texts: ['their-teams-and-public'],
            permissions: [Permission.SIMULATION_CREATE_IN_USER_TEAM_AND_PUBLIC],
          },
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      edit: {
        title: 'user:read-only-roles.edit-designs-names',
        roles: {
          [ReadOnlyRoles.admin]: {
            texts: ['in-any-projects'],
            permissions: [Permission.SIMULATION_WRITE_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {
            texts: ['any-designs-of-their-teams-and-public'],
            permissions: [Permission.SIMULATION_WRITE_IN_USER_TEAM_ALL_AND_PUBLIC_IF_IS_CREATOR],
          },
          [ReadOnlyRoles.user]: {
            texts: ['only-designs-created-by-them'],
            permissions: [Permission.SIMULATION_WRITE_IN_USER_TEAM_AND_PUBLIC_IF_IS_CREATOR],
          },
          [ReadOnlyRoles.basicUser]: {
            texts: ['only-designs-created-by-them'],
            permissions: [Permission.SIMULATION_WRITE_IN_USER_TEAM_AND_PUBLIC_IF_IS_CREATOR],
          },
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      delete: {
        title: 'user:read-only-roles.delete-designs',
        roles: {
          [ReadOnlyRoles.admin]: {
            texts: ['in-any-projects'],
            permissions: [Permission.SIMULATION_WRITE_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {
            texts: ['any-designs-of-their-teams-and-public'],
            permissions: [Permission.SIMULATION_WRITE_IN_USER_TEAM_ALL_AND_PUBLIC_IF_IS_CREATOR],
          },
          [ReadOnlyRoles.user]: {
            texts: ['only-designs-created-by-them'],
            permissions: [Permission.SIMULATION_WRITE_IN_USER_TEAM_AND_PUBLIC_IF_IS_CREATOR],
          },
          [ReadOnlyRoles.basicUser]: {
            texts: ['only-designs-created-by-them'],
            permissions: [Permission.SIMULATION_WRITE_IN_USER_TEAM_AND_PUBLIC_IF_IS_CREATOR],
          },
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      download: {
        title: 'user:read-only-roles.download-documents',
        roles: {
          [ReadOnlyRoles.admin]: {
            permissions: [
              Permission.SIMULATION_READ_FROM_USER_COMPANY,
              Permission.SIMULATION_READ_FROM_USER_TEAM_AND_PUBLIC,
            ],
          },
          [ReadOnlyRoles.teamManager]: {
            permissions: [
              Permission.SIMULATION_READ_FROM_USER_COMPANY,
              Permission.SIMULATION_READ_FROM_USER_TEAM_AND_PUBLIC,
            ],
          },
          [ReadOnlyRoles.user]: {
            permissions: [
              Permission.SIMULATION_READ_FROM_USER_COMPANY,
              Permission.SIMULATION_READ_FROM_USER_TEAM_AND_PUBLIC,
            ],
          },
          [ReadOnlyRoles.basicUser]: {
            permissions: [
              Permission.SIMULATION_READ_FROM_USER_COMPANY,
              Permission.SIMULATION_READ_FROM_USER_TEAM_AND_PUBLIC,
            ],
          },
          [ReadOnlyRoles.readOnly]: {
            permissions: [
              Permission.SIMULATION_READ_FROM_USER_COMPANY,
              Permission.SIMULATION_READ_FROM_USER_TEAM_AND_PUBLIC,
            ],
          },
        },
      },
    },
  },
  [ReadOnlyRolesConfigFeature.teams]: {
    title: 'user:teams.tab-title',
    actions: {
      create: {
        title: 'user:read-only-roles.create-teams',
        roles: {
          [ReadOnlyRoles.admin]: {
            permissions: [Permission.TEAM_WRITE_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {},
          [ReadOnlyRoles.user]: {},
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      addUsers: {
        title: 'user:read-only-roles.add-users-in-a-team',
        roles: {
          [ReadOnlyRoles.admin]: {
            permissions: [Permission.TEAM_WRITE_IN_USER_TEAM, Permission.TEAM_WRITE_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {
            permissions: [Permission.TEAM_WRITE_IN_USER_TEAM, Permission.TEAM_WRITE_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.user]: {},
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      assignProjectToTeam: {
        title: 'user:read-only-roles.assign-existing-projects-to-team',
        roles: {
          [ReadOnlyRoles.admin]: {
            texts: ['to-any-teams'],
            permissions: [Permission.TEAM_WRITE_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {
            texts: ['to-their-teams'],
            permissions: [Permission.TEAM_WRITE_IN_USER_TEAM],
          },
          [ReadOnlyRoles.user]: {},
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      removeProjectsFromATeam: {
        title: 'user:read-only-roles.remove-project-from-team',
        roles: {
          [ReadOnlyRoles.admin]: {
            texts: ['from-any-teams'],
            permissions: [Permission.TEAM_WRITE_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {
            texts: ['from-their-teams'],
            permissions: [Permission.TEAM_WRITE_IN_USER_TEAM],
          },
          [ReadOnlyRoles.user]: {},
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      changeTeamSettings: {
        title: 'user:read-only-roles.change-team-settings',
        roles: {
          [ReadOnlyRoles.admin]: {
            texts: ['to-any-teams'],
            permissions: [Permission.TEAM_WRITE_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {
            texts: ['to-their-teams'],
            permissions: [Permission.TEAM_WRITE_IN_USER_TEAM],
          },
          [ReadOnlyRoles.user]: {},
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      romeUserFromTeam: {
        title: 'user:read-only-roles.remove-user-from-team',
        roles: {
          [ReadOnlyRoles.admin]: {
            texts: ['from-any-teams'],
            permissions: [Permission.TEAM_WRITE_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {
            texts: ['from-their-teams'],
            permissions: [Permission.TEAM_WRITE_IN_USER_TEAM],
          },
          [ReadOnlyRoles.user]: {},
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      deleteTeams: {
        title: 'user:read-only-roles.delete-teams',
        roles: {
          [ReadOnlyRoles.admin]: {
            permissions: [Permission.TEAM_WRITE_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {},
          [ReadOnlyRoles.user]: {},
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
    },
  },
  [ReadOnlyRolesConfigFeature.users]: {
    title: 'user:user-plural',
    actions: {
      viewUsersList: {
        title: 'user:read-only-roles.view-user-lists',
        roles: {
          [ReadOnlyRoles.admin]: {
            permissions: [Permission.USER_READ_FROM_USER_COMPANY, Permission.USER_READ_FROM_USER_TEAM],
          },
          [ReadOnlyRoles.teamManager]: {
            permissions: [Permission.USER_READ_FROM_USER_COMPANY, Permission.USER_READ_FROM_USER_TEAM],
          },
          [ReadOnlyRoles.user]: {},
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      createUsers: {
        title: 'user:read-only-roles.create-users',
        roles: {
          [ReadOnlyRoles.admin]: {
            texts: ['any-users'],
            permissions: [Permission.USER_WRITE_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {},
          [ReadOnlyRoles.user]: {},
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      edit: {
        title: 'user:read-only-roles.edit-users',
        roles: {
          [ReadOnlyRoles.admin]: {
            texts: ['any-teams'],
            permissions: [Permission.USER_WRITE_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {
            texts: ['from-their-teams'],
            permissions: [Permission.USER_WRITE_IN_USER_TEAM],
          },
          [ReadOnlyRoles.user]: {},
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      deactivateUser: {
        title: 'user:read-only-roles.deactivate-users',
        roles: {
          [ReadOnlyRoles.admin]: {
            permissions: [Permission.USER_WRITE_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {},
          [ReadOnlyRoles.user]: {},
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
    },
  },
  [ReadOnlyRolesConfigFeature.equipment]: {
    title: 'common:equipment',
    actions: {
      viewEquipmentTab: {
        title: 'user:read-only-roles.view-equipments',
        roles: {
          [ReadOnlyRoles.admin]: {
            permissions: [Permission.EQUIPMENT_READ],
          },
          [ReadOnlyRoles.teamManager]: {
            permissions: [Permission.EQUIPMENT_READ],
          },
          [ReadOnlyRoles.user]: {
            permissions: [Permission.EQUIPMENT_READ],
          },
          [ReadOnlyRoles.basicUser]: {
            permissions: [Permission.EQUIPMENT_READ],
          },
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      editCompanyLevel: {
        title: 'user:read-only-roles.edit-default-settings-at-company-level',
        roles: {
          [ReadOnlyRoles.admin]: {
            permissions: [Permission.EQUIPMENT_SETTINGS_WRITE_AT_COMPANY_LEVEL],
          },
          [ReadOnlyRoles.teamManager]: {},
          [ReadOnlyRoles.user]: {},
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      editProjectLevel: {
        title: 'user:read-only-roles.default-settings-project-level-edit',
        roles: {
          [ReadOnlyRoles.admin]: {
            permissions: [Permission.EQUIPMENT_SETTINGS_WRITE_AT_PROJECT_LEVEL_IN_USER_COMPANY],
          },
          [ReadOnlyRoles.teamManager]: {
            texts: ['their-teams-and-public-created-by-them'],
            permissions: [
              Permission.EQUIPMENT_SETTINGS_WRITE_AT_PROJECT_LEVEL_IN_USER_TEAM_ALL_AND_PUBLIC_IF_IS_CREATOR,
            ],
          },
          [ReadOnlyRoles.user]: {
            texts: ['only-for-projects-they-created'],
            permissions: [Permission.EQUIPMENT_SETTINGS_WRITE_AT_PROJECT_LEVEL_IN_USER_TEAM_AND_PUBLIC_IF_IS_CREATOR],
          },
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      upload: {
        title: 'user:read-only-roles.upload-equipment',
        roles: {
          [ReadOnlyRoles.admin]: {
            permissions: [Permission.EQUIPMENT_WRITE],
          },
          [ReadOnlyRoles.teamManager]: {
            permissions: [Permission.EQUIPMENT_WRITE],
          },
          [ReadOnlyRoles.user]: {
            permissions: [Permission.EQUIPMENT_WRITE],
          },
          [ReadOnlyRoles.basicUser]: {
            permissions: [Permission.EQUIPMENT_WRITE],
          },
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      download: {
        title: 'user:read-only-roles.download-equipment',
        roles: {
          [ReadOnlyRoles.admin]: {
            permissions: [Permission.EQUIPMENT_READ],
          },
          [ReadOnlyRoles.teamManager]: {
            permissions: [Permission.EQUIPMENT_READ],
          },
          [ReadOnlyRoles.user]: {
            permissions: [Permission.EQUIPMENT_READ],
          },
          [ReadOnlyRoles.basicUser]: {
            permissions: [Permission.EQUIPMENT_READ],
          },
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      disable: {
        title: 'user:read-only-roles.disable-equipment',
        roles: {
          [ReadOnlyRoles.admin]: {
            texts: ['all-the-equipment'],
            permissions: [Permission.EQUIPMENT_DISABLE],
          },
          [ReadOnlyRoles.teamManager]: {
            texts: ['only-the-the-equipment-created'],
            permissions: [Permission.EQUIPMENT_DISABLE_OWN],
          },
          [ReadOnlyRoles.user]: {
            texts: ['only-the-the-equipment-created'],
            permissions: [Permission.EQUIPMENT_DISABLE_OWN],
          },
          [ReadOnlyRoles.basicUser]: {
            texts: ['only-the-the-equipment-created'],
            permissions: [Permission.EQUIPMENT_DISABLE_OWN],
          },
          [ReadOnlyRoles.readOnly]: {},
        },
      },
    },
  },
  [ReadOnlyRolesConfigFeature.capex]: {
    title: 'design:capex-templates',
    actions: {
      view: {
        title: 'user:read-only-roles.view-sheets',
        roles: {
          [ReadOnlyRoles.admin]: {
            permissions: [Permission.FINANCIAL_READ],
          },
          [ReadOnlyRoles.teamManager]: {
            permissions: [Permission.FINANCIAL_READ],
          },
          [ReadOnlyRoles.user]: {
            permissions: [Permission.FINANCIAL_READ],
          },
          [ReadOnlyRoles.basicUser]: {
            permissions: [Permission.FINANCIAL_READ],
          },
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      create: {
        title: 'user:read-only-roles.create-sheets',
        roles: {
          [ReadOnlyRoles.admin]: {
            permissions: [Permission.FINANCIAL_WRITE],
          },
          [ReadOnlyRoles.teamManager]: {
            permissions: [Permission.FINANCIAL_WRITE],
          },
          [ReadOnlyRoles.user]: {
            permissions: [Permission.FINANCIAL_WRITE],
          },
          [ReadOnlyRoles.basicUser]: {
            permissions: [Permission.FINANCIAL_WRITE],
          },
          [ReadOnlyRoles.readOnly]: {},
        },
      },
      edit: {
        title: 'user:read-only-roles.edit-sheets',
        roles: {
          [ReadOnlyRoles.admin]: {
            permissions: [Permission.FINANCIAL_WRITE],
          },
          [ReadOnlyRoles.teamManager]: {
            permissions: [Permission.FINANCIAL_WRITE],
          },
          [ReadOnlyRoles.user]: {
            permissions: [Permission.FINANCIAL_WRITE],
          },
          [ReadOnlyRoles.basicUser]: {
            permissions: [Permission.FINANCIAL_WRITE],
          },
          [ReadOnlyRoles.readOnly]: {},
        },
      },
    },
  },
  [ReadOnlyRolesConfigFeature.statistics]: {
    title: 'statistics:page-name',
    actions: {
      view: {
        title: 'user:roles.READ',
        roles: {
          [ReadOnlyRoles.admin]: {
            permissions: [Permission.CUSTOMER_STATISTICS_READ],
          },
          [ReadOnlyRoles.teamManager]: {},
          [ReadOnlyRoles.user]: {},
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
    },
  },
  [ReadOnlyRolesConfigFeature.myCompany]: {
    title: 'company:my-company',
    actions: {
      view: {
        title: 'user:roles.READ',
        roles: {
          [ReadOnlyRoles.admin]: {
            permissions: [Permission.COMPANY_READ],
          },
          [ReadOnlyRoles.teamManager]: {},
          [ReadOnlyRoles.user]: {},
          [ReadOnlyRoles.basicUser]: {},
          [ReadOnlyRoles.readOnly]: {},
        },
      },
    },
  },
};
