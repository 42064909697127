import { useState } from 'react';
import { IListItemNotificationRecord } from 'pages/communication/create-notification/list-items-notification/types/list-item-notification';
import { ILinkItemNotificationRecord } from 'pages/communication/create-notification/link-items-notification/types/link-items-notification';
import { INotification } from 'types/notifications';
import {
  ICreateNotificationFormField,
  INewNotificationForm,
  INewNotificationFormValueTypes,
} from 'pages/communication/utils/types/create-notification-form.types';

const titleMaxLength = 255;
const descriptionMaxLength = 700;
const notificationSubsectionMaxLength = 255;
const knowLedgeBaseArticleMaxLength = 255;

const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

const urlValidator = (value: string): boolean => {
  return !value || (urlRegex.test(value) && knowledgeBaseArticlesTitleValidator(value));
};

const notificationSubsectionDescriptionValidator = (title: string): boolean => {
  return maxLengthValidator(title, notificationSubsectionMaxLength);
};

const notificationSubsectionsValidator = (subsections: IListItemNotificationRecord): boolean => {
  return !Object.values(subsections).some(
    (subsection) => !notificationSubsectionDescriptionValidator(subsection.description)
  );
};

const knowledgeBaseArticlesTitleValidator = (title: string): boolean => {
  return maxLengthValidator(title, knowLedgeBaseArticleMaxLength);
};

const knowledgeBaseArticlesValidator = (knowledgeBaseArticles: ILinkItemNotificationRecord): boolean => {
  return !Object.values(knowledgeBaseArticles).some(
    (article) => (article.link && !urlValidator(article.link)) || !knowledgeBaseArticlesTitleValidator(article.title)
  );
};

const lengthValidator = (value: string): boolean => {
  return value.length > 0;
};

const maxLengthValidator = (value: string, maxLength: number): boolean => {
  return value.length <= maxLength;
};

const createMaxLengthValidator =
  (maxLength: number) =>
  (value: string): boolean => {
    return lengthValidator(value) && maxLengthValidator(value, maxLength);
  };

const titleValidator = createMaxLengthValidator(titleMaxLength);

const descriptionValidator = createMaxLengthValidator(descriptionMaxLength);

const editableProps = new Set<keyof INewNotificationForm>([
  'title',
  'iconName',
  'description',
  'notificationSubsections',
  'knowledgeBaseArticles',
  'resendUserNotifications',
]);

const getInitialFormData = (notification?: INotification): INewNotificationForm => {
  const initialFormData = {
    title: {
      value: '',
      error: false,
      key: 'title',
      validator: titleValidator,
      maxLength: titleMaxLength,
    },
    iconName: {
      value: '',
      error: false,
      key: 'iconName',
      validator: lengthValidator,
    },
    description: {
      value: '',
      error: false,
      key: 'description',
      validator: descriptionValidator,
      maxLength: descriptionMaxLength,
    },
    notificationSubsections: {
      value: {},
      error: false,
      key: 'notificationSubsections',
      validator: notificationSubsectionsValidator,
      descriptionValidator: notificationSubsectionDescriptionValidator,
      maxLength: notificationSubsectionMaxLength,
    },
    knowledgeBaseArticles: {
      value: {},
      error: false,
      key: 'knowledgeBaseArticles',
      validator: knowledgeBaseArticlesValidator,
      urlValidator,
      knowledgeBaseArticlesTitleValidator,
      maxLength: knowLedgeBaseArticleMaxLength,
    },
    resendUserNotifications: {
      value: false,
      error: false,
      key: 'resendUserNotifications',
      validator: () => true,
    },
  };

  if (notification) {
    Object.keys(notification).forEach((key) => {
      if (editableProps.has(key as keyof INewNotificationForm)) {
        initialFormData[key as keyof INewNotificationForm].value = notification[key as keyof INotification];
      }
    });
  }

  return initialFormData;
};

const useCreateNotificationForm = (notification?: INotification) => {
  const [formData, setFormData] = useState<INewNotificationForm>(getInitialFormData(notification));

  const handleChange = (key: string, value: INewNotificationFormValueTypes): void => {
    setFormData({
      ...formData,
      [key]: {
        ...formData[key],
        value: value,
        error: !formData[key].validator(value),
      },
    });
  };

  const formIsValid = (): boolean => {
    const form: INewNotificationForm = formData;

    let isValid = true;

    Object.keys(form).forEach((key) => {
      const field: ICreateNotificationFormField = form[key as keyof INewNotificationForm];

      if (!field.validator(field.value)) {
        setFormData({
          ...formData,
          [key]: {
            ...formData[key],
            error: true,
          },
        });

        isValid = false;
      }
    });

    return isValid;
  };

  const clearForm = (): void => setFormData(getInitialFormData());

  return { formData, handleChange, formIsValid, clearForm };
};

export default useCreateNotificationForm;
