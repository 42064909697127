import styled from 'styled-components';

export const TopBarWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.v2.spacing.xs};
  justify-content: space-between;
  align-self: center;
  padding: ${({ theme }) => theme.v2.spacing.s} ${({ theme }) => theme.v2.spacing.l};
  background-color: ${({ theme }) => theme.v2.bg.primary.default};
  border-bottom: 1px solid ${({ theme }) => theme.v2.stroke.primary.default};
  width: 100%;
  z-index: 1;
  position: fixed;
`;

export const Flex = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.v2.spacing.xxs};
`;
