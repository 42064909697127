import React, { PropsWithChildren, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_COMPARISON_DESIGNS, TOGGLE_FAVORITE_DESIGN } from 'services/designs-gql';
import { IDesign } from 'types/design';
import useQuerySearchParams from 'utils/hooks/use-query-search-params';
import { useNavigate, useParams } from 'react-router-dom';
import { useConverter } from 'utils/conversor';
import { useChartColors } from 'utils/hooks/use-chart-colors';

interface IStatus {
  loading: boolean;
  error?: any;
}

interface IDesignComparison {
  project: {
    id: string;
    name: string;
    companyId: string;
  };
  designs: IDesign[];
  addDesigns: (newDesigns: IDesign[]) => void;
  removeDesign: (id: string) => void;
  toggleFavorite: (id: string) => void;
  refetch: () => void;
  selectedDesigns: string[];
}

type ContextState = IStatus & IDesignComparison;
const Context = React.createContext<ContextState | null>(null);

export const useDesignComparisonData = (): ContextState => {
  const contextState = React.useContext(Context);
  if (contextState === null) {
    throw new Error('useDesignComparisonData must be used within a DesignComparisonProvider tag');
  }
  return contextState;
};

export const DesignComparisonProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { id: projectId } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { converters } = useConverter();
  const { chartColors } = useChartColors();

  // Get selected design ids
  const querySearchParams = useQuerySearchParams();
  const selectedDesigns = useMemo(() => {
    return querySearchParams.getAll('id') ?? [];
  }, [querySearchParams]);

  // Get designs
  const { data, loading, error, refetch } = useQuery(GET_COMPARISON_DESIGNS, {
    variables: {
      projectId,
      designIds: selectedDesigns,
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  });

  const designs = useMemo<IDesign[]>(() => {
    if (!data || !data.designs || !data.project) return [];
    const validDesigns: IDesign[] = data.designs
      .filter((e: IDesign) => e.simulationResult === 'OK')
      .map((e: IDesign, i: number) => {
        return { ...e, fillColor: chartColors[i], projectTeamId: data.project.teamId };
      });

    return validDesigns;
  }, [data, loading]);

  const findMinValue = (arrayOfValues: any[]) => {
    return Math.min(...arrayOfValues);
  };
  const findMaxValue = (arrayOfValues: any[]) => {
    return Math.max(...arrayOfValues);
  };

  const convertPitchValue = (value: any) => {
    return `${converters?.length.toShow(value, { decimals: 2 }).value}${converters?.length.toShow(value).unit}`;
  };

  const converGCRValue = (value: any) => {
    return `${converters?.percentage.toShow(value).value}${converters?.percentage.toShow(value).unit}`;
  };

  const formattedDesign = (design: IDesign) => {
    if (!design.outputs || !design.inputsPlotAreas) return design;
    const pitchValuesPerArea = design.inputsPlotAreas.map((inputPlotArea: any) => ({
      label: inputPlotArea.codeAreaOk,
      value: convertPitchValue(inputPlotArea.pitchSpecificDistance),
    }));

    const gcrValuesPerArea = design.inputsPlotAreas.map((inputPlotArea: any) => ({
      label: inputPlotArea.codeAreaOk,
      value: converGCRValue(inputPlotArea.gcr),
    }));

    const gcrValuesArray = design.inputsPlotAreas.map((inputPlotArea: any) => inputPlotArea.gcr);
    const pitchValuesArray = design.inputsPlotAreas.map((inputPlotArea: any) => inputPlotArea.pitchSpecificDistance);

    const minValue = findMinValue(pitchValuesArray);
    const maxValue = findMaxValue(pitchValuesArray);

    const gcrMinValue = findMinValue(gcrValuesArray);
    const gcrMaxValue = findMaxValue(gcrValuesArray);

    const gcr = design.inputsSimulation.inputsPerAreaDefinedGlobally
      ? {
          value: converters?.percentage.toShow(design.outputs.gcr).value,
          unit: converters?.percentage.toShow(design.outputs.gcr).unit,
          definedGlobally: true,
        }
      : {
          label: `${converGCRValue(gcrMinValue)} - ${converGCRValue(gcrMaxValue)}`,
          value: gcrValuesPerArea,
        };

    const pitchDistance = design.inputsSimulation.inputsPerAreaDefinedGlobally
      ? {
          value: converters?.length.toShow(design.inputs.pitchDistance, { decimals: 2 }).value,
          unit: converters?.length.toShow(design.inputs.pitchDistance).unit,
          definedGlobally: true,
        }
      : {
          label: `${convertPitchValue(minValue)} - ${convertPitchValue(maxValue)}`,
          value: pitchValuesPerArea,
        };

    return {
      ...design,
      pitchDistanceMinMax: pitchDistance,
      gcr,
    };
  };

  const transformedDesignsData = designs && designs.length > 0 ? designs.map((design) => formattedDesign(design)) : [];
  const project = { id: projectId ?? '', name: data?.project.name || '', companyId: data?.project.company.id || '' };

  // Update design status
  const [toggleFavoriteDesigns] = useMutation(TOGGLE_FAVORITE_DESIGN, { refetchQueries: [GET_COMPARISON_DESIGNS] });

  // Add designs
  const addDesigns = (newDesigns: IDesign[]) => {
    updateLocationState([...newDesigns]);
  };

  // Remove designs
  const removeDesign = async (removedDesignId: string) => {
    if (!removedDesignId) return;
    const updatedDesigns = designs.filter((e) => e.id !== removedDesignId);

    updateLocationState(updatedDesigns);
  };

  // Favorite a design
  const toggleFavorite = async (designId: string) => {
    const updatedDesigns = designs.map((e) => {
      if (e.id === designId) {
        return { ...e, ...{ isFavorite: !e.isFavorite } };
      }
      return e;
    });

    await toggleFavoriteDesigns({ variables: { designId } });
    updateLocationState(updatedDesigns);
  };

  const updateLocationState = (newDesigns: IDesign[]) => {
    const selectedDesignsIds = newDesigns.map((e) => `id=${e.id}`).join('&');
    navigate(
      {
        pathname: location.pathname,
        search: `?${selectedDesignsIds}`,
      },
      { replace: true }
    );
  };

  return (
    <Context.Provider
      value={{
        ...{
          project,
          designs: transformedDesignsData,
          loading,
          error,
          addDesigns,
          removeDesign,
          toggleFavorite,
          refetch,
          selectedDesigns,
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

const designComparisonState = { useDesignComparisonData };

export default designComparisonState;
