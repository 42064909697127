import React, { Fragment, useState } from 'react';
import * as S from './power-stations-kpis-display.styled';
import { useTranslation } from 'react-i18next';
import { DesignKPI } from '../../../ilc-types';
import { ChevronDownSvg, ChevronUpSvg, TargetSvg, DotSvg } from 'assets/icons';
import { StatusSVGWrapper, Typography, SVGWrapper } from '@ratedpower/components';
import { Collapse } from 'react-collapse';
import { KpisData } from '../kpis-data';
import { useGetRectangleElement } from '../../../ilc-store';
import { flyToArea } from '../../../ilc-utils';
import { CameraControls } from '@react-three/drei';

interface IPowerStationsKpisDisplay {
  powerStationsKpis: DesignKPI['powerStationKpis'];
  controls: typeof CameraControls | undefined;
}

export function PowerStationsKpisDisplay({ powerStationsKpis, controls }: IPowerStationsKpisDisplay) {
  const { t } = useTranslation('ilc');
  const [expanded, setExpanded] = useState(false);
  const [expandedPowerStations, setExpandedPowerStations] = useState<string[]>([]);
  const getRectangleElement = useGetRectangleElement();

  const powerStationsKpisToDisplay = Object.values(powerStationsKpis);

  const handleToggleExpandedPowerStation = (psKey: string) => {
    if (expandedPowerStations.includes(psKey)) {
      setExpandedPowerStations(expandedPowerStations.filter((ps) => ps !== psKey));
      return;
    }
    setExpandedPowerStations([...expandedPowerStations, psKey]);
  };

  const handleClickPowerStationTarget = (psKey: string) => {
    const rectangleElement = getRectangleElement(psKey);
    if (!rectangleElement) return;
    flyToArea([rectangleElement.centroid], controls);
  };

  return (
    <S.PowerStationKpisDisplayWrapper>
      <S.TitleHeader>
        <StatusSVGWrapper
          icon={expanded ? ChevronUpSvg : ChevronDownSvg}
          onClick={() => setExpanded(!expanded)}
          size="s"
          active={false}
        />
        <Typography weight="bold">{t('results.list-of-power-stations')}</Typography>
      </S.TitleHeader>
      <Collapse isOpened={expanded}>
        {powerStationsKpisToDisplay.map((psData) => {
          const rectangleElement = getRectangleElement(psData.psKey);
          return (
            <Fragment key={psData.psKey}>
              <S.PowerStationItem>
                <StatusSVGWrapper
                  icon={expandedPowerStations.includes(psData.psKey) ? ChevronUpSvg : ChevronDownSvg}
                  onClick={() => handleToggleExpandedPowerStation(psData.psKey)}
                  size="s"
                  active={false}
                />
                <SVGWrapper size="s" icon={DotSvg} color={rectangleElement?.color} />
                <Typography>{psData.psName}</Typography>
                <StatusSVGWrapper
                  icon={TargetSvg}
                  onClick={() => handleClickPowerStationTarget(psData.psKey)}
                  size="s"
                  active={false}
                />
              </S.PowerStationItem>
              <Collapse isOpened={expandedPowerStations.includes(psData.psKey)}>
                <S.CollapseContent>
                  <KpisData powerKpi={psData.powerKpis} />
                </S.CollapseContent>
              </Collapse>
            </Fragment>
          );
        })}
      </Collapse>
    </S.PowerStationKpisDisplayWrapper>
  );
}
