import React from 'react';
import Can from 'access-control/can';
import { Link } from 'react-router-dom';
import { Separator, SVGWrapper, Tag, Tooltip, Typography } from '@ratedpower/components';
import { Permission } from 'access-control/permissions';
import { ListItem } from './sidebar-list-item.styled';
import { useTranslation } from 'react-i18next';
import { IndexIds } from 'index.ids';

interface IProps {
  perform: Permission | Permission[];
  pathTo: string;
  isPathActive: (path: any) => boolean;
  handleClickItem: (path: any) => void;
  sidebarCollapsed: boolean;
  hasSeparator?: boolean;
  description: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  isNew?: boolean;
  dataTest?: string;
}

const SidebarListItem: React.FC<IProps> = ({
  perform,
  pathTo,
  isPathActive,
  handleClickItem,
  sidebarCollapsed,
  description,
  hasSeparator,
  Icon,
  isNew,
  dataTest,
}) => {
  const { t } = useTranslation();
  const active = isPathActive(pathTo);
  return (
    <Can perform={perform}>
      <Tooltip description={sidebarCollapsed ? description : sidebarCollapsed} className={IndexIds.Homepage.SideBarIds.Icon}>
        <Link to={pathTo}>
          <ListItem
            data-testid={dataTest}
            $active={active}
            onClick={() => handleClickItem(pathTo)}
            $sidebarCollapsed={sidebarCollapsed}
          >
            <SVGWrapper icon={Icon} size="m" />
            {!sidebarCollapsed && (
              <Typography category="display" size="m" weight={active ? 'bold' : 'regular'} color="inherit">
                {description}
              </Typography>
            )}
            {isNew && !sidebarCollapsed && (
              <Tag ellipsis={false} variant="new">
                {t('new')}
              </Tag>
            )}
          </ListItem>
        </Link>
      </Tooltip>
      {hasSeparator && <Separator />}
    </Can>
  );
};

export default SidebarListItem;
