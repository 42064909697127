import React, { useState } from 'react';
import * as S from './read-only-roles-wrapper.styled';
import { Dropdown, RatedPowerLoader } from '@ratedpower/components';
import { IOption } from '@ratedpower/components/dist/types/types';
import { useUsersManagementContext } from 'pages/users-management/users-management-data';
import { useRolesByCurrentUser } from 'utils/hooks/use-roles-by-current-user';
import { useReadOnlyRolesTable } from 'pages/users-management/read-only-roles-tab/hooks/use-read-only-roles-table';
import { ReadOnlyRolesCustom } from 'pages/users-management/read-only-roles-tab/read-only-roles-wrapper/read-only-roles-custom/read-only-roles.custom';
import {
  readOnlyRolesConfig,
  ReadOnlyRolesConfigFeature,
} from 'pages/users-management/read-only-roles-tab/config/read-only-roles-config';
import { useGetExternalRolesWithPermissions } from 'utils/hooks/use-get-external-roles-with-permissions';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data/index';
import { useTranslation } from 'react-i18next';
import HeaderRow from 'pages/users-management/read-only-roles-tab/read-only-roles-wrapper/read-only-roles-custom/header-row/header-row';
import { ReadOnlyRolesRow } from 'pages/users-management/read-only-roles-tab/read-only-roles-wrapper/read-only-roles-custom/read-only-roles-custom.styled';

const ReadOnlyRolesWrapper: React.FC = () => {
  const {
    useUsersObject: { userRoleSummary },
  } = useUsersManagementContext();
  const { rolesByCurrentUser } = useRolesByCurrentUser(false);
  const { t } = useTranslation();
  const { readOnlyRoles } = useReadOnlyRolesTable(userRoleSummary, rolesByCurrentUser);
  const dropdownOptions = Object.keys(readOnlyRolesConfig).map((key) => ({
    label: t(readOnlyRolesConfig[key as ReadOnlyRolesConfigFeature].title),
    value: key,
  }));
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>(dropdownOptions);
  const { data, error, loading } = useGetExternalRolesWithPermissions();

  const handleOnChange = (options: IOption[]): void => setSelectedOptions(options);

  const isSelected = (key: string): boolean => selectedOptions.some((option) => option.value === key);

  if (error) return <ErrorRetrievingData />;

  return loading ? (
    <RatedPowerLoader />
  ) : (
    <S.MainGridStyled>
      <Dropdown
        multipleChoice
        options={dropdownOptions}
        onChange={handleOnChange}
        placeholder={t('user:select-sections')}
        value={selectedOptions}
      />
      <ReadOnlyRolesRow>
        <HeaderRow userRoleSummary={userRoleSummary} />
      </ReadOnlyRolesRow>
      <S.ReadOnlyRolesWContainerStyled>
        <S.ReadOnlyRolesWrapperStyled>
          {Object.entries(readOnlyRolesConfig).map(
            ([key]) =>
              isSelected(key) && (
                <ReadOnlyRolesCustom
                  key={key}
                  feature={key as ReadOnlyRolesConfigFeature}
                  readonlyRoles={readOnlyRoles}
                  readOnlyRolesPermissionsMap={data}
                />
              )
          )}
        </S.ReadOnlyRolesWrapperStyled>
      </S.ReadOnlyRolesWContainerStyled>
    </S.MainGridStyled>
  );
};

export default ReadOnlyRolesWrapper;
