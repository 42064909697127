import React from 'react';
import * as S from './users-management.styled';
import UsersTab from './users-tab/users-tab';
import RolesTab from './roles-tab/roles-tab';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import UsersManagementTeamsTab from './teams-tab/users-management-teams-tab';
import { Route, Routes } from 'react-router-dom';
import GeneralError from 'pages/error/general-error/general-error';
import ReadOnlyRolesTab from 'pages/users-management/read-only-roles-tab/index';

const RolesPages = () => {
  const { isAuthorized } = useAuthorization();
  const isInternalAdmin = isAuthorized(Permission.ROLE_SUPPORT_READ);

  return isInternalAdmin ? <RolesTab /> : <ReadOnlyRolesTab />;
};

const UsersManagement = () => {
  const { isAuthorized } = useAuthorization();

  const hasAccessToTeams =
    isAuthorized(Permission.TEAM_READ_FROM_USER_COMPANY) || isAuthorized(Permission.TEAM_READ_FROM_USER_TEAM);

  return (
    <S.UsersManagementWrapper>
      <Routes>
        <Route path="" element={<UsersTab />} ErrorBoundary={GeneralError} />
        <Route path="users" element={<UsersTab />} ErrorBoundary={GeneralError} />
        <Route path="roles" element={<RolesPages />} ErrorBoundary={GeneralError} />
        {hasAccessToTeams && (
          <Route path="teams/*" element={<UsersManagementTeamsTab />} ErrorBoundary={GeneralError} />
        )}
      </Routes>
    </S.UsersManagementWrapper>
  );
};

export default UsersManagement;
