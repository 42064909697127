import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
  useParams,
} from 'react-router-dom';
import AppLayout from 'layout/app-layout';

import Layout from 'Layout';
import Projects from 'pages/projects/projects';
import AuthRequired from 'layout/auth-required';
import GeneralError from 'pages/error/general-error/general-error';

import ErrorNotFound from 'pages/error/error-not-found/error-not-found';
import Designs from 'pages/designs/designs';
import UsersManagement from 'pages/users-management/users-management';
import { UsersManagementProvider } from 'pages/users-management/users-management-data';

import ActivityTab from 'pages/support/company-activity/activity-tab/activity-tab';
import ProjectsTab from 'pages/support/company-activity/projects-files-tab/projects-files-tab';
import DesignsTab from 'pages/support/company-activity/designs-tab/designs-tab';
import CompanyInfoTab from 'pages/support/company-activity/company-info-tab/company-info-tab';
import UsersTab from 'pages/support/company-activity/users-tab/users-tab';
import TeamsRoutes from 'pages/teams/teams-routes';
import DesignComparisonWrapper from 'pages/design-comparison/design-comparison-wrapper';
import Profile from 'pages/profile/profile';
import Company from 'pages/company/company';
import ProfileForm from 'pages/profile/profile-form';
import LanguageAndMetricsPreferences from 'pages/profile/language-and-metric-preferences';
import PvDesignPlans from 'pages/company/plan/pv-design-plans/pv-design-plans';
import Home from 'pages/home/home';
import Notifications from 'pages/notifications/notifications';
import { paths } from 'components/bars/side-bar/sidebar-list-items';
import InteractiveLayoutCreator from 'pages/interactive-layout-creator/interactive-layout-creator';
import { buildDesignProcessUrl } from 'utils/navigation/navigation';
import { SIMULATION_CATEGORY } from 'types/design';

const DesignResultsRedirect: React.FC = () => {
  const { id, designId } = useParams<{ id: string; designId?: string }>();
  return id && designId ? (
    <Navigate to={buildDesignProcessUrl(id, designId, SIMULATION_CATEGORY.PV, true)} replace />
  ) : (
    <ErrorNotFound />
  );
};

const Router: React.FC = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<AppLayout />}>
        {/* Errors */}
        <Route path="*" element={<ErrorNotFound />} />
        {/* Protected routes */}
        <Route element={<AuthRequired />}>
          <Route element={<Layout />}>
            <Route index element={<Home />} ErrorBoundary={GeneralError} />
            <Route path="notifications" element={<Notifications />} ErrorBoundary={GeneralError} />
            <Route path="projects" element={<Projects />} ErrorBoundary={GeneralError} />
            <Route path="projects/:id" element={<Designs />} ErrorBoundary={GeneralError} />
            <Route
              path="projects/:projectId/interactive-layout-webgl"
              element={<InteractiveLayoutCreator />}
              ErrorBoundary={GeneralError}
            />
            <Route
              path="projects/:id/design-process/:designId?"
              lazy={async () => {
                const { default: NewDesignProcess } = await import('pages/design-process/new-design-process');
                return { Component: NewDesignProcess };
              }}
              ErrorBoundary={GeneralError}
            />
            {/* Old route for design results, redirect to /design-process/:id */}
            <Route path="projects/:id/designs/:designId" element={<DesignResultsRedirect />} />
            <Route
              path="projects/:id/standalone-bess/:designId?"
              lazy={async () => {
                const { default: StandAloneBessDesignProcess } = await import(
                  'pages/standalone-bess/standalone-bess-dp'
                );
                return { Component: StandAloneBessDesignProcess };
              }}
              ErrorBoundary={GeneralError}
            />
            <Route path="projects/:id/comparison" element={<DesignComparisonWrapper />} ErrorBoundary={GeneralError} />
            <Route
              path="equipment"
              lazy={async () => {
                const { default: EquipmentWrapper } = await import('pages/equipment/equipment-wrapper');
                return { Component: EquipmentWrapper };
              }}
              ErrorBoundary={GeneralError}
            />
            <Route path="profile" element={<Profile />} ErrorBoundary={GeneralError}>
              <Route index path="" element={<ProfileForm />} ErrorBoundary={GeneralError} />
              <Route path="preferences" element={<LanguageAndMetricsPreferences />} ErrorBoundary={GeneralError} />
              <Route path="plan" element={<PvDesignPlans isOldCompanyPlan={false} />} />
            </Route>
            <Route
              path="capex"
              lazy={async () => {
                const { default: CapexPageWrapper } = await import('pages/capex/capex-page-wrapper');
                return { Component: CapexPageWrapper };
              }}
              ErrorBoundary={GeneralError}
            />
            <Route
              path="capex/:id?/:action"
              lazy={async () => {
                const { default: CapexEditWrapper } = await import('pages/capex/capex-edit-wrapper/capex-edit-wrapper');
                return { Component: CapexEditWrapper };
              }}
              ErrorBoundary={GeneralError}
            />
            <Route path="my-company" element={<Company />} ErrorBoundary={GeneralError} />
            <Route
              path="users-management/*"
              element={
                <UsersManagementProvider>
                  <UsersManagement />
                </UsersManagementProvider>
              }
              ErrorBoundary={GeneralError}
            />
            <Route
              path="companies-activity"
              lazy={async () => {
                const { default: CompaniesActivity } = await import(
                  'pages/support/companies-activity/companies-activity'
                );
                return { Component: CompaniesActivity };
              }}
              ErrorBoundary={GeneralError}
            ></Route>
            <Route
              path="companies-activity"
              lazy={async () => {
                const { default: CompanyActivity } = await import('pages/support/company-activity/company-activity');
                return { Component: CompanyActivity };
              }}
              ErrorBoundary={GeneralError}
            >
              <Route path=":id" index element={<ActivityTab />} ErrorBoundary={GeneralError} />
              <Route path=":id/activity" element={<ActivityTab />} ErrorBoundary={GeneralError} />
              <Route path=":id/projects-and-files" index element={<ProjectsTab />} ErrorBoundary={GeneralError} />
              <Route path=":id/designs" index element={<DesignsTab />} ErrorBoundary={GeneralError} />
              <Route path=":id/company" index element={<CompanyInfoTab />} ErrorBoundary={GeneralError} />
              <Route path=":id/support-teams/*" index element={<TeamsRoutes />} ErrorBoundary={GeneralError} />
              <Route path=":id/support-users" index element={<UsersTab />} ErrorBoundary={GeneralError} />
            </Route>
            <Route
              path="statistics"
              lazy={async () => {
                const { default: StatisticsWrapper } = await import('pages/statistics/statistics-wrapper');
                return { Component: StatisticsWrapper };
              }}
              ErrorBoundary={GeneralError}
            />
            <Route
              path="customer-statistics"
              lazy={async () => {
                const { default: CustomerStatistics } = await import('pages/customer-statistics');
                return { Component: CustomerStatistics };
              }}
              ErrorBoundary={GeneralError}
            />
            <Route
              path="analytics"
              lazy={async () => {
                const { default: Analytics } = await import('pages/analytics/analytics');
                return { Component: Analytics };
              }}
              ErrorBoundary={GeneralError}
            />
            <Route
              path={paths.communication.substring(1)}
              lazy={async () => {
                const { default: Communication } = await import('pages/communication/communication');
                return { Component: Communication };
              }}
              ErrorBoundary={GeneralError}
            />
          </Route>
        </Route>
      </Route>
    )
  );
  return <RouterProvider router={router} />;
};

export default Router;
