import styled, { css } from 'styled-components';
import { Button } from '@ratedpower/components';
import { zIndex } from 'globalStyles';

export const SideBarContainer = styled.div<{ $hideSidebar: boolean; $collapsed: boolean }>`
  z-index: ${zIndex.sidebar};
  display: flex;
  flex-direction: column;
  transition: width ${({ theme }) => theme.animation_time} ease-out,
    min-width ${({ theme }) => theme.animation_time} ease-out, z-index ${({ theme }) => theme.animation_time} ease-out,
    transform ${({ theme }) => theme.animation_time} ease-out;
  padding: ${({ theme }) => theme.v2.spacing.xs};
  gap: ${({ theme }) => theme.v2.spacing.xs};
  background: ${({ theme }) => theme.v2.bg.primary.default};
  box-shadow: ${({ theme }) => theme.v2.elevation.shadow.m};
  width: ${({ $collapsed }) => ($collapsed ? '56px' : '224px')};
  min-width: ${({ $collapsed }) => ($collapsed ? '56px' : '224px')};
  height: calc(100% - 52px);

  ${({ $hideSidebar, $collapsed }) =>
    $hideSidebar &&
    css`
      position: fixed;
      z-index: 1200;
      height: 100%;
      ${$collapsed &&
      css`
        transform: translateX(-100px);
      `};
    `};
`;

export const SidebarButton = styled(Button)<{ $collapsed: boolean }>`
  align-self: ${({ $collapsed }) => ($collapsed ? 'center' : 'flex-start')};
  margin-top: auto;
`;
