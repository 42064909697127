import React from 'react';
import { useTranslation } from 'react-i18next';
import { COMPANY_TABS as tabs } from 'utils/constants/tabs';
import { Helmet } from 'react-helmet';
import { Breadcrumb, ITab, Tabs, TopbarWrapper } from '@ratedpower/components';
import { BriefcaseSvg, IntegrationSvg, SubscriptionSvg } from 'assets/icons';
import { useTheme } from 'styled-components';
import { IndexIds } from 'index.ids';

interface IProps {
  onTabChange: (tab: string) => void;
  activeTab: any;
}

const TopbarCompany: React.FC<IProps> = ({ onTabChange, activeTab }) => {
  const { t } = useTranslation();
  const pageTabs: ITab[] = [
    {
      id: tabs.account,
      label: t(`company:${tabs.account}`),
      Icon: BriefcaseSvg,
      dataTest: IndexIds.MyCompany.AccountTab,
    },
    {
      id: tabs.plan,
      label: t(`company:${tabs.plan}`),
      Icon: SubscriptionSvg,
      dataTest: IndexIds.MyCompany.PlanTab,
    },
    {
      id: tabs.integrations,
      label: t(`company:${tabs.integrations}`),
      Icon: IntegrationSvg,
      dataTest: IndexIds.MyCompany.IntegrationsTab,
    },
  ];
  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title>{t('company:my-company')}</title>
      </Helmet>

      <TopbarWrapper type="main" left={<Breadcrumb currentPage={{ name: t('company:my-company') }} />} />
      <TopbarWrapper
        padding={theme.v2.spacing.l}
        type="secondary"
        left={<Tabs onClickTab={onTabChange} tabs={pageTabs} tabActive={activeTab} />}
        noBorder
      />
    </>
  );
};

export default TopbarCompany;
