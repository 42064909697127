import styled from 'styled-components';

export const ValidationErrorsDisplayBox = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.xs};
  box-shadow: ${({ theme }) => theme.v2.elevation.shadow.l};
  background-color: ${({ theme }) => theme.v2.bg.secondary.default};
`;

export const TabsWrapper = styled.div`
  a {
    width: 50%;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.v2.stroke.primary.default};
  margin: ${({ theme }) => theme.v2.spacing.xxs} 0;
`;

export const ValidationErrorsDisplayContent = styled.div`
  padding: ${({ theme }) => theme.v2.spacing.xs};
  background-color: ${({ theme }) => theme.v2.bg.primary.default};
`;

export const ValidationsWarningWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.v2.spacing.xs};
`;

export const ValidationErrorsTypes = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.v2.spacing.xs};
  margin-bottom: ${({ theme }) => theme.v2.spacing.xxs};
`;

export const ValidationErrorsList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.v2.spacing.xxs};
`;
