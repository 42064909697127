import i18n from 'i18n';
import { IOption } from 'types/common';
import { ISelectorOptions } from 'types/selector.types';
import { IRoleFilters, IUserListItem, IUsersFilters, USER_TYPE } from 'types/users';
import { ITab } from '@ratedpower/components';
import { IRole, IRoleListItem, ROLE_STATUS, ROLE_TYPE } from 'types/role';
import { t, TFunction } from 'i18next';
import { DefaultTheme } from 'styled-components';
import { PeopleSvg, TeamsSvg, UserRolesSvg } from 'assets/icons';
import { IndexIds } from 'index.ids';

export const LOGIN_REQUIRED = 'Login required';
export const MFA_REQUIRED = 'Multifactor authentication required';
// STATUS
type UserStatus = 'active' | 'disabled' | 'deleted';

export const USER_STATUS: { [key: string]: UserStatus } = {
  ENABLED: 'active',
  DISABLED: 'disabled',
  DELETED: 'deleted',
};

export const getOriginalRolesColors = (theme: DefaultTheme): { [key: string]: string } => ({
  'Root': theme.v2.orange[500],
  'Admin': theme.v2.orange[200],
  'Support': theme.v2.green[500],
  'Read-only': theme.v2.blue[500],
  'Basic user': theme.v2.red[800],
  'Team manager': theme.v2.accent[500],
  'User': theme.v2.green[800],
});

export const getRolesOptions = (roles: IRole[]): IOption[] => {
  return roles?.map((role) => ({
    label: i18n.t([`user:${role.name.toLowerCase()}`, role.name]),
    value: role,
  }));
};

export const USER_TYPES_OPTIONS = (t: TFunction) => [
  {
    label: t('support:CS-manager'),
    value: USER_TYPE.CS_MANAGER,
  },
  {
    label: t('support:BIS-manager'),
    value: USER_TYPE.BIS_MANAGER,
  },
  {
    label: t('support:sdr-manager'),
    value: USER_TYPE.SDR_MANAGER,
  },
  {
    label: t('support:users-tab.STANDARD_USER'),
    value: USER_TYPE.STANDARD_USER,
  },
];

// ACTIONS
export const USER_ROLES_ACTIONS = {
  save: 'save',
  edit: 'edit',
  cancel: 'cancel',
  checkTeamsAssigned: 'checkTeamsAssigned',
  editRowField: 'editRowField',
  disable: 'disable',
  deleteRow: 'deleteRow',
  resendPassword: 'resendPassword',
};

export type RoleHierarchy = 2 | 1 | 0;

export const MAXIMUM_ROLE_HIERARCHY = 2;

export const HIERARCHY_OPTIONS: { label: string; value: RoleHierarchy }[] = [
  { label: '2', value: 2 },
  { label: '1', value: 1 },
  { label: '0', value: 0 },
];

// JOB TITLE
export enum JobTitles {
  HEAD_OF_ENGINEERING = 'HEAD_OF_ENGINEERING',
  EPC_MANAGER = 'EPC_MANAGER',
  DEVELOPMENT_MANAGER = 'DEVELOPMENT_MANAGER',
  ENGINEERING_MANAGER = 'ENGINEERING_MANAGER',
  HEAD_OF_PROJECT_DEVELOPMENT = 'HEAD_OF_PROJECT_DEVELOPMENT',
  TECHNICAL_HEAD_OF_PROJECT = 'TECHNICAL_HEAD_OF_PROJECT',
  HEAD_OF_PROCUREMENT = 'HEAD_OF_PROCUREMENT',
  PROJECT_MANAGER = 'PROJECT_MANAGER',
  DESIGN_MANAGER = 'DESIGN_MANAGER',
  PROJECT_DEVELOPER = 'PROJECT_DEVELOPER',
  SOLAR_PV_ENGINEER = 'SOLAR_PV_ENGINEER',
  TECHNICAL_CIVIL_ELECTRICAL_APPLICATION_ENGINEER = 'TECHNICAL_CIVIL_ELECTRICAL_APPLICATION_ENGINEER',
  STUDENT = 'STUDENT',
  OTHER = 'OTHER',
}

export const usersManagementTabs: ITab[] = [
  {
    id: 'USERS',
    label: 'user:user-plural',
    Icon: PeopleSvg,
    dataTest: IndexIds.Users.Tabs.Users,
  },
  {
    id: 'TEAMS',
    label: 'user:teams.tab-title',
    Icon: TeamsSvg,
    tagNew: true,
    dataTest: IndexIds.Users.Tabs.Teams,
  },
  {
    id: 'ROLES',
    label: 'user:roles-and-permissions',
    Icon: UserRolesSvg,
    dataTest: IndexIds.Users.Tabs.Roles,
  },
];

export const initialUserFilters: IUsersFilters = {
  status: '',
  jobTitles: [],
};

export const initialRoleFilters: IRoleFilters = {
  status: '',
  type: '',
};

export const ROLE_STATUSES: { [key: string]: string } = {
  ENABLED: 'active',
  DISABLED: 'disabled',
};

export const ROLE_TYPE_COLOR: { [key: string]: 'secondary' | 'orange' } = {
  EXTERNAL: 'secondary',
  INTERNAL: 'orange',
};

export const ROLE_USER = 'User';

export const NEW_USER_ROW: IUserListItem = {
  email: `${t('common:new')}@ratedpower.com`,
  name: t('common:new'),
  lastname: t('common:new'),
  jobTitle: 'OTHER',
  status: 'ENABLED',
  editable: true,
  role: {
    id: '',
    permissions: [],
    name: ROLE_USER,
    type: ROLE_TYPE.EXTERNAL,
  },
};

export const NEW_ROLE_ROW: IRoleListItem = {
  name: t('common:new'),
  hierarchyLevel: 0,
  editable: true,
  description: t('common:new'),
  status: ROLE_STATUS.DISABLED,
  permissions: [],
  type: ROLE_TYPE.EXTERNAL,
};

export const ALL_TEAMS_OPTION = (tFunction: TFunction): IOption => ({
  label: tFunction('user:teams.all-teams'),
  value: null,
});
export const OTHER_TEAMS_OPTION = (tFunction: TFunction): IOption => ({
  label: tFunction('user:teams.other-teams'),
  value: 'Other teams',
});
export const PUBLIC_TEAMS_OPTION = (tFunction: TFunction): IOption => ({
  label: tFunction('user:teams.public-teams'),
  value: 'Public',
});

export const RepeatedEmailError = 'DataIntegrityViolationException';
export const RepeatedRoleError = 'DataIntegrityViolationException';

let jobTitleOptions: ISelectorOptions[] = [];

function translateJobTitles() {
  jobTitleOptions = Object.keys(JobTitles).map((jobTitle) => ({
    label: i18n.t(`user:jobTitle.${jobTitle}`),
    value: jobTitle,
  }));
}

translateJobTitles();

i18n.on('languageChanged', () => translateJobTitles());

export { jobTitleOptions };
