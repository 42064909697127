import { TFunction } from 'i18next';
import { IOption } from 'types/common';
import { IComparisonFilterGroup } from 'types/design-comparison.types';
import { designAttributes } from './design-attributes';
import { dateHasExpired } from 'utils/date';

export const COMPARISON_DESIGNS_LIMIT = 24;
const daysToExpire = 20;

export const DesignComparisonTableFilters: IComparisonFilterGroup[] = [
  {
    className: 'no-margin',
    filters: [
      {
        ...designAttributes.satellitePicture.low,
        ...{ shape: 'rect', size: 'large', style: { textTransform: 'uppercase' } },
      },
    ],
  },
  {
    label: 'design:power',
    filters: [designAttributes.power.ratedPower, designAttributes.power.peakPower, designAttributes.power.ratioDcAc],
  },
  {
    label: 'design:energy',
    filters: [
      designAttributes.energy.specificProduction,
      designAttributes.energy.eneryYield,
      designAttributes.energy.plantPR,
    ],
  },
  {
    label: 'design-process:location-tab.site',
    filters: [designAttributes.site.availableArea, designAttributes.site.suitableArea, designAttributes.site.fenceArea],
  },
  {
    label: 'design:meteo',
    filters: [
      designAttributes.meteo.source,
      designAttributes.meteo.yearGhi,
      designAttributes.meteo.yearBhi,
      designAttributes.meteo.yearDhi,
      designAttributes.meteo.avgTemp,
      designAttributes.meteo.minTemp,
      designAttributes.meteo.maxTemp,
    ],
  },
  {
    label: 'equipment',
    filters: [
      designAttributes.equipment.moduleName,
      designAttributes.equipment.modulePower,
      designAttributes.equipment.moduleType,
      designAttributes.equipment.inverterName,
      designAttributes.equipment.invertersPower,
      designAttributes.equipment.structureName,
      designAttributes.equipment.structureType,
      designAttributes.equipment.structureConfig,
      designAttributes.equipment.lvConfig,
      designAttributes.equipment.modulesPerString,
      designAttributes.equipment.minGroundClearance,
      designAttributes.equipment.tiltAngle,
      designAttributes.equipment.pitchDistance,
      designAttributes.equipment.gcr,
    ],
  },
  {
    label: 'design:financial',
    new: !dateHasExpired(new Date('2024-6-26'), daysToExpire),
    filters: [
      designAttributes.financial.totalPrice,
      designAttributes.financial.specificPrice,
      designAttributes.financial.lcoe,
      designAttributes.financial.npv,
      designAttributes.financial.irr,
      designAttributes.financial.payback,
      designAttributes.financial.roi,
      designAttributes.financial.discountedPayback,
    ],
  },
  {
    label: 'design:others',
    filters: [designAttributes.notes, designAttributes.simulationType],
  },
];

export const defaultDesignComparisonAttributes = [
  ...[designAttributes.satellitePicture.low],
  ...[designAttributes.simulationType],
  ...DesignComparisonTableFilters[1].filters,
  ...[designAttributes.energy.specificProduction, designAttributes.energy.eneryYield],
  ...[designAttributes.equipment.moduleName, designAttributes.equipment.inverterName, designAttributes.equipment.gcr],
  ...[designAttributes.financial.totalPrice, designAttributes.financial.lcoe],
  ...[designAttributes.notes],
];

export const DESIGN_COMPARISON_CHARTS = {
  peakPowerVsGCR: 'comparison-charts.peak-power-vs-gcr',
  energyVsGCR: 'comparison-charts.energy-vs-gcr',
  irrVsDCACRatio: 'comparison-charts.irr-vs-dc-ac-ratio',
  monthlyEnergy: 'comparison-charts.monthly-energy',
  npvVsDCACRatio: 'comparison-charts.npv-vs-dc-ac-ratio',
  paybackVsTiltAngles: 'comparison-charts.payback-vs-tilt-angles',
  roiVsGCR: 'comparison-charts.roi-vs-gcr',
  specificProductionVsDcAcRatio: 'comparison-charts.specific-production-vs-dcac-ratio',
  capexVsDesign: 'comparison-charts.capex-design',
  lcoeVsDesign: 'comparison-charts.lcoe-design',
  npvVsGCR: 'comparison-charts.npv-vs-gcr',
  npvVsTiltAngle: 'comparison-charts.npv-vs-tilt-angle',
  irrVsGCR: 'comparison-charts.irr-vs-gcr',
  irrVsTiltAngle: 'comparison-charts.irr-vs-tilt-angle',
  paybackVsDCACRatio: 'comparison-charts.payback-vs-dc-ac-ratio',
  paybackVsGCR: 'comparison-charts.payback-vs-gcr',
  discountedPaybackVsDCACRatio: 'comparison-charts.discounted-payback-vs-dc-ac-ratio',
  discountedPaybackVsGCR: 'comparison-charts.discounted-payback-vs-gcr',
  discountedPaybackVsTiltAngle: 'comparison-charts.discounted-payback-vs-tilt-angle',
  roiVsDCACRatio: 'comparison-charts.roi-vs-dc-ac-ratio',
  roiVsTiltAngle: 'comparison-charts.roi-vs-tilt-angle',
  lcoeVsDCACRatio: 'comparison-charts.lcoe-vs-dc-ac-ratio',
  lcoeVsGCR: 'comparison-charts.lcoe-vs-gcr',
  lcoeVsTiltAngle: 'comparison-charts.lcoe-vs-tilt-angle',
};

export const DesignComparisonChartFilters: IComparisonFilterGroup[] = [
  {
    label: 'layout',
    filters: [
      { key: DESIGN_COMPARISON_CHARTS.peakPowerVsGCR, label: DESIGN_COMPARISON_CHARTS.peakPowerVsGCR },
      { key: DESIGN_COMPARISON_CHARTS.energyVsGCR, label: DESIGN_COMPARISON_CHARTS.energyVsGCR },
      { key: DESIGN_COMPARISON_CHARTS.monthlyEnergy, label: DESIGN_COMPARISON_CHARTS.monthlyEnergy },
      {
        key: DESIGN_COMPARISON_CHARTS.specificProductionVsDcAcRatio,
        label: DESIGN_COMPARISON_CHARTS.specificProductionVsDcAcRatio,
      },
    ],
  },
  {
    label: 'design:net-present-value',
    new: !dateHasExpired(new Date('2024-6-26'), daysToExpire),
    filters: [
      { key: DESIGN_COMPARISON_CHARTS.npvVsGCR, label: DESIGN_COMPARISON_CHARTS.npvVsGCR },
      { key: DESIGN_COMPARISON_CHARTS.npvVsTiltAngle, label: DESIGN_COMPARISON_CHARTS.npvVsTiltAngle },
      { key: DESIGN_COMPARISON_CHARTS.npvVsDCACRatio, label: DESIGN_COMPARISON_CHARTS.npvVsDCACRatio },
    ],
  },
  {
    label: 'design:internal-rate-return',
    new: !dateHasExpired(new Date('2024-6-26'), daysToExpire),
    filters: [
      { key: DESIGN_COMPARISON_CHARTS.irrVsGCR, label: DESIGN_COMPARISON_CHARTS.irrVsGCR },
      { key: DESIGN_COMPARISON_CHARTS.irrVsTiltAngle, label: DESIGN_COMPARISON_CHARTS.irrVsTiltAngle },
      { key: DESIGN_COMPARISON_CHARTS.irrVsDCACRatio, label: DESIGN_COMPARISON_CHARTS.irrVsDCACRatio },
    ],
  },
  {
    label: 'design:payback',
    new: !dateHasExpired(new Date('2024-6-26'), daysToExpire),
    filters: [
      { key: DESIGN_COMPARISON_CHARTS.paybackVsDCACRatio, label: DESIGN_COMPARISON_CHARTS.paybackVsDCACRatio },
      { key: DESIGN_COMPARISON_CHARTS.paybackVsGCR, label: DESIGN_COMPARISON_CHARTS.paybackVsGCR },
      { key: DESIGN_COMPARISON_CHARTS.paybackVsTiltAngles, label: DESIGN_COMPARISON_CHARTS.paybackVsTiltAngles },
    ],
  },
  {
    label: 'design:discountedPayback',
    new: !dateHasExpired(new Date('2024-6-26'), daysToExpire),
    filters: [
      {
        key: DESIGN_COMPARISON_CHARTS.discountedPaybackVsDCACRatio,
        label: DESIGN_COMPARISON_CHARTS.discountedPaybackVsDCACRatio,
      },
      { key: DESIGN_COMPARISON_CHARTS.discountedPaybackVsGCR, label: DESIGN_COMPARISON_CHARTS.discountedPaybackVsGCR },
      {
        key: DESIGN_COMPARISON_CHARTS.discountedPaybackVsTiltAngle,
        label: DESIGN_COMPARISON_CHARTS.discountedPaybackVsTiltAngle,
      },
    ],
  },
  {
    label: 'design:return-of-investment',
    new: !dateHasExpired(new Date('2024-6-26'), daysToExpire),
    filters: [
      { key: DESIGN_COMPARISON_CHARTS.roiVsGCR, label: DESIGN_COMPARISON_CHARTS.roiVsGCR },
      { key: DESIGN_COMPARISON_CHARTS.roiVsDCACRatio, label: DESIGN_COMPARISON_CHARTS.roiVsDCACRatio },
      { key: DESIGN_COMPARISON_CHARTS.roiVsTiltAngle, label: DESIGN_COMPARISON_CHARTS.roiVsTiltAngle },
    ],
  },
  {
    label: 'design:lcoe-capex',
    new: !dateHasExpired(new Date('2024-6-26'), daysToExpire),
    filters: [
      { key: DESIGN_COMPARISON_CHARTS.capexVsDesign, label: DESIGN_COMPARISON_CHARTS.capexVsDesign },
      { key: DESIGN_COMPARISON_CHARTS.lcoeVsDesign, label: DESIGN_COMPARISON_CHARTS.lcoeVsDesign },
      { key: DESIGN_COMPARISON_CHARTS.lcoeVsDCACRatio, label: DESIGN_COMPARISON_CHARTS.lcoeVsDCACRatio },
      { key: DESIGN_COMPARISON_CHARTS.lcoeVsGCR, label: DESIGN_COMPARISON_CHARTS.lcoeVsGCR },
      { key: DESIGN_COMPARISON_CHARTS.lcoeVsTiltAngle, label: DESIGN_COMPARISON_CHARTS.lcoeVsTiltAngle },
    ],
  },
];

export const defaultChartFilters: IComparisonFilterGroup[] = [
  {
    label: 'chartFilters',
    filters: [
      { key: DESIGN_COMPARISON_CHARTS.peakPowerVsGCR, label: DESIGN_COMPARISON_CHARTS.peakPowerVsGCR },
      { key: DESIGN_COMPARISON_CHARTS.energyVsGCR, label: DESIGN_COMPARISON_CHARTS.energyVsGCR },
      { key: DESIGN_COMPARISON_CHARTS.monthlyEnergy, label: DESIGN_COMPARISON_CHARTS.monthlyEnergy },
      {
        key: DESIGN_COMPARISON_CHARTS.specificProductionVsDcAcRatio,
        label: DESIGN_COMPARISON_CHARTS.specificProductionVsDcAcRatio,
      },
    ],
  },
];

export const filterOptions = (t: TFunction): IOption[] => [
  {
    label: t('favorites'),
    value: 'favorites',
  },
  {
    label: t('archived'),
    value: 'archived',
  },
];

export const typeFilterOptions = (t: TFunction): IOption[] => [
  {
    label: t('design-process:batch.single-designs'),
    value: 'single',
  },
  {
    label: t('design-process:batch.multiple-pv-designs'),
    value: 'batch',
  },
];
