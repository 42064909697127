import React from 'react';
import { useThree } from '@react-three/fiber';
import { CSSProperties, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useEvent } from 'react-use';
import { Vector2 } from 'three';
import { SelectionBox } from './class/SelectionBox';

const getCoords = (clientX: number, clientY: number) => ({
  x: (clientX / window.innerWidth) * 2 - 1,
  y: -(clientY / window.innerHeight) * 2 + 1,
});

interface SelectionProps {
  onSelectionChanged?(objects: any[]): void;
  style?: CSSProperties;
}

export const Selection: FC<SelectionProps> = ({ style, onSelectionChanged }) => {
  const { camera, scene, gl } = useThree();
  const [start, setStart] = useState<any>();
  const [mouse, setMouse] = useState<[number, number]>();
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const selectRectangle = useRef(document.createElement('div'));

  useEffect(() => {
    selectRectangle.current.classList.add('selectBox');
    selectRectangle.current.style.pointerEvents = 'none';
    for (const key in style) {
      const val = (style as any)[key];
      selectRectangle.current.style.setProperty(
        key
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .replace(/[\s_]+/g, '-')
          .toLowerCase(),
        val
      );
    }
  }, [selectRectangle, style]);

  useEffect(() => {
    if (isSelecting && start && mouse) {
      gl.domElement.parentElement?.append(selectRectangle.current);

      const topLeft = {
        x: Math.min(start.x, mouse[0]),
        y: Math.min(start.y, mouse[1]),
      };
      const bottomRight = {
        x: Math.max(start.x, mouse[0]),
        y: Math.max(start.y, mouse[1]),
      };

      selectRectangle.current.style.left = `${topLeft.x}px`;
      selectRectangle.current.style.top = `${topLeft.y}px`;
      selectRectangle.current.style.width = `${bottomRight.x - topLeft.x}px`;
      selectRectangle.current.style.height = `${bottomRight.y - topLeft.y}px`;
    } else {
      selectRectangle.current.parentElement?.removeChild(selectRectangle.current);
    }
  }, [isSelecting, gl, start, mouse, selectRectangle]);

  const selectionBox = useMemo(() => new SelectionBox(camera, scene), [scene, camera]);

  const onPointerDown = useCallback((e: Event) => {
    const event = e as PointerEvent;
    const { clientX, clientY, altKey, shiftKey } = event;
    if (shiftKey) {
      if (!altKey && !isSelecting) {
        const { x: startX, y: startY } = getCoords(clientX, clientY);
        setStart(new Vector2(clientX, clientY));
        setIsSelecting(true);

        selectionBox.startPoint.set(startX, startY, 0);
        selectionBox.endPoint.set(startX, startY, 0);
      }
    }
  }, []);

  const onPointerMove = useCallback(
    (e: Event) => {
      if (!isSelecting) return;
      const { clientX, clientY } = e as PointerEvent;
      const { x: endX, y: endY } = getCoords(clientX, clientY);
      setMouse([clientX, clientY]);

      selectionBox.endPoint.set(endX, endY, 0.5);
    },
    [isSelecting]
  );

  const onPointerUp = useCallback(
    (e: Event) => {
      const { ctrlKey, clientX, clientY } = e as PointerEvent;
      if (isSelecting) {
        setIsSelecting(false);
        const { x: endX, y: endY } = getCoords(clientX, clientY);
        const { x: startX, y: startY } = getCoords(start.x, start.y);
        selectionBox.startPoint.set(startX, startY, 0);
        selectionBox.endPoint.set(endX, endY, 0);
        selectionBox.select();
        setMouse(undefined);
        setStart(undefined);
        if (ctrlKey) {
          // appendSelection(curSelected);
        } else {
          if (onSelectionChanged) {
            onSelectionChanged(selectionBox.instanceDetection as any);
          }
        }
      }
    },
    [isSelecting]
  );

  useEvent('pointerdown', onPointerDown, gl.domElement);
  useEvent('pointermove', onPointerMove, gl.domElement);
  useEvent('pointerup', onPointerUp, gl.domElement);

  return <></>;
};
