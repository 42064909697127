import React from 'react';
import { Checkbox, StatusSVGWrapper, Typography } from '@ratedpower/components';
import { TargetSvg } from 'assets/icons';
import * as S from './error-item.styled';
import { IlcValidationError } from '../../../ilc-types';

interface IErrorItem {
  error: IlcValidationError;
  index: number;
  type: IlcValidationError['type'];
  selectedRectanglesIds: string[];
  handleCheckboxChange: (errorIds: string[], type: IlcValidationError['type']) => void;
  handleClickItemTarget: (e: React.MouseEvent, error: IlcValidationError) => void;
}
const ErrorItem = ({
  error,
  index,
  type,
  selectedRectanglesIds,
  handleCheckboxChange,
  handleClickItemTarget,
}: IErrorItem) => {
  return (
    <S.SingleErrorItem
      key={error.id}
      onClick={() => {
        handleCheckboxChange([error.id], type);
      }}
    >
      {type !== 'ROADS' && <Checkbox checked={selectedRectanglesIds.includes(error.id)} onChange={() => void 0} />}
      <Typography size="s">{`${error.result}-${index + 1}`}</Typography>
      {type !== 'ROADS' && (
        <StatusSVGWrapper icon={TargetSvg} onClick={(e) => handleClickItemTarget(e, error)} size="s" active={false} />
      )}
    </S.SingleErrorItem>
  );
};

export { ErrorItem };
