import React, { useMemo } from 'react';
import * as S from './teams-selector.styled';
import { useAuthorization } from 'access-control/can';
import { Permission } from 'access-control/permissions';
import { ITeam } from 'types/teams';
import { ALL_TEAMS_OPTION, OTHER_TEAMS_OPTION, PUBLIC_TEAMS_OPTION } from 'utils/constants/users';
import { useTranslation } from 'react-i18next';
import { IOption } from 'types/common';
import { Dropdown } from '@ratedpower/components';

export interface IProps {
  teams: ITeam[];
  onChangeTeam: (teamId: string) => void;
  padding?: string;
  addPublicOption?: boolean;
  addOthersOption?: boolean;
  teamSelected?: string | null;
}

const TeamsSelector: React.FC<IProps> = ({
  teams,
  onChangeTeam,
  padding,
  addOthersOption,
  addPublicOption,
  teamSelected,
}) => {
  const { t } = useTranslation();
  const { isAuthorized } = useAuthorization();

  const currentTeams = teams ? teams.map((team: ITeam) => ({ label: team.name, value: team.id })) : [];

  const teamsOptions = useMemo(
    () => [
      ALL_TEAMS_OPTION(t),
      ...(addOthersOption && isAuthorized(Permission.TEAM_READ_FROM_USER_TEAM) ? [OTHER_TEAMS_OPTION(t)] : []),
      ...(addPublicOption ? [PUBLIC_TEAMS_OPTION(t)] : []),
      ...currentTeams,
    ],
    [currentTeams, addOthersOption, addPublicOption]
  );

  const companyHasTeams = !!teams?.length;

  const handleChangeTeam = (optionSelected: IOption[]) => {
    onChangeTeam(optionSelected[0].value);
  };
  const selectedOption = teamsOptions.find((option) => option.value === teamSelected) ?? ALL_TEAMS_OPTION(t);

  return (
    <S.TeamsContainer $visible={companyHasTeams} $padding={padding}>
      <Dropdown options={teamsOptions} onChange={handleChangeTeam} value={[selectedOption]} isSearchable />
    </S.TeamsContainer>
  );
};

export default TeamsSelector;
