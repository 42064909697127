import { useCustomQuery } from 'utils/hooks/use-custom-query';
import { useEffect, useMemo } from 'react';
import { GET_COMBINED_PROJECT_DESIGNS, GET_COMBINED_PROJECT_DESIGNS_BY_USER } from 'services/designs-gql';
import { IBessDesign, IDesign } from 'types/design';
import { useDesignsActions } from 'pages/designs/use-designs-actions';
import { useApolloClient } from '@apollo/client';
import { GET_LAST_PROJECTS_WITH_FAVOURITES_DESIGNS_BY_USER } from 'services/projects-gql';
import { designCardDimensions } from 'pages/home/home-designs/home-project-item/home-projects-designs/home-design-item-content';
import { combineSimulations, combineSimulationsByUser } from 'services/transform-services/map-designs-gql';

export const useHomeProjectsDesigns = (projectId: string, isFavorite: boolean, containerWidth: number | null) => {
  const numCards = containerWidth ? Math.floor(containerWidth / designCardDimensions.width) : -1;
  const query = isFavorite ? GET_COMBINED_PROJECT_DESIGNS : GET_COMBINED_PROJECT_DESIGNS_BY_USER;

  const {
    data: simulations,
    loading,
    error,
    paginationComponent,
    refetch,
  } = useCustomQuery(
    query,
    {
      variables: {
        pagination: {
          page: 0,
          size: numCards,
        },
        projectId,
        isFavorite: isFavorite,
        sorting: [
          {
            property: 'number',
            direction: 'DESC',
          },
        ],
      },
      skip: numCards === -1,
      fetchPolicy: 'network-only',
    },
    false,
    true
  );

  const apolloClient = useApolloClient();
  const combinedSimulationsData = useMemo(() => {
    return isFavorite ? combineSimulations(simulations) : combineSimulationsByUser(simulations);
  }, [query, simulations]);

  useEffect(() => {
    if (
      combinedSimulationsData?.result?.content?.length === 0 &&
      combinedSimulationsData?.result?.pageInfo?.pageNumber === 0 &&
      isFavorite
    ) {
      apolloClient.refetchQueries({
        include: [GET_LAST_PROJECTS_WITH_FAVOURITES_DESIGNS_BY_USER],
      });
    }
  }, [combinedSimulationsData]);

  const designActions = useDesignsActions(refetch, [query], projectId);

  const designs = useMemo(() => {
    if (combinedSimulationsData?.result) {
      const newData: IDesign[] | IBessDesign = combinedSimulationsData.result.content
        .filter((item: any) => !!item)
        .map((item: any) => {
          return { ...item, active: false };
        });
      return newData;
    } else {
      return [];
    }
  }, [combinedSimulationsData]);

  return {
    data: designs,
    loading,
    error,
    paginationComponent,
    designActions,
  };
};
