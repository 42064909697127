import React from 'react';
import { Circle } from './nodes-without-refs';
import { getMidpoints } from '../ilc-utils';
import { Point } from '../ilc-types';
import { elevation } from './config';

interface IReferencePointsProps {
  userCoords: Array<Point>;
  center: { x: number; y: number };
  onClick: (coords: { x: number; y: number }, event?: any) => void;
  showReferencePoints: boolean;
  showMidPoints: boolean;
  selectedReferencePoint: { x: number; y: number } | null;
  color: string;
}

const ReferencePoints: React.FC<IReferencePointsProps> = ({
  userCoords,
  onClick,
  showReferencePoints,
  showMidPoints,
  selectedReferencePoint,
  color,
}) => {
  if (!userCoords) return null;

  const midpoints = getMidpoints(userCoords);

  const isPointSelected = (point: { x: number; y: number }) => {
    if (!selectedReferencePoint) return;
    return selectedReferencePoint.x === point.x && selectedReferencePoint.y === point.y;
  };

  return (
    <>
      {showReferencePoints &&
        userCoords.map((coord: any) => (
          <Circle
            key={`${coord.x}-${coord.y}`}
            onClick={(event) => onClick({ x: coord.x, y: coord.y }, event)}
            radius={0.4}
            position={[coord.x, elevation.referencePoints, -coord.y]}
            color={isPointSelected(coord) ? 'red' : color}
          />
        ))}
      {showMidPoints &&
        midpoints.map((coord: any) => (
          <Circle
            onClick={(event) => onClick({ x: coord.x, y: coord.y }, event)}
            key={`${coord.x}-${coord.y}`}
            radius={0.6}
            position={[coord.x, elevation.referencePoints, -coord.y]}
            color={isPointSelected(coord) ? 'red' : color}
          />
        ))}
    </>
  );
};

export default ReferencePoints;
