import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 115px;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.v2.spacing.s};
  padding-top: ${({ theme }) => theme.v2.spacing.xs};
  box-shadow: ${({ theme }) => theme.v2.elevation.shadow.l};
  width: 50px;
  height: 100%;
  background-color: ${({ theme }) => theme.v2.bg.primary.default};
`;
