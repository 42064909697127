import JSZip from 'jszip';
import { getApiUrl } from 'utils/api_url';
import { AxiosRequestConfig } from 'axios';

export const fetchSite = async (
  siteId: string,
  get: (uri: string, options?: AxiosRequestConfig<any> | undefined) => Promise<any>
) => {
  const url = `${getApiUrl()}/sites/${siteId}/geojson`;
  const { response, error } = await get(url);
  if (error) {
    console.error(error);
    return;
  }
  if (response && !error) {
    return response.data;
  }
};

export const fetchTopography = async (
  siteId: string,
  topographyId: string,
  get: (uri: string, options?: AxiosRequestConfig<any> | undefined) => Promise<any>
) => {
  const urltopo = `${getApiUrl()}/elevations/${topographyId}/slopes-geojson`;
  const { response, error } = await get(urltopo);

  const siteResponse = await fetchSite(siteId, get);

  if (error) {
    console.error(error);
    return;
  }
  if (response && !error) {
    return {
      layers: [...siteResponse.layers, ...response.data.layers],
      bbox: response.data.bbox,
    };
  }
};

export const fetchLayoutData = async (
  id: string,
  get: (uri: string, options?: AxiosRequestConfig<any> | undefined) => Promise<any>
) => {
  const url = `${getApiUrl()}${id}`;

  const { response } = await get(url, {
    responseType: 'arraybuffer',
  });
  if (!response) return null;
  const zip = await JSZip.loadAsync(response.data);
  const zipFileName = id.includes('layoutGrid')
    ? 'LAYOUT_GRID_JSON'
    : id.includes('layoutIlc')
    ? 'ILC_LAYOUT_JSON'
    : 'LAYOUT_JSON';

  const zipFile = zip.file(zipFileName);
  if (!zipFile) return null;
  const content = await zipFile.async('text');
  return JSON.parse(content);
};
