import React from 'react';
import * as S from './plan-history.styled';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data';
import Skeleton from 'react-loading-skeleton';
import { AddPlanModal } from './add-plan-modal/add-plan-modal';
import { UpgradePlanModal } from './upgrade-plan-modal/upgrade-plan-modal';
import { EditPlanModal } from './edit-plan-modal/edit-plan-modal';
import { ConfirmDeleteModal } from './confirm-delete-modal';

import { Button, RPTable } from '@ratedpower/components';
import { usePlanHistory } from './use-plan-history';
import { useTranslation } from 'react-i18next';
import { useAddPlan } from './use-add-plan';

const PlanHistory: React.FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    table,
    confirmDeleteModal,
    editPlanModal,
    upgradePlanModal,
    supportView,
    showAddPlanButton,
    handleDeletePlan,
    handleEditPlan,
    handleRenewPlan,
    handleUpgradePlan,
    handleUpgradeChangePlan,
  } = usePlanHistory();
  const { newPlanModal, handleCreateNewPlan } = useAddPlan(supportView);

  const TableComponent: React.FC = () => {
    if (loading) return <Skeleton height={100} />;
    if (error) return <ErrorRetrievingData />;
    return <RPTable table={table as any} zeroResultsFoundText={t('common:zero-results-found')} />;
  };

  return (
    <S.PlanHistoryWrapper>
      {!loading && supportView && showAddPlanButton && (
        <div>
          <Button variant="ghost" text={t('company:add-new-plan')} onClick={() => newPlanModal.setModalOpen(true)} />
        </div>
      )}
      <S.PlanHistoryTable>
        <TableComponent />
        {supportView && (
          <>
            {newPlanModal.isOpen && (
              <AddPlanModal
                isActive={newPlanModal.isOpen}
                onClose={() => newPlanModal.setModalOpen(false)}
                handleCreateNewPlan={handleCreateNewPlan}
              />
            )}
            {confirmDeleteModal.isOpen && (
              <ConfirmDeleteModal
                isActive={confirmDeleteModal.isOpen}
                onClose={() => confirmDeleteModal.setModalOpen(false)}
                handleDeletePlan={handleDeletePlan}
              />
            )}
            {editPlanModal.isOpen && (
              <EditPlanModal
                isActive={editPlanModal.isOpen}
                onClose={() => editPlanModal.setModalOpen(false)}
                handleEditPlan={handleEditPlan}
                handleRenewPlan={handleRenewPlan}
                {...editPlanModal.data}
              />
            )}
            {upgradePlanModal.isOpen && (
              <UpgradePlanModal
                isActive={upgradePlanModal.isOpen}
                onClose={() => upgradePlanModal.setModalOpen(false)}
                handleUpgradePlan={handleUpgradePlan}
                handleUpgradeChangePlan={handleUpgradeChangePlan}
                currentPlan={upgradePlanModal.data.currentPlan}
              />
            )}
          </>
        )}
      </S.PlanHistoryTable>
    </S.PlanHistoryWrapper>
  );
};

export default PlanHistory;
