import React from 'react';
import { INotification } from 'types/notifications';
import { Modal } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import CreateNotificationForm from 'pages/communication/create-notification/create-notification-form/create-notification-form';
import useCreateNotificationForm from 'pages/communication/create-notification/hooks/use-create-notification-form';
import useEditNotification from 'pages/communication/hooks/use-edit-notification';
import ErrorRetrievingData from 'components/error-components/error-retrieving-data/index';
import { ToastType, useToasts } from 'utils/hooks/use-toasts';
import useGetNotificationToast, { NotificationsOperation } from 'pages/communication/hooks/use-get-notifications-toast';

interface IProps {
  notification: INotification;
  show: boolean;
  onClose: () => void;
}

const EditNotificationModal: React.FC<IProps> = ({ notification, show, onClose }: IProps) => {
  const { t } = useTranslation();
  const { formData, handleChange, formIsValid } = useCreateNotificationForm(notification);
  const editNotificationTitle = t('communication:edit-notification');
  const { addToast } = useToasts();
  const getNotificationToast = useGetNotificationToast();

  const handleOnCompleted = (): void => {
    onClose();
    showToast(ToastType.SUCCESS, formData.resendUserNotifications.value ? 2 : 0);
  };

  const handleOnError = (): void => {
    showToast(ToastType.ERROR);
  };

  const { editNotification, error } = useEditNotification({
    formData,
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    id: notification.id,
  });

  const showToast = (statusType: ToastType, variant = 0): void => {
    const { location, message, type, duration } = getNotificationToast(
      NotificationsOperation.EDIT,
      statusType,
      variant
    );

    addToast(location, message, type, duration);
  };

  const submitData = (): void => {
    if (!formIsValid()) return;

    editNotification(formData);
  };

  return (
    <Modal
      isActive={show}
      onClose={onClose}
      title={editNotificationTitle}
      size="fitContent"
      buttons={{
        primary: {
          text: editNotificationTitle,
          onClick: submitData,
        },
        secondary: {
          text: t('action:cancel'),
          onClick: onClose,
        },
      }}
      disableClickOutside
    >
      {error && <ErrorRetrievingData />}
      {show && <CreateNotificationForm formData={formData} handleChange={handleChange} updateMode />}
    </Modal>
  );
};

export default EditNotificationModal;
