import React, { useRef } from 'react';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import tunnel from 'tunnel-rat';
import { useOffset, useUtmZone } from '../ilc-store';

const round = (n: number) => Math.round(n * 100) / 100;

const gui = tunnel();

export function GuiIn() {
  const mouseRef = useRef<any>();

  const offset = useOffset();

  const utmZone = useUtmZone();

  useFrame(({ pointer, camera }) => {
    if (!mouseRef.current && !offset) return;
    const vector = new THREE.Vector3();
    vector.set(pointer.x, pointer.y, 0);
    vector.unproject(camera);
    if (offset && utmZone) {
      if (!mouseRef.current) return;
      mouseRef.current.innerHTML = `UTM Zone: ${utmZone} <br> X: ${round(vector.x + offset?.x)} <br> Y: ${round(
        vector.y + offset.y
      )}`;
    }
  });

  return (
    <gui.In>
      <div style={{ position: 'fixed', bottom: 0, left: 0 }}>
        <div style={{ fontSize: '20px', backgroundColor: 'white' }} ref={mouseRef}></div>
      </div>
    </gui.In>
  );
}

export function GuiOut() {
  return <gui.Out />;
}
