import React from 'react';
import * as S from './top-toolbar.styled';
import { ChevronDownSvg } from 'assets/icons';
import { Tabs, Button, PopupWrapper, IPopupIconButtonOption } from '@ratedpower/components';
import {
  PrimaryActionTypes,
  SecondaryActionTypes,
  useAddLineBetweenNodesMode,
  useAddNodeToClosestMode,
  useAddNodeToOtherNodeMode,
  useAddStructuresMode,
  useModes,
  useRemoveElements,
  useResetMode,
  useSetMode,
  useSetSecondaryMode,
  useTopToolbarTab,
  useSetTopToolbarTab,
  TopToolbarTab,
  useDesignInputs,
  useAlignSelectedStructureWithSetbacks,
  useSelectReferenceElementForRoadsMode,
  useCallValidation,
  useOpenModal,
  useAlignUniformly,
  useMoveMultipleElements,
} from '../../ilc-store';
import { StructureMovementInputs } from '../structure-movement-inputs';
import { getPerpendicularAngle } from '../../utils/maths';
import { useTranslation } from 'react-i18next';

function TopToolbar() {
  const { t } = useTranslation('ilc');
  const topToolBar = useTopToolbarTab();
  const setTopToolBar = useSetTopToolbarTab();
  const addStructuresMode = useAddStructuresMode();
  const setSecondaryMode = useSetSecondaryMode();
  const action = useModes().mode;
  const resetActions = useResetMode();
  const removeElements = useRemoveElements();
  const callValidation = useCallValidation();
  const moveMultipleElements = useMoveMultipleElements();
  const setMode = useSetMode();
  const openModal = useOpenModal();
  const designInputs = useDesignInputs();
  const someSelectedStructures =
    action.type === PrimaryActionTypes.SELECT_RECTANGLES && action.payload.some((item) => item.type === 'STRUCTURES');
  const someSelectedPowerStations =
    action.type === PrimaryActionTypes.SELECT_RECTANGLES &&
    action.payload.some((item) => item.type === 'POWER_STATIONS');
  const selectedPowerStations =
    action.type === PrimaryActionTypes.SELECT_RECTANGLES &&
    action.payload.every((item) => item.type === 'POWER_STATIONS')
      ? action.payload
      : null;

  const addNodeToClosestMode = useAddNodeToClosestMode();
  const addNodeToOtherNodeMode = useAddNodeToOtherNodeMode();
  const addLineBetweenNodesMode = useAddLineBetweenNodesMode();
  const alignSelectedStructureWithSetbacks = useAlignSelectedStructureWithSetbacks();
  const selectReferenceElementForRoadsMode = useSelectReferenceElementForRoadsMode();
  const alignUniformly = useAlignUniformly();

  const deleteElements = async () => {
    await removeElements();
    resetActions();
    callValidation();
  };

  const tabOptions = [
    { id: 'structures', label: t('tabs.structures') },
    { id: 'roads', label: t('tabs.roads') },
    { id: 'power-stations', label: t('tabs.power-stations') },
    { id: 'all', label: t('tabs.manage-multiple') },
  ];

  const handleRoadVertexOptionsClick = (selectedOption: IPopupIconButtonOption) => {
    if (selectedOption.value === 'closest-vertex') {
      addNodeToClosestMode();
    }
    if (selectedOption.value === 'other-vertex') {
      addNodeToOtherNodeMode(null);
    }
    if (selectedOption.value === 'connect-vertices') {
      addLineBetweenNodesMode(null);
    }
  };

  const roadVertexOptions = [
    { label: t('actions.connect-vertices'), value: 'connect-vertices' },
    { label: t('actions.to-closest-vertex'), value: 'closest-vertex' },
    { label: t('actions.to-other-vertex'), value: 'other-vertex' },
  ];

  const handleRemoveRoadVertexOptionsClick = (selectedOption: IPopupIconButtonOption) => {
    if (selectedOption.value === 'connect-adjacent') {
      setMode(PrimaryActionTypes.DELETE_NODE);
    }
    if (selectedOption.value === 'also-remove-lines') {
      setMode(PrimaryActionTypes.DELETE_NODE_AND_CONNECTING_EDGES);
    }
  };

  const removeRoadVertexOptions = [
    { label: t('actions.keep-connection'), value: 'connect-adjacent' },
    { label: t('actions.remove-connection'), value: 'also-remove-lines' },
  ];

  const designInputsAvailable = designInputs !== null;

  const structureOptionKeyToStructureOptionMap = designInputsAvailable
    ? designInputs.structureParametricInputs.reduce((acc, structureOption) => {
        return {
          ...acc,
          [`${structureOption.length}-${structureOption.width}-${structureOption.strings}`]: structureOption,
        };
      }, {})
    : {};

  const structureOptions = designInputsAvailable
    ? designInputs.structureParametricInputs.map((parametricInput) => {
        return {
          value: `${parametricInput.length}-${parametricInput.width}-${parametricInput.strings}`,
          label: `Length: ${parametricInput.length}, Width: ${parametricInput.width}, Strings: ${parametricInput.strings}`,
        };
      })
    : [];

  const handleSelectStructureOption = (selectedOption: IPopupIconButtonOption) => {
    const selectedStructure = structureOptionKeyToStructureOptionMap[selectedOption.value];
    addStructuresMode({ selectedStructureOption: selectedStructure });
  };

  const alignWithSetbacksOptions = [
    { label: t('actions.align-in-parallel'), value: 'parallel' },
    { label: t('actions.align-closest'), value: 'closest' },
  ];

  const handleAlignWithSetbackOptionsClick = (selectedOption: IPopupIconButtonOption) => {
    if (selectedOption.value === 'parallel') {
      alignSelectedStructureWithSetbacks('parallel');
    }
    if (selectedOption.value === 'closest') {
      alignSelectedStructureWithSetbacks('closest');
    }
  };

  const powerStationRotateOptions = [
    { label: t('actions.parallel-to-structure'), value: 'parallel-to-structure' },
    { label: t('actions.perpendicular-to-structure'), value: 'perpendicular-to-structure' },
    { label: t('actions.custom-angle'), value: 'custom' },
  ];

  const handleClickPowerStationRotate = async (selectedOption: IPopupIconButtonOption) => {
    if (!selectedPowerStations) return;
    const structureAngle = designInputs?.areaParametricInputs[selectedPowerStations[0].areaKey].structureAngle;
    if (!structureAngle) return;

    if (selectedOption.value === 'parallel-to-structure') {
      const updatedPowerStations = selectedPowerStations.map((ps) => {
        return {
          ...ps,
          angle: structureAngle,
        };
      });
      await moveMultipleElements(updatedPowerStations);
      resetActions();
    }
    if (selectedOption.value === 'perpendicular-to-structure') {
      const updatedPowerStations = selectedPowerStations.map((ps) => {
        return {
          ...ps,
          angle: getPerpendicularAngle(structureAngle),
        };
      });
      await moveMultipleElements(updatedPowerStations);
      resetActions();
    }
    if (selectedOption.value === 'custom') {
      openModal({ modalName: 'custom-ps-rotation', data: selectedPowerStations });
    }
  };

  return (
    <S.TopToolbarWrapper>
      <S.TopToolbarTabRow>
        <Tabs onClickTab={(tab) => setTopToolBar(tab as TopToolbarTab)} tabs={tabOptions} tabActive={topToolBar} />
      </S.TopToolbarTabRow>
      <S.TopToolbarButtonsRow>
        {topToolBar === 'structures' ? (
          <>
            <PopupWrapper
              onClickOption={handleSelectStructureOption}
              options={structureOptions}
              dataTest="structure-options"
              popupPosition="left"
            >
              <Button text={t('actions.add')} variant="ghost" Icon={ChevronDownSvg} iconPosition="right" />
            </PopupWrapper>
            <S.Divider />
            <PopupWrapper
              onClickOption={handleAlignWithSetbackOptionsClick}
              options={alignWithSetbacksOptions}
              dataTest="align-with-sectbacks-options"
              popupPosition="left"
            >
              <Button
                text={t('actions.align-to-setbacks')}
                variant="ghost"
                Icon={ChevronDownSvg}
                iconPosition="right"
                disabled={!someSelectedStructures}
              />
            </PopupWrapper>
            <S.Divider />
            <Button
              text={t('actions.align-to-reference')}
              variant="ghost"
              onClick={() => setSecondaryMode({ type: SecondaryActionTypes.SELECT_REFERENCE })}
              disabled={!someSelectedStructures}
            />
            <S.Divider />
            <Button
              text={t('actions.align-uniformly')}
              variant="ghost"
              disabled={!someSelectedStructures}
              onClick={alignUniformly}
            />
            <S.Divider />
            <Button
              text={t('actions.edit-mv-group')}
              variant="ghost"
              onClick={() => setSecondaryMode({ type: SecondaryActionTypes.SELECT_PS_FOR_STRUCTURES })}
              disabled={!someSelectedStructures}
            />
            <S.Divider />
            <Button
              text={t('actions.delete')}
              variant="ghost"
              onClick={() => deleteElements()}
              disabled={!someSelectedStructures}
            />
            <S.Divider />
            <StructureMovementInputs disabled={!someSelectedStructures} />
          </>
        ) : topToolBar === 'roads' ? (
          <>
            <PopupWrapper
              onClickOption={handleRoadVertexOptionsClick}
              options={roadVertexOptions}
              dataTest="road-options"
              popupPosition="left"
            >
              <Button text={t('actions.draw-line')} variant="ghost" Icon={ChevronDownSvg} iconPosition="right" />
            </PopupWrapper>
            <S.Divider />
            <PopupWrapper
              onClickOption={handleRemoveRoadVertexOptionsClick}
              options={removeRoadVertexOptions}
              dataTest="remove-options"
              popupPosition="left"
            >
              <Button text={t('actions.delete-vertex')} variant="ghost" Icon={ChevronDownSvg} iconPosition="right" />
            </PopupWrapper>
            <S.Divider />
            <Button
              text={t('actions.align-to-reference')}
              variant="ghost"
              onClick={() => selectReferenceElementForRoadsMode(null)}
            />
          </>
        ) : topToolBar === 'power-stations' ? (
          <>
            <PopupWrapper
              onClickOption={handleClickPowerStationRotate}
              options={powerStationRotateOptions}
              dataTest="rotate-power-station-options"
              popupPosition="left"
            >
              <Button
                text={t('actions.rotate')}
                variant="ghost"
                disabled={selectedPowerStations === null}
                Icon={ChevronDownSvg}
                iconPosition="right"
              />
            </PopupWrapper>
            <S.Divider />
            <PopupWrapper
              onClickOption={handleAlignWithSetbackOptionsClick}
              options={alignWithSetbacksOptions}
              dataTest="align-with-sectbacks-options"
              popupPosition="left"
            >
              <Button
                text={t('actions.align-to-setbacks')}
                variant="ghost"
                Icon={ChevronDownSvg}
                iconPosition="right"
                disabled={!someSelectedPowerStations}
              />
            </PopupWrapper>
            <S.Divider />
            <Button
              text={t('actions.align-to-reference')}
              variant="ghost"
              onClick={() => setSecondaryMode({ type: SecondaryActionTypes.SELECT_REFERENCE })}
              disabled={!someSelectedPowerStations}
            />
            <S.Divider />
            <StructureMovementInputs disabled={!someSelectedPowerStations} />
          </>
        ) : (
          <>
            <PopupWrapper
              onClickOption={handleAlignWithSetbackOptionsClick}
              options={alignWithSetbacksOptions}
              dataTest="align-with-sectbacks-options"
              popupPosition="left"
            >
              <Button
                text={t('actions.align-to-setbacks')}
                variant="ghost"
                Icon={ChevronDownSvg}
                iconPosition="right"
                disabled={!someSelectedStructures}
              />
            </PopupWrapper>
            <S.Divider />
            <Button
              text={t('actions.align-uniformly')}
              variant="ghost"
              disabled={!someSelectedStructures}
              onClick={alignUniformly}
            />
            <S.Divider />
            <Button
              text={t('actions.align-to-reference')}
              variant="ghost"
              onClick={() => setSecondaryMode({ type: SecondaryActionTypes.SELECT_REFERENCE })}
              disabled={!someSelectedStructures}
            />
            <S.Divider />
            <StructureMovementInputs disabled={!someSelectedStructures} />
          </>
        )}
      </S.TopToolbarButtonsRow>
    </S.TopToolbarWrapper>
  );
}

export { TopToolbar };
