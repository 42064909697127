/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { IModalProps, Button, Modal, Typography } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { ModalButtons } from '../../teams-tab.styled';
import { useRemoveMemberFromTeam } from '../../hooks/use-remove-member-from-team';
import { IndexIds } from 'index.ids';

interface IProps extends IModalProps {
  selectedTeamId: string;
  selectedUserId: string;
  description: string;
}

const UnassignMemberModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { selectedTeamId, selectedUserId, description, onClose } = props;
  const { onRemoveMember } = useRemoveMemberFromTeam(selectedTeamId, onClose);

  const onUnassignMember = () => {
    onRemoveMember(selectedUserId);
  };

  return (
    <Modal {...props}>
      <Typography>{description}</Typography>
      <ModalButtons>
        <Button variant="ghost" text={t('action:cancel')} onClick={onClose} />
        <Button
          variant="accent"
          text={t('user:teams.remove-from-team')}
          onClick={onUnassignMember}
          dataTest={IndexIds.Users.RemoveMemberButton}
        />
      </ModalButtons>
    </Modal>
  );
};

export default UnassignMemberModal;
