import React, { useRef } from 'react';
import * as S from './new-team-modal.styled';
import { InputText, InputTextArea, LabelWrapper, Modal, Typography, IModalProps } from '@ratedpower/components';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { getDefaultCoverColors } from 'utils/teams';
import ColorPicker from 'components/color-picker/color-picker';
import UploadFile from 'components/upload/upload-file';
import { PlusSvg } from 'assets/icons';
import { useCreateTeam } from '../../hooks/use-create-team';
import { CreatedTeam } from 'types/teams';
import { IndexIds } from 'index.ids';

interface IProps extends IModalProps {
  onCreateTeam: (createdTeam: CreatedTeam) => void;
}

const NewTeamModal: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const currentColorPicked = useRef('');

  const {
    handleTeamSubmit,
    handleSelectColorCover,
    handleSelectCoverImage,
    formError,
    selectedCoverColor,
    selectedCoverImage,
    onChangeInput,
  } = useCreateTeam(props.onCreateTeam);

  return (
    <Modal
      {...props}
      buttons={{
        primary: {
          text: t('user:teams.create-team'),
          onClick: handleTeamSubmit,
          dataTest: IndexIds.Users.CreateTeamButton,
        },
        secondary: {
          text: t('action:cancel'),
          onClick: props.onClose,
        },
      }}
    >
      <S.ModalForm>
        <S.TeamCoverTitle>
          <Typography>{t('user:teams.team-cover-image')}</Typography>
          <Typography className="cover-image" nowrap>
            {!selectedCoverColor && selectedCoverImage?.name}
          </Typography>
        </S.TeamCoverTitle>
        <S.TeamCoverContainer>
          <S.ColorPickerWrapper
            selected={
              !!selectedCoverColor && !getDefaultCoverColors(theme).includes(selectedCoverColor) && !selectedCoverImage
            }
          >
            <ColorPicker
              onChangeColor={(newColor) => {
                currentColorPicked.current = newColor;
                handleSelectColorCover(newColor)();
              }}
              colorWheelWidth={64}
              hexPanelWidth={136}
              color={currentColorPicked.current || selectedCoverColor || ''}
              editable
            />
          </S.ColorPickerWrapper>
          {getDefaultCoverColors(theme).map((color) => (
            <S.DefaultCoverColor
              key={color}
              color={color}
              onClick={handleSelectColorCover(color)}
              selected={selectedCoverColor === color}
            />
          ))}
          <S.UploadFileContainer>
            <UploadFile
              onFileChange={handleSelectCoverImage}
              maxMbSize={1024 * 1024}
              accept="image/jpeg, image/png, image/jpg"
            />
            <PlusSvg />
          </S.UploadFileContainer>
        </S.TeamCoverContainer>
        <LabelWrapper htmlFor="name" title={t('user:teams.team-name') + '*'} alignItems="center">
          <InputText
            name="teamName"
            autofocus
            placeholder={t('user:teams.name-placeholder')}
            onChange={(value: string) => onChangeInput('teamName', value)}
            warning={!!formError}
          />
        </LabelWrapper>
        <LabelWrapper htmlFor="name" title={t('description')}>
          <InputTextArea
            name="description"
            placeholder={t('user:teams.description-placeholder')}
            minRows={3}
            size="m"
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChangeInput('description', e.target.value)}
          />
        </LabelWrapper>
        <Typography color={theme.v2.error.bg.contrast} size="s">
          {formError?.name ? formError?.name : formError}
        </Typography>
      </S.ModalForm>
    </Modal>
  );
};

export default NewTeamModal;
