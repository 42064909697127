import { getCoreRowModel, getExpandedRowModel, useReactTable } from '@tanstack/react-table';
import { getReadOnlyRolesTableColumns } from 'pages/users-management/table-columns/read-only-roles-table-columns';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { IRole, ROLE_TYPE } from 'types/role';
import { IUserRoleSummary } from 'types/users';

export const useReadOnlyRolesTable = (userRoles: IUserRoleSummary[], rolesByCurrentUser: IRole[]) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [rolesExpanded, setRolesExpanded] = useState<string[]>([]);

  const readOnlyRoles = useMemo(
    () =>
      rolesByCurrentUser
        .filter((role: IRole) => role.type === ROLE_TYPE.EXTERNAL)
        .sort((roleA: IRole, roleB: IRole) => {
          if (roleA.hierarchyLevel === 0 && roleB.hierarchyLevel === 0) {
            const order = ['User', 'Basic user', 'Read-only'];
            return order.indexOf(roleA.name) - order.indexOf(roleB.name);
          }
          return (roleB.hierarchyLevel || 0) - (roleA.hierarchyLevel || 0);
        }),
    [rolesByCurrentUser]
  );

  const table = useReactTable({
    data: readOnlyRoles,
    columns: getReadOnlyRolesTableColumns(t, theme, userRoles || [], rolesExpanded),
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: () => true,
  });

  const handleExpandSection = (roleId: string) => {
    setRolesExpanded((currentRolesExpanded) => {
      if (currentRolesExpanded.includes(roleId)) {
        const index = currentRolesExpanded.findIndex((current: string) => current === roleId);
        currentRolesExpanded.splice(index, 1);
        return currentRolesExpanded;
      }
      return [...currentRolesExpanded, roleId];
    });
  };

  return {
    table,
    setRolesExpanded,
    handleExpandSection,
    readOnlyRoles,
  };
};
