import React, { ReactElement, useMemo } from 'react';
import classnames from 'classnames';
import { IOption } from 'types/common';
import { IUserListItem, USER_STATUS } from 'types/users';
import { getOriginalRolesColors, ROLE_USER, USER_ROLES_ACTIONS } from 'utils/constants/users';
import { IRole, ROLE_TYPE } from 'types/role';
import {
  BulletPoint,
  Button,
  Dropdown,
  InputSelect,
  StatusSVGWrapper,
  SVGWrapper,
  Tag,
  Typography,
} from '@ratedpower/components';
import { TableActions } from '../users-tab/users-tab.styled';
import { CircleFullSvg, NewPasswordSvg, TrashSvg } from 'assets/icons';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import EditableInputTextCell from 'components/tables/editable-input-text-cell';
import { IndexIds } from 'index.ids';

export interface IUserTableColumnsProps {
  sortableHeader: (label: string, code: string) => ReactElement;
  handlePerform: (action: string, id?: string, data?: any) => void;
  rolesByCurrentUser: IRole[];
  jobTitles: string[];
  userHierarchyLevel: number;
  loadingCreate: boolean;
  error: boolean;
  isExternalAdmin: boolean;
  myTeamMembers: string[];
  hasWritePermission: boolean;
  hasRoleReadPermissions: boolean;
  hasRoleChangePermissions: boolean;
  hasDeletePermissions: boolean;
}

const columnHelper = createColumnHelper<IUserListItem>();

export const useGetUserTableColumns = (userTableColumnsProps: IUserTableColumnsProps, companyHasTeams: boolean) => {
  const {
    sortableHeader,
    handlePerform,
    rolesByCurrentUser,
    jobTitles,
    error,
    userHierarchyLevel,
    isExternalAdmin,
    myTeamMembers,
    hasWritePermission,
    hasRoleChangePermissions,
    hasDeletePermissions,
  } = userTableColumnsProps;

  const { t } = useTranslation();
  const theme = useTheme();
  const originalRolesColors = getOriginalRolesColors(theme);

  return useMemo(
    () =>
      [
        columnHelper.accessor('name', {
          header: () => sortableHeader('name', 'name'),
          cell: (info) => <EditableInputTextCell info={info} />,
        }),
        columnHelper.accessor('lastname', {
          header: () => sortableHeader('user:lastname', 'surname'),
          cell: (info) => <EditableInputTextCell info={info} />,
        }),
        columnHelper.accessor('email', {
          header: () => sortableHeader('user:email', 'email'),
          cell: (info) =>
            !info.row.original.id ? (
              <EditableInputTextCell info={info} error={error} />
            ) : (
              <Typography>{info.getValue()}</Typography>
            ),
        }),
        columnHelper.accessor('jobTitle', {
          header: () => sortableHeader('user:job-title', 'jobTitle'),
          cell: ({ getValue, row: { index, original }, column: { id }, table }) =>
            !original.editable ? (
              <Typography>{t(`user:jobTitle.${getValue()}`)}</Typography>
            ) : (
              <InputSelect
                name="jobTitle"
                value={[
                  {
                    label: t(`user:jobTitle.${getValue() || ''}`),
                    value: getValue() || '',
                  },
                ]}
                options={jobTitles.map((j) => ({
                  label: t(`user:jobTitle.${j}`),
                  value: j,
                }))}
                onChange={(option) => {
                  table.options.meta?.updateData(index, id, option[0].value);
                }}
              />
            ),
        }),
        ...(companyHasTeams
          ? [
              columnHelper.accessor('id', {
                header: () => <>{t('user:teams.teams-assigned')}</>,
                cell: (info) => (
                  <Button
                    variant="link"
                    textUnderlined
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePerform(USER_ROLES_ACTIONS.checkTeamsAssigned, info.getValue());
                    }}
                    text={t('user:teams.view-teams')}
                  />
                ),
              }),
            ]
          : []),
        columnHelper.accessor('status', {
          header: () => sortableHeader('user:status', 'status'),
          cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
            const newValue = getValue() === 'DISABLED' ? USER_STATUS.ENABLED : USER_STATUS.DISABLED;
            const isNotEditable = (original.editable && !id) || !original.editable || !isExternalAdmin;
            return (
              <div style={{ width: 'max-content' }}>
                <Tag
                  color={getValue() === USER_STATUS.DISABLED ? 'gray' : 'teal'}
                  onClick={() => {
                    if (!isNotEditable) {
                      table.options.meta?.updateData(index, id, newValue);
                    }
                  }}
                >
                  {t(`user:${USER_STATUS[getValue()]}`)}
                </Tag>
              </div>
            );
          },
        }),
        columnHelper.accessor('role', {
          header: () => sortableHeader('user:role', 'role'),
          cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
            const role = rolesByCurrentUser.find((roleByCurrentUser) => roleByCurrentUser.id === getValue()?.id);
            const editable = original.editable;

            const getRoleSelected = (roleId?: string, roleName?: string): IOption => {
              return {
                value: roleId,
                label: roleName,
                // TODO fix Dropdown to accept a component as labelIcon, or labelIconColor as prop
                labelIcon: CircleFullSvg,
              };
            };
            return !editable || (!hasRoleChangePermissions && role?.type === ROLE_TYPE.INTERNAL) ? (
              <BulletPoint color={role?.color ?? originalRolesColors[String(role?.name)] ?? theme.black}>
                <Typography as="span">{role?.name ?? ROLE_USER}</Typography>
              </BulletPoint>
            ) : (
              <Dropdown
                value={[getRoleSelected(role?.id, role?.name)]}
                onChange={(option) => {
                  table.options.meta?.updateData(index, id, { id: option[0].value });
                }}
                options={rolesByCurrentUser
                  .filter(
                    (roleByCurrentUser) =>
                      (!hasRoleChangePermissions && roleByCurrentUser?.type === ROLE_TYPE.EXTERNAL) ||
                      hasRoleChangePermissions
                  )
                  .filter(
                    (roleByCurrentUser) =>
                      !!isExternalAdmin || (roleByCurrentUser?.hierarchyLevel || 0) < userHierarchyLevel
                  )
                  .map(({ name, id }) => ({
                    label: getRoleSelected(id, name).label,
                    value: id,
                    description: t(`user:roles.descriptions.${name}`, { defaultValue: '-' }),
                    labelIcon: CircleFullSvg,
                    labelIconColor: originalRolesColors[name],
                  }))}
              />
            );
          },
        }),
        columnHelper.accessor('editable', {
          header: '',
          cell: (info) => {
            const { id, role, email, editable, lastLogin, status } = info.row.original;
            const canEdit = () => {
              if (!hasWritePermission) return false;
              else if (role?.type === 'INTERNAL') {
                return true;
              } else if (
                isExternalAdmin ||
                (userHierarchyLevel > (role?.hierarchyLevel || 0) && myTeamMembers.includes(String(id)))
              ) {
                return true;
              }
              return false;
            };

            return (
              <TableActions className={classnames('table-actions', { disabled: status === 'DISABLED' })}>
                {id && !editable && !lastLogin && email !== 'new@ratedpower.com' ? (
                  <StatusSVGWrapper
                    active={false}
                    size="m"
                    className="verification-pass-btn"
                    data-test="verification-pass-btn"
                    icon={NewPasswordSvg}
                    tooltip={{
                      text: t('user:send-new-verification-password'),
                      place: 'bottom',
                    }}
                    onClick={() => handlePerform(USER_ROLES_ACTIONS.resendPassword, '', { email })}
                  />
                ) : (
                  <></>
                )}
                {!editable && canEdit() ? (
                  <Button
                    dataTest={IndexIds.Users.EditUserButton}
                    variant="link"
                    textUnderlined
                    text={t('action:edit')}
                    onClick={() => {
                      info.table.options.meta?.editRow(info.row.index, true);
                    }}
                  />
                ) : (
                  editable && (
                    <>
                      <Button
                        variant="link"
                        textUnderlined
                        text={t('action:cancel')}
                        onClick={() => {
                          info.table.options.meta?.editRow(info.row.index, false);
                        }}
                      />
                      <Button
                        variant="accent"
                        dataTest={IndexIds.Users.SaveUserButton}
                        onClick={() => handlePerform(USER_ROLES_ACTIONS.save, id)}
                        text={t('action:save')}
                        loading={userTableColumnsProps.loadingCreate}
                      />
                    </>
                  )
                )}
                {hasDeletePermissions &&
                  (status === 'DISABLED' ? (
                    <SVGWrapper
                      icon={TrashSvg}
                      onClick={() => handlePerform(USER_ROLES_ACTIONS.deleteRow, id)}
                      size="m"
                    />
                  ) : (
                    <StatusSVGWrapper
                      icon={TrashSvg}
                      active={false}
                      disabled
                      tooltip={{
                        text: 'UsersTabRoleSwitch',
                        place: 'bottom',
                      }}
                      size="m"
                    />
                  ))}
              </TableActions>
            );
          },
        }),
      ] as ColumnDef<IUserListItem>[],
    [myTeamMembers, rolesByCurrentUser, sortableHeader]
  );
};
