import React from 'react';
import * as S from './maintenance.styled';
import { MaintenanceSvg } from 'assets/icons/index';
import { useTranslation } from 'react-i18next';
import { Typography } from '@ratedpower/components';
import { IndexIds } from 'index.ids';

const Maintenance: React.FC = () => {
  const { t } = useTranslation();

  return (
    <S.MaintenanceWrapper data-testid={IndexIds.Maintenance.Container}>
      <S.MaintenanceContainer>
        <MaintenanceSvg />
        <Typography weight="bold">{t('maintenance:maintenance-in-progress')}</Typography>
        <Typography size="s">{t('maintenance:currently-in-maintenance')}</Typography>
      </S.MaintenanceContainer>
    </S.MaintenanceWrapper>
  );
};

export default Maintenance;
